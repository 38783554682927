import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/loader";

import {
    InputGroup,
    Input,
    InputGroupButtonDropdown,
    Button,
} from "reactstrap";
import { PageSettings } from "../../config/page-settings"
import ItemProduto from "../../components/produto"
import Breadcrumb from "../../components/breakcumbs"
import TitlePage from "../../components/pages/title"
import { Link } from "react-router-dom"
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteProduct, getProducts } from "../../services/product";
import { store } from "react-notifications-component";
import SkeletonItem from "../../components/skeleton/skeleton-item";
import { BASE_URL_PRODUCTS } from "../../config/api.config";
import Pagination from "../../components/paginate/pagination";
import ListarProdutosComponent from "./listarProduto"

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [splitButtonOpen, setSplitButtonOpen] = useState(false);
    const contextType = useContext(PageSettings);
    const [deletar, setDeletar] = useState(null)

    const [listaProdutos, setListaProdutos] = useState({})

    const [searchname, setSearchname] = useState("")

    const toggleSplit = () => {
        setSplitButtonOpen(!splitButtonOpen);
    };

    const getProdutos = async (currentUrl = null) => {
        setLoading(true)
        
        await getProducts({name:searchname, page:currentUrl}).then(response => {
            setListaProdutos({
                currentUrlProducts: response.request.responseURL,
                ...response.data.resposta.conteudo.products
            })
        })
            .catch(error => {
                if (error && error.response?.data) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getProdutos()
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

    }, []);

    const confirmar = async () => {
        setLoading(true)
        await deleteProduct(deletar.id)
            .then(response => {
                getProdutos()
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
            })
            .catch(error => {
                if (error && error.response?.data) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
                setDeletar(null)
            })
    }
    const deletarItem = () => {
        console.log("confirmar")
        setDeletar(null)
    }

    useEffect(() => {
        if (!searchname) {
            getProdutos()
        }
    }, [searchname])
    return (
        <>
            {(deletar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim, deletar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Você tem certeza?"
                    onConfirm={confirmar}
                    onCancel={deletarItem}
                >
                    Após essa ação Você não conseguira recuperar os dados.
      </SweetAlert>
            )}
            <Loader loading={loading} />
            <TitlePage title="Produtos" desc="Edite e cadastre todos os seus produtos." icon="fal fa-box" />
            <div className="row">
                <div className="col-md-12">
                    <div className="result-container">
                        <InputGroup size="lg" className="m-b-20">
                            <Input
                                placeholder="Pesquise o seu produto..."
                                type="text"
                                className="input-white"
                                value={searchname}
                                onChange={e => setSearchname(e.target.value)}
                            />
                            <InputGroupButtonDropdown
                                addonType="append"
                                isOpen={splitButtonOpen}
                                toggle={toggleSplit}
                            >
                                <Button color="primary" onClick={() => getProdutos()}>
                                    <i className="fa fa-search fa-fw"></i>
                                </Button>
                                {
                                    searchname && (
                                        <Button color="danger"
                                            onClick={() => {
                                                setSearchname("")
                                            }}
                                        >
                                            <i className="fa fa-times fa-fw"></i>
                                        </Button>
                                    )
                                }
                                <Button color="success" tag={Link} to="/cadastrar/produto" >
                                    <i className="fa fa-plus fa-fw"></i>
                                </Button>
                            </InputGroupButtonDropdown>
                        </InputGroup>

                        <ListarProdutosComponent listaProdutos={listaProdutos.data} setDeletar={setDeletar}/>

                        <div className="clearfix m-t-20">
                            <Pagination
                                firstPageUrl={listaProdutos.first_page_url}
                                lastPageUrl={listaProdutos.last_page_url}
                                nextPageUrl={listaProdutos.next_page_url}
                                previousPageUrl={listaProdutos.prev_page_url}

                                perPage={listaProdutos.per_page}
                                lastPage={listaProdutos.last_page}
                                currentPage={listaProdutos.current_page}
                                currentUrl={listaProdutos.currentUrlProducts}
                                path={listaProdutos.path}

                                {...listaProdutos}

                                baseUrl={BASE_URL_PRODUCTS}
                                getItems={getProdutos}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
