import React, { useState, useEffect, useContext, useRef } from "react";
import Loader from "../../components/loader";
import classnames from "classnames";
import ModalCotacao from "./modal/cotacao"
import { PageSettings } from "../../config/page-settings"
import Produtos from "./parts/products"
import Servicos from "./parts/services"
import { Link, useParams } from "react-router-dom"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import Header from "./parts/header"
import { getOtherProducts as getProducts } from "../../services/product";
import { getOtherServices as getServices } from "../../services/service";
import { getCompanie } from "../../services/companies";
import { store } from "react-notifications-component";
import { useSelector } from "react-redux";
import { statusPedido as statusPedidoService } from "../../services/portfolios";
import ListaComentarios from "../../components/comentarios/lista";
import { createEvaluations } from "../../services/evaluations";
import { useDispatch } from "react-redux";
import {getCompanie as getCompanieStore, getUser} from "../../store/auth/action"

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const Empresa = (props) => {
    const contextType = useContext(PageSettings);
    const {user, current_companie} = useSelector(state => state.auth)
    const refFormEvaluation = useRef(null)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("1");
    const [modalProduto, toggleModal] = useState(false)
    const [empresa, setEmpresa] = useState({})
    const [listaProdutos, setListaProdutos] = useState({data: []})
    const [listaServicos, setListaServicos] = useState({data: []})
    const [statusPedido, setStatusPedido] = useState({})
    const [carregandoStatus, setCarregandoStatus] = useState(true)

    const [title, setTitle] = useState("")
    const [comment, setComment] = useState("")
    const [stars, setStars] = useState(0)
    const [edit, setEdit] = useState(false)

    let { id } = useParams();

    const currentIdCompany = () => {
        const urlParamInt = parseInt(window.location.pathname.split('/').pop())
        return !isNaN(urlParamInt) ? urlParamInt : user.companies[0].id
    }

    const getProdutos = async (params = {}) => {
        setLoading(true)
        await getProducts({ ...params, company_id: currentIdCompany() }).then(response => {
            setListaProdutos({
                currentUrlProducts: response.request.responseURL,
                ...response.data.resposta.conteudo.products
            })
        })
            .catch(error => {
                if (error && error.response?.data) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            // avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                // avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getServicos = async (params = {}) => {
        setLoading(true)
        await getServices({ ...params, company_id: currentIdCompany() }).then(response => {
            setListaServicos({
                currentUrlServices: response.request.responseURL,
                ...response.data.resposta.conteudo.services
            })
        })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            // avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                // avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getEmpresa = async (empresaId) => {
        setLoading(true)
        await getCompanie(empresaId).then(response => {
            setEmpresa(response.data.resposta.conteudo.empresa)
        })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getStatus = async (empresaId) => {
        await statusPedidoService(empresaId).then(response => {
            setStatusPedido(response.data.resposta.conteudo.statusRequest)
        })
            .catch(error => {
                setStatusPedido({})
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
            })
            .finally(() => {
                setCarregandoStatus(false)
            })
    }

    const runServices = async () => {
        setLoading(true)
        Promise.all([getProdutos(), getServicos(), getEmpresa(currentIdCompany()), getStatus(currentIdCompany())])
            .finally(() => setLoading(false))
    }
    
    const createComent = async ({ title, comment, stars }, callback) => {
        if(current_companie.id === empresa.id)
            return avisar("Você não pode avaliar sua própria empresa", "Tente avaliando outra empresa", "danger")
        const input = {
            company_id: empresa.id,
            title: title,
            description: comment,
            status: "ACTIVE",
            stars: String(stars)
        }
        if (!comment) return avisar("Texto do comentário obrigatório.", "Texto não pode ser vazio", "danger")
        await createEvaluations(input)
            .then(response => {
                avisar("Comentário adicionado", "Comentário adicionado com sucesso.", "success")
                if (callback) callback()
                getEmpresa(empresa.id)
            })
            .catch(err => {
                if (err && err.response && err.response.data?.resposta?.conteudo?.message) {
                    avisar("Algo deu errado!", err.response.data.resposta.conteudo.message, "danger")
                }
            })
    }
    const loadData = () =>{
        
        if(user)
            dispatch(getCompanieStore(user.companies[0].id))
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.handleSetPageContentFullHeight(true);
        contextType.handleSetPageContentFullWidth(true);
        contextType.closeMobileSiderBar()
        runServices()
        loadData();
        if (listaProdutos.data.length === 0 && listaServicos.data.length === 0) {
            toggle('3')
        }

        return () => {
            contextType.handleSetPageContentFullHeight(false);
            contextType.handleSetPageContentFullWidth(false);
        }
    }, []);

    useEffect(() => {
        if (edit) {
            window.scrollTo({ behavior: 'smooth', top: refFormEvaluation.current.offsetTop })
            setEdit(false)
        }
    }, [edit])

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const toggleModalProduto = () => {
        toggleModal(!modalProduto)
    }
    console.log(current_companie)
    return (
        <>
            <Loader loading={loading} />
            <ModalCotacao modalProduto={modalProduto} toggleModal={toggleModalProduto} />

            <Header
                id={id}
                item={empresa}
                runServices={runServices}
                carregandoStatus={carregandoStatus}
                status={statusPedido}
                setLoading={setLoading}
            />
            {
                empresa.profile === "1" && (
                    <>
                        <div className="bg-white" style={{ marginTop: '-15px' }}>
                            <div className="col-12 pt-3 pb-3">
                                <Nav tabs className="nav-pills">
                                    {
                                        listaProdutos.data.length > 0 && (
                                            <NavItem className="m-3">
                                                <NavLink
                                                    className={classnames({ active: activeTab === "1" })}
                                                    onClick={() => {
                                                        toggle("1");
                                                    }}
                                                >
                                                    Produtos
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                    {
                                        listaServicos.data.length > 0 && (
                                            <NavItem className="m-3">
                                                <NavLink
                                                    className={classnames({ active: activeTab === "2" || listaProdutos.data.length === 0 })}
                                                    onClick={() => {
                                                        toggle("2");
                                                    }}
                                                >
                                                    Serviços
                                                </NavLink>
                                            </NavItem>
                                        )
                                    }
                                    <NavItem className="m-3">
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === "3" || (listaProdutos.data.length === 0 && listaServicos.data.length === 0)
                                            })}
                                            onClick={() => {
                                                toggle("3");
                                            }}
                                        >
                                            Avaliações
                                        </NavLink>
                                    </NavItem>
                                    {
                                        id ? <Link to={`/cotacao/cotar/${parseInt(window.location.pathname.split('/').pop())}`} className="btn btn-md btn-orange btn-circle ml-auto" style={{ height: "35px" }}><i className="fa fa-comment-dots t-plus-1 fa-fw fa-lg"></i> Cotação</Link>
                                            : null
                                    }
                                </Nav>
                            </div>
                        </div>

                        <TabContent activeTab={activeTab} style={{ paddingBottom: 30 }}>
                            <TabPane tabId="1">
                                <Produtos loading={loading} listaProdutos={listaProdutos} getItems={getProdutos} company={empresa} />
                            </TabPane>
                            <TabPane tabId="2">
                                <Servicos loading={loading} listaServicos={listaServicos} getItems={getServicos} company={empresa} />
                            </TabPane>
                            <TabPane tabId="3">
                                <div ref={refFormEvaluation} className="col-md-12 mt-4" style={{position: 'relative'}}>
                                    {
                                        loading && (
                                            <div
                                                style={{
                                                    zIndex: '1',
                                                    background: '#eff4f9',
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: loading ? 'none' : 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    opacity: '.8',
                                                    fontSize: '1rem',
                                                    fontWeight: '600', 
                                                }}
                                            >
                                                Carregando...
                                            </div>
                                        )
                                    }
                                    <form className="comentar">
                                        <h4 className="media-heading">Seu comentário.</h4>
                                        <div className="rating mt-2 mb-1">
                                            <p className="mr-2">sua nota: {stars}</p>
                                            {
                                                [1, 2, 3, 4, 5].map(item => (
                                                    <span
                                                        key={item}
                                                        className={`m-1 p-0 btn star ${item <= stars && 'active'}`}
                                                        onClick={() => setStars(item)}
                                                    ></span>
                                                ))
                                            }

                                        </div>
                                        <div className="input-group mb-2">
                                            <input
                                                type="text"
                                                className="form-control bg-light"
                                                placeholder="Título do coméntaio"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group mb-2">
                                            <textarea
                                                type="text"
                                                className="form-control bg-light"
                                                placeholder="Escreva seu coméntaio"
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-green"
                                            type="button"
                                            onClick={() => createComent({ title, comment, stars }, () => { setComment(""); setTitle("") })}                                    >
                                            <i className="fa fa-paper-plane"></i> Enviar
                                        </button>
                                    </form>
                                    <hr />
                                    {
                                        empresa?.evaluates && (
                                            <ListaComentarios
                                                lista={empresa.evaluates}
                                                getItems={runServices}
                                                avisar={avisar}
                                                type="company"
                                                setLoading={setLoading}
                                                loading={loading}
                                            />
                                        )
                                    }
                                    {
                                        loading && (
                                            <p className="text-center mb-5"><i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando... </p>
                                        )
                                    }
                                </div>
                            </TabPane>
                        </TabContent>
                    </>
                )
            }
        </>
    );
};

export default Empresa;
