import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"

import 'react-multi-carousel/lib/styles.css';
import classnames from 'classnames';
// cadastrar categoria
import { TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Card } from 'reactstrap';
import ListaComentarios from "../../../components/comentarios/lista"
import { createEvaluations } from "../../../services/evaluations"
import FormComentario from "../../../components/comentarios/formComentario";
const list = [
    { empresa: "Empresa", data: "22/10/2021", isCurrentUser: true, dontShowEdit: true },
    { empresa: "Empresa", data: "22/10/2021", isCurrentUser: false, dontShowEdit: false, conclusao: "texto texto texto" },
    { empresa: "Empresa", data: "22/10/2021", isCurrentUser: false, dontShowEdit: false }

]
const Sobre = function (props) {
    const {
        produto,
        type,
        loadingAvaliacoes,
        getItem,
        avisar,
        loading,
        setLoading
    } = props
    const [activeTab, setActiveTab] = useState("1")
    const [title, setTitle] = useState("")
    const [comment, setComment] = useState("")
    const [stars, setStars] = useState(0)

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    useEffect(() => {
        type === 'servico' && setActiveTab("2")
    }, [])

    // imagem d escricao
    return (
        <>
            <div className="bg-white">
                <Container fluid={true} className="product pb-1">
                    <Nav pills className=" mb-4 pt-4 " >
                        {
                            type !== 'servico' && (
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                        <i className="fa fa-grip-lines fa-lg m-r-5"></i> <span className="d-md-inline">Especificações Técnicas</span>
                                    </NavLink>
                                </NavItem>

                            )
                        }
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                <i className="fa fa-comments fa-lg m-r-5"></i> <span className="d-md-inline">Comentários</span>
                            </NavLink>
                        </NavItem>

                    </Nav>

                </Container>
            </div>

            <Container>

                <TabContent activeTab={activeTab} className="pb-4">
                    <TabPane tabId="1" >

                        <Card className="mt-4" style={{ borderRadius: 20 }}>
                            <CardBody >
                                <div dangerouslySetInnerHTML={{ __html: produto.technical_specifications }} ></div>

                            </CardBody>
                        </Card>

                    </TabPane>
                    <TabPane tabId="2" className="product-pd mb-5">
                        <FormComentario
                            stars = {stars}
                            setStars = {setStars}
                            title = {title}
                            setTitle = {setTitle}
                            comment = {comment}
                            setComment = {setComment}
                            createComent = {props.createComent}
                        />

                        <hr />
                        {
                            produto[type === 'servico' ? 'evaluates' : 'evaluations'] && (
                                <ListaComentarios
                                    lista={produto[type === 'servico' ? 'evaluates' : 'evaluations']}
                                    getItems={getItem}
                                    avisar={avisar}
                                    setLoading={setLoading}
                                    loading={loading}
                                />
                            )
                        }
                        {
                            loadingAvaliacoes && (
                                <p className="text-center mb-5"><i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando </p>
                            )
                        }
                    </TabPane>

                </TabContent>
            </Container>

        </>


    )

};

export default Sobre