import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Row } from "reactstrap";
import Loader from "../../../components/loader";
import TitlePage from "../../../components/pages/title";
import { rolesRequests } from "../../../services/admin/roles";
import { permissionsRequests } from "../../../services/admin/permissions";
import ContainerToForm from "../../../components/containerToForm";

export default function Form() {
  const { id } = useParams();
  const history = useHistory();

  const [role, setRole] = useState({});
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);

  const getRole = async () => {
    await rolesRequests
      .show(id)
      .then((response) => {
        if (response?.data) {
          const { role } = response?.data?.resposta?.conteudo;
          setRole(role);
        }
      })
      .finally(() => setLoading(false));
  };

  const getPermissions = async () => {
    await permissionsRequests
      .list()
      .then((response) => {
        if (response?.data) {
          const { permissions } = response?.data?.resposta?.conteudo;
          setPermissions(permissions);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const { name, description, status } = role;
    await rolesRequests[id ? "update" : "create"]({
      id,
      name,
      description,
      status,
    })
      .then((response) => {
        if (response?.data) {
          const { message } = response?.data?.resposta?.conteudo;
          console.log("res", message);
          toast.success(message || "Perfil atualizado com suceso!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
          history.push("/perfis");
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.message) {
          const { message } = error.response.data.resposta.conteudo;
          toast.error(message || "Algo deu errado!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
          return;
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitPermissionToRole = async (permissionId, remove = false) => {
    const endpointAPI = remove ? "deletePermissionToRole" : "addPermissionToRole"
    await rolesRequests[endpointAPI]({
      role_id: id,
      permission_id: permissionId
    })
      .then((response) => {
        if (response?.data) {
          const { message } = response?.data?.resposta?.conteudo;
          console.log("res", message);
          toast.success(message || "Permissão adicionada ao perfil com sucesso.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.message) {
          const { message } = error.response.data.resposta.conteudo;
          toast.error(message || "Algo deu errado!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
          return;
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) {
      getRole();
    }
  }, [id]);

  useEffect(() => {
    getPermissions()
  }, [])

  return (
    <>
      <ToastContainer />
      <Loader loading={loading} />

      <TitlePage
        title={`${id ? "Edição" : "Cadastro"} de Perfil`}
        icon="far fa-roles"
      />

      <ContainerToForm title="Perfil">
        <form onSubmit={handleSubmit}>
          <Row>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Nome</label>
                <input
                  required
                  name="name"
                  placeholder="Nome"
                  type="text"
                  className="form-control"
                  value={role?.name || ""}
                  onChange={(event) =>
                    setRole({ ...role, name: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Descrição</label>
                <input
                  required
                  name="description"
                  placeholder="Descrição"
                  type="text"
                  className="form-control"
                  value={role?.description || ""}
                  onChange={(event) =>
                    setRole({ ...role, description: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Status</label>
                <input
                  name="status"
                  placeholder="Status"
                  type="text"
                  className="form-control"
                  value={role?.status || ""}
                  onChange={(event) =>
                    setRole({ ...role, status: event.target.value })
                  }
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12">
              <h5>Permissões</h5>
            </div>
            {permissions?.data &&
              permissions.data.map((permission) => (
                  <div key={permission.id} className="col-md-6">
                      <div className="form-check">
                          <input
                              className="form-check-input"
                              type="checkbox"
                              checked={role?.permissions?.find(perm => perm.id === permission.id)}
                              value={permission.id}
                              id={permission.id}
                              onChange={(event) => {
                                  const rolePermissions = role?.permissions || []
                                  let newsPermissionsToRole = [...rolePermissions, +event.target.value]
                                  if (rolePermissions.find(permission => permission === +event.target.value)) {
                                      handleSubmitPermissionToRole(+event.target.value, true)
                                      newsPermissionsToRole = newsPermissionsToRole.filter(permission => permission !== +event.target.value)
                                  } else {
                                      handleSubmitPermissionToRole(+event.target.value, false)
                                  }
                                  setRole({ ...role, permissions: newsPermissionsToRole})
                              }}
                          />
                          <label className="form-check-label" htmlFor={permission.id}>
                              <strong>{permission.name}</strong> <small>- {permission.description}</small>
                          </label>
                      </div>
                  </div>
              ))}
          </Row>
          <Row>
            <Button
              disabled={loading}
              className="btn-rounded btn-lg bg-secondary mt-3"
              type="submit"
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin text-inverse mr-1"></i>
              ) : null}
              Salvar
            </Button>
          </Row>
        </form>
      </ContainerToForm>

    </>
  );
}
