import React, { useState, useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

import { URL_TO_IMAGES } from "../../../config/api.config"
import product from '../../../components/cotacao/product.svg'

const ModalProduto = ({ modal, toggle, produto }) => {
    const [image, setImage] = useState("")

    useEffect(() => {
        if (!!produto === false) {
            return
        }
        if (produto.images && produto.images.length > 0) {
            const images = !!produto.images[0].length ? produto.images[0] : produto.images
            if (images.length === 0) {
                return
            }
            if (images.some(i => i.featured_image === 1)) {
                setImage(URL_TO_IMAGES + '/' + images.find(i => i.featured_image === 1).path.replace('public', ''))
                return
            }
            if (!!images[0].length) {
                setImage(URL_TO_IMAGES + '/' + images[0].path.replace('public', ''))
            }
        }
    }, [produto])

    return (
        produto ? (
            <Modal isOpen={modal} toggle={toggle} centered={true} size="lg" >
                <ModalHeader toggle={toggle}>{produto && produto.name}</ModalHeader>
                <ModalBody>
                    <div className="media media-sm">
                        <img src={image || product} alt="" className="media-object rounded mr-3" />
                        <div className="media-body">
                            <div><strong>Descrição: </strong> <div dangerouslySetInnerHTML={{__html: produto.description}}></div> </div>
                            <div><strong>Informações técnicas: </strong> <div dangerouslySetInnerHTML={{__html: produto.technical_specifications}}></div> </div>
                            <div className="mb-2">
                                <p><strong>Unid. de medida</strong>: {produto.unit_measurements_name}</p>
                                <p><strong>Código de produto:</strong>: {produto.code}</p>
                                <p><strong>GETIN/EAN:</strong>: {produto.gtin_ean}</p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        ) : ""
    )
}

export default ModalProduto 