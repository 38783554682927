import React from "react"
import { Link } from "react-router-dom"

 const Pagination = () => {
    return(
        <ul className="pagination pull-right">
        <li className="page-item disabled">
          <Link to="/extra/search" className="page-link">
            «
          </Link>
        </li>
        <li className="page-item active">
          <Link to="/extra/search" className="page-link">
            1
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            2
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            3
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            4
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            5
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            6
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            7
          </Link>
        </li>
        <li className="page-item">
          <Link to="/extra/search" className="page-link">
            »
          </Link>
        </li>
      </ul>
    )
}

export default Pagination