import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import { updateEvaluations } from "../../services/evaluations";
import Comentarios from "./comentario"
const ListaComentarios = function ({
    lista,
    getItems,
    avisar,
    type,
    setLoading,
    loading
}) {
    const { user } = useSelector(state => state.auth)
    const [comentarios, setComentarios] = useState([])

    const updateComent = async ({ id, title, comment, stars}, callback) => {
        setLoading(true)
        window.scrollTo(0, 0)
        const input = {
            title: title,
            description: comment,
            status: "ACTIVE",
            stars: String(stars)
        }
        if (!comment) return avisar("Texto do comentário obrigatório.", "Texto não pode ser vazio", "danger")

        await updateEvaluations(id, input)
            .then(response => {
                avisar("Comentário editado", "Comentário editado com sucesso.", "success")
                if (callback) callback()
                getItems()
            })
            .catch(err => {
                if (err && err.response && err.response.data?.resposta?.conteudo?.message) {
                    avisar("Algo deu errado!", err.response.data.resposta.conteudo.message, "danger")
                }
            })
            .finally(() => setLoading(false))
    }
    
    useEffect(() => {
        if (lista) {
            let myComent = lista.find(item => user.id === item.customer_id)
            const restComent = lista.filter(item => user.id !== item.customer_id)
            if (myComent) {
                myComent = {...myComent, edit: true }
                return setComentarios([myComent, ...restComent])
            }
            setComentarios(restComent)
        }
    }, [lista])

    return (
        <>
            <ul className="media-list media-list-with-divider">
                {comentarios && comentarios.map((item, i) => {
                    return <Comentarios
                        item={item}
                        key={`listaComentarios_${i}`}
                        updateComent={updateComent}
                        edit={!!item.edit}
                        getItems={getItems}
                        avisar={avisar}
                        type={type}
                        setLoading={setLoading}
                        loading={loading}
                    />
                })}

            </ul>
        </>
    )

};

export default ListaComentarios