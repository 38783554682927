import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input } from 'reactstrap';
import { store } from 'react-notifications-component';

const ModalExample = (props) => {
  const {
    className,
    modalProduto,
    toggleModal
  } = props;

  const enviarMensagem = ()=>{
    store.addNotification({
      title: "Mensagem enviada!",
      message: "Agora é só aguardar que o fornecedor irá entrar em contato com você.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 10000,
        onScreen: true
      }
    });
    toggleModal(false)
  }
  
  return (
    <>
      <Modal isOpen={modalProduto} toggle={toggleModal} className={className} size="lg" centered>
        <ModalHeader toggle={toggleModal}>Orçar</ModalHeader>
        <ModalBody>
            <Alert color="warning">
            Especifique tudo oque for possivel nesse campo. Ex: cor, tamanho forma de pagamento etc...
            </Alert>
              <div className="form-group">
                <label className="opacity-5 mb-1">Mensagem</label>
                <Input
                  placeholder="Mensagem"
                  type="textarea"
                  className="form-control"
                />
              </div>

        </ModalBody>
        <ModalFooter>
          <Button block color="secondary" onClick={enviarMensagem}>Enviar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalExample;