import React, { useEffect, useState } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Alert, Input, Modal } from 'reactstrap';
import { store } from 'react-notifications-component';
import Produto from "../../components/cotacao/produto"
import { getUnitsMeasurement } from '../../services/general'
import ItemProduto from "../../components/imProcurar/index"
import { getOtherProducts, getProduct } from "../../services/product";
import { useParams } from "react-router-dom";
import { URL_TO_IMAGES } from '../../config/api.config';
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import product from '../../components/cotacao/product.svg'
import ProdutoCotacao from './parts/produtoCotacao';
import { orderBy } from '../../uteis';

const CotarModal = ({ modal, avisar, toggle, setLoading, produtosStorage, setProdutosStorage }) => {
    const [unidadesMedidas, setUnidadesMedidas] = useState([])
    const [unidadesMedidasCat, setUnidadesMedidasCat] = useState([])
    const [todasUnidadesMedidas, setTodasUnidadesMedidas] = useState([])
    const [produtos, setProdutos] = useState({ data: [] })
    const [loadingSearch, setLoadingSearch] = useState(false)

    const [produto, setProduto] = useState({})
    const [quantidade, setQuantidade] = useState(0)
    const [unidadeMedida, setUnidadeMedida] = useState(0)
    const [observacao, setObservacao] = useState("")

    const enviarMensagem = () => {
        store.addNotification({
            title: "Sucesso!",
            message: "Produto adicionado a cotação!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 10000,
                onScreen: true
            }
        });
        toggle(false)
    }

    const getUnidades = async (searchItem = '') => {
        setLoading(true)
        await getUnitsMeasurement(searchItem).then(response => {
            const data = response.data.resposta.conteudo.unitMeasurement
            const categorias = []
            data.forEach(unid => {
                const hasThisCat = categorias.some(element => element === unid.unit_measurement)
                if (hasThisCat) return
                categorias.push(unid.unit_measurement)
            });
            setUnidadesMedidasCat(categorias)
            setTodasUnidadesMedidas(data)
        })
            .catch(error => {
                if (error && error.response?.data?.message) {
                    avisar("Ops!!", error.response.data.message, "danger")
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => setLoading(false))
    }

    const getProdutos = async (searchItem = {}) => {
        if (searchItem.name) {
            setLoadingSearch(true)
        }
        const companyToURL = JSON.parse(getUrlParam('produto'))
        const company_id = companyToURL?.company_id || parseInt(window.location.href.split('/').pop())
        await getOtherProducts({...searchItem, company_id }).then(response => {
            setProdutos(response.data.resposta.conteudo.products)
        })
            .catch(error => {
                if (error && error.response?.data?.message) {
                    avisar("Ops!!", error.response.data.message, "danger")
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => setLoadingSearch(false))
    }
    
    const searchProduto = (searchItem) => {
        getProdutos({name: searchItem})
    }

    const adicionar = () => {
        if (!produto.unit_measurement && !unidadeMedida) {
            toast.error(
                "Erro! Unidade de medida e quantidade são obrigatórios!",
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 10000,
                }
            );
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Unidade de medida e quantidade são obrigatórios!",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 10000,
            //         onScreen: true
            //     }
            // });
            return
        }
        if (!quantidade) {
            toast.error(
                "Erro! Campo quantidade é obrigatórios!",
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 10000,
                }
            );
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Campo quantidade é obrigatórios!",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 10000,
            //         onScreen: true
            //     }
            // });
            return
        }
        
        const newProduto = {
            toSave: {
                product_id: produto.id,
                unit_measurements_id: parseInt(unidadeMedida) || parseInt(produto.unit_measurement_id),
                quantity: quantidade,
                observations: observacao
            },
            toShow: {
                produto: produto,
                unidade: unidadesMedidas.find(u => u.id === (parseInt(unidadeMedida) || parseInt(produto.unit_measurement_id))) || produto.unit_measurement,
                quantidade,
                observacao
            }
        }
        console.log('prod ctr', newProduto);
        let newProdutos = [...produtosStorage]
        newProdutos.push(newProduto)
        setProdutosStorage(newProdutos)
        
        setUnidadesMedidas([])
        setProdutos({ data: [] })
        setLoadingSearch(false)
        setProduto("")
        setQuantidade(0)
        setUnidadeMedida(0)
    
        enviarMensagem()
        const companyToURL = JSON.parse(getUrlParam('produto'))
        if (companyToURL) {
            window.history.replaceState(null, null, window.location.pathname)
        }
    }

    const getUrlParam = (param) => {
        const urlProduto = new URLSearchParams(window.location.search.substring(1))
        return urlProduto.get(param)
    }

    const getProduto = async idProduto => {
        setLoading(true)
        await getProduct(idProduto)
            .then(response => {
                const prod = response.data.resposta.conteudo.product                
                setProduto(prod)
                if (!!prod.unit_measurements_id) {
                    setTodasUnidadesMedidas([prod.unit_measurement])
                    searchProduto(prod.name)
                    setProduto(prod.name)
                }
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const prodId = parseInt(getUrlParam('produto'))
        if (!!prodId) {
            getProduto(prodId)
            getUnidades()
        }
    }, [window.location.pathname])

    return (
        <>
            <ToastContainer />

            <Modal isOpen={modal} toggle={toggle} size="lg" centered>
                <ModalHeader toggle={toggle}>Adicionar Produto</ModalHeader>
                <ModalBody>
                    {
                        produto.name && (
                           <ProdutoCotacao produto={produto} />
                        )
                    }
                    <div className="row">
                        <div className="col-sm-12 col-xl-9 mb-9">
                            <div className="form-group">
                                <label className="opacity-5 mb-1">Uni. de Medida</label>
                                <div className="d-flex">
                                    {
                                        !!produto.name && !!produto.unit_measurement_id ? (
                                            // <h3>{produto.unit_measurement.name_portuguese}</h3>
                                            <input
                                                name="produto"
                                                // defaultValue={quantidade}
                                                value={produto.unit_measurement.name_portuguese}
                                                type="text"
                                                className="form-control"
                                                style={{ textAlign: "center" }}
                                                disabled
                                            />
                                        ) : (
                                            <>
                                                <Input
                                                    type="select"
                                                    name="selectMulti"
                                                    id="exampleSelectMulti"
                                                    onChange={(e) => {
                                                        setUnidadeMedida(e.target.value)
                                                    }}
                                                >
                                                    <option>Selecione a Medida...</option>
                                                    {
                                                        orderBy(todasUnidadesMedidas, 'name_portuguese').map(unid => (                                                        
                                                            <option key={unid.id} value={unid.id}> {unid.name_portuguese} ({unid.acronym})</option>
                                                        ))
                                                    }
                                                </Input>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-3 mb-3">
                            <div className="form-group">
                                <label className="opacity-5 mb-1">Quantidade</label>
                                <div style={{ width: "100%" }}>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <button type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    let newQuantidade = quantidade === 0 ? 0 :  quantidade-1
                                                    setQuantidade(newQuantidade)
                                                }}
                                            >
                                                -
                                            </button>
                                        </div>
                                        <input
                                            name="demo_vertical"
                                            // defaultValue={quantidade}
                                            value={quantidade}
                                            type="text"
                                            className="form-control"
                                            style={{ textAlign: "center" }} 
                                            // readOnly
                                            onChange={(e) => {
                                                setQuantidade(parseInt(e.target.value))
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    let newQuantidade = quantidade+1
                                                    setQuantidade(newQuantidade)
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-12 mb-12">
                            <div className="form-group">
                                <label className="opacity-5 mb-1">Observação</label>
                                <Input
                                    placeholder=""
                                    type="textarea"
                                    className="form-control"
                                    defaultValue={observacao}
                                    onChange={(e) => {
                                        setObservacao(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button block color="secondary" onClick={adicionar}>Adicionar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CotarModal;