import React, { useEffect, useState } from "react";
import { useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DataTable from "react-data-table-component";
import { store } from "react-notifications-component";
import { Button } from "reactstrap";
import SearchFor from "../busca/SearchFor";

const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};


async function udpateItem(item, requests, getData, setLoading, setBloquear) {
  const statusMsg = !item.active ? 'ativado' : 'desativado';

  return await requests
    .update({...item, active: !item.active})
    .then(() => {
      getData();
      avisar(
        `Item ${statusMsg} com sucesso!`,
        `O item agora está ${statusMsg}`,
        "success"
      );
    })
    .catch((error) => {
      if (error && error.response?.data?.resposta) {
        const messages = error.response.data?.resposta?.conteudo?.messages;
  
        if (error.response.data.resposta?.conteudo?.error && !messages) {
          avisar(
            "Ops!!",
            error.response.data.resposta?.conteudo?.error,
            "danger"
          );
        }
  
        if (messages) {
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              avisar("Ops!!", message, "danger");
            });
          });
        }
        return;
      }
  
      avisar("Ops!!", "Erro interno no servidor!", "danger");
    })
    .finally(() => {
      setLoading(false);
      setBloquear(null);
    });
}

async function deleteItem(item, requests, getData, setLoading, setDeletar) {
  await requests
  .delete(item.id)
  .then((response) => {
    getData();
    avisar("Sucesso!", response.data.resposta.conteudo.message, "success");
  })
  .catch((error) => {
    if (error && error.response?.data) {
      const messages = error.response.data.resposta.conteudo.messages;
      Object.keys(messages).forEach((key) => {
        messages[key].forEach((message) => {
          avisar("Ops!!", message, "danger");
        });
      });
      return;
    }
    avisar("Ops!!", "Erro interno no servidor!", "danger");
  })
  .finally(() => {
    setLoading(false);
    setDeletar(null);
  });
}

export default function TableDefault({
  columns = [],
  editCallback,
  dataRequest,
  type,
  hasEdit = true,
  hasBlock = true,
  hasDelete = true,
  expandable = false,
  defaultParams = {},
  tiposDeBusca,
}) {
  const mounted = useRef(false);

  const [itemDelete, setItemDelete] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bloquear, setBloquear] = useState(null);

  const [keyword, setKeyword] = useState("");
  const [searchFor, setSearchFor] = useState("name");

  const pegaResultados = (resetSearch = false) => {
    if (resetSearch) {
      getData({});
      return;
    }
    getData({ [searchFor]: keyword });
  };

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  
  const getData = async (params = {}) => {
    setLoading(true);
    await dataRequest
      .list({ per_page: perPage, ...params, ...defaultParams })
      .then((reqData) => {
        if (mounted.current) {
          const { data, ...rest } = reqData?.data?.resposta?.conteudo[type];
          setData(data);
          setTotalRows(rest.total);
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const confirmar = async (type = 'deletar') => {
    setLoading(true);
    if (type === 'bloquear') {
      setBloquear(null)
      await udpateItem(bloquear, dataRequest, pegaResultados, setLoading, setBloquear);
      return
    }
    setItemDelete(null)
    await deleteItem(itemDelete, dataRequest, pegaResultados, setLoading, setItemDelete);
  };

  const handlePageChange = (page) => {
    getData({ page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getData({ page, per_page: newPerPage });
    setPerPage(newPerPage);
  };

  const actions = (row) => {
    const hasActiveKey = Object.keys(row).includes('active');
    const isActive = !hasActiveKey ? true : row.active === 1 ? true : false
    return (
      <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
        {(hasEdit && isActive) && (
          <Button
            onClick={() => editCallback(row.id)}
            className="btn btn-success btn-icon btn-circle btn-lg m-3"
            title="Editar"
          >
            <i
              className="fas fa-edit"
              style={{ cursor: "pointer", fontSize: "15px" }}
            ></i>
          </Button>
        )}
        {hasBlock && hasActiveKey && (
          <Button
            onClick={() => setBloquear(row)}
            className="btn btn-warning btn-icon btn-circle btn-lg m-3"
            title={isActive ? "Bloquear" : "Desbloquear"}
          >
            {
              isActive ? 
                <i className="fa fa-ban"></i> :
                <i className="fal fa-play"></i>
            }
          </Button>
        )}
        {hasDelete && (
          <Button
            onClick={() => setItemDelete(row)}
            className="btn btn-danger btn-icon btn-circle btn-lg m-3"
            title="Deletar"
          >
            <i
              className="fas fa-trash"
              style={{ cursor: "pointer", fontSize: "15px" }}
            ></i>
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    mounted.current = true;
    getData();

    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return (
    <>
      <SearchFor
        pegaResultados={pegaResultados}
        tiposDeBusca={tiposDeBusca}
        searchFor={searchFor}
        setSearchFor={setSearchFor}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      <div
        className="table-responsive"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {itemDelete && (
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Sim, deletar!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={confirmar}
            onCancel={() => setItemDelete(null)}
          >
            Após essa ação Você não conseguira recuperar os dados.
          </SweetAlert>
        )}
        {bloquear && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Sim, Bloquear!"
            confirmBtnBsStyle="warning"
            cancelBtnBsStyle="default"
            title="Você tem certeza?"
            onConfirm={() => confirmar('bloquear')}
            onCancel={() => setBloquear(null)}
          >
            Após essa ação o Produto irá ficar {!bloquear.active ? 'ativado' : 'desativado'}.
          </SweetAlert>
        )}

        <DataTable
          responsive
          columns={[
            ...columns.map((col) => ({
              ...col,
              name: typeof col === "string" ? col : col.name,
              selector: (row) => {
                const currentKey = typeof col === "string" ? col : col.key;
                return col?.render
                  ? col?.render(row[currentKey])
                  : row[currentKey];
              },
            })),
            {
              name: "Ações",
              selector: () => {},
              format: (row, index) => actions(row),
              right: true,
            },
          ]}
          conditionalRowStyles={[
            {
              when: row => row.active === 1,
              style: { filter: 'none' },
            },
            {
              when: row => row.active === 0,
              style: { filter: 'grayscale(1)' },
            },
          ]}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationComponentOptions={{
            rowsPerPageText: "Registros por página",
            rangeSeparatorText: "de",
            selectAllRowsItem: true,
            selectAllRowsItemText: "Todos",
          }}
          highlightOnHover
          pointerOnHover
          striped
          expandableRows={expandable}
          expandableRowsComponent={({ data }, t) => {
            return (
              <div className="p-15">
                {columns.map((col) => (
                  <span key={col.key} className="m-2">
                    <b className="m-2">{col.name}:</b>
                    {data[col.key]}
                  </span>
                ))}
              </div>
            );
          }}
        />
      </div>
    </>
  );
}
