import React, { useState, useEffect, useContext } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from "../../components/loader";

import ModalProduto from "./parts/modalProduto";

import { PageSettings } from "../../config/page-settings"
import Topo from "../../components/cotacao/topo"
import TitlePage from "../../components/pages/title"
import CotarModal from "./cotarModal"
import { createQuotation } from "../../services/quotations";
import { Link, useHistory, useParams } from "react-router-dom";
import { getCompanie } from "../../services/companies";
import { store } from "react-notifications-component";
import ProdutoCotacao from "./parts/produtoCotacao";

const avisar = (title, msg, type) => {
  store.addNotification({
      title: title || "Usuário ou senha incorretos",
      message: msg || "Um código de verificação foi enviado para o seu email!",
      type: type || "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
          duration: 5000,
          onScreen: true
      }
  });
}

const Cotar = () => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalProduto, setModalProduto] = useState(false)
  const [produtosStorage, setProdutosStorage] = useState([])
  const [empresa, setEmpresa] = useState({})
  const [itemToDelete, setItemToDelete] = useState(null)
	const [produtoToModal, setProdutoToModal] = useState({})

	const [hasCotacao, setHasCotacao] = useState(false)
  
  let history = useHistory();
  const params = useParams()
  
  const toggleModalProduto = () => setModalProduto(!modalProduto)
  
  const contextType = useContext(PageSettings);
  const toggleModal = () => {
    setModal(!modal)
  }

  const solicitar = () => {
    const dataTosave = {
      company2_id: produtosStorage[0]?.toShow.produto.company_id,
      observations: "",
      items: produtosStorage.map(item => item.toSave)
    }
    createQuotation(dataTosave)
      .then(response => {
        avisar("Sucesso", "Cotação enviada!", "success")
        setProdutosStorage([])
        localStorage.removeItem('cotacao-produtos')
        history.push('/cotacao/minhascontacao/pendentes')
      })
      .catch(error =>  {
        
        console.log(error)
        
        avisar("Erro", "Não é possível enviar uma solicitação de cotação pra sua própria empresa.", "danger")
      })
  }

  const deleteProduto = (idProduto) => {
    const newProds = produtosStorage.filter(prod => prod.toShow.produto.id !== idProduto)
    setProdutosStorage(newProds)
    localStorage.setItem('cotacao-produtos', JSON.stringify(newProds))
    if (newProds.length === 0) {
      localStorage.removeItem('cotacao-produtos')
    }
  }

  const getEmpresa = async idEmpresa => {
    setLoading(true)
    await getCompanie(idEmpresa)
      .then(response => {
        setEmpresa(response.data.resposta.conteudo.empresa)
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (localStorage.getItem('cotacao-produtos')) {
      setProdutosStorage(JSON.parse(localStorage.getItem('cotacao-produtos')))
    }
    const urlProduto = new URLSearchParams(window.location.search.substring(1))
    if (urlProduto.get('produto')) {
      if (localStorage.getItem('cotacao-produtos') && JSON.parse(localStorage.getItem('cotacao-produtos')).length > 0) {
        if (parseInt(params.idCompany) === JSON.parse(localStorage.getItem('cotacao-produtos'))[0].toShow.produto.company_id) {
          toggleModal()
        } else {
          setHasCotacao(true)
        }
      } else {
        toggleModal()
        setHasCotacao(false)
      }
    }

    getEmpresa(parseInt(window.location.pathname.split('/').pop()))

    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
  }, [window.location.pathname]);

  useEffect(() => {
    if (produtosStorage.length > 0) {
      localStorage.setItem('cotacao-produtos', JSON.stringify(produtosStorage))
    }
  }, [produtosStorage])

  return (
    <>    
      {
        hasCotacao && (
          <SweetAlert warning showCancel
            confirmBtnText="Sim, excluir!"
            confirmBtnBsStyle="danger"
            cancelBtnText="Cancelar"
            cancelBtnBsStyle="default"
            title="Existe uma Cotação aberta para outra Empresa"
            onConfirm={() => {
              setHasCotacao(false)
              localStorage.removeItem('cotacao-produtos')
              toggleModal()
              setProdutosStorage([])
            }}
            onCancel={() => {
              setHasCotacao(false)
              history.push(`/cotacao/cotar/${produtosStorage[0].toShow.produto.company_id}`)
            }}
          >
            Deseja excluir a cotação e abrir outra pra esse produto?
          </SweetAlert>
        )
      }
      <ModalProduto
        modal={modalProduto}  
        toggle={toggleModalProduto} 
        produto={produtoToModal}
        setProdutoToModal={setProdutoToModal}
      />

      <Loader loading={loading} />
      <TitlePage title="Cotar" desc="Cotação de produtos." icon="fal fa-box" />
      
      <CotarModal
        avisar={avisar}  
        produtosStorage={produtosStorage} 
        setProdutosStorage={setProdutosStorage} 
        toggle={toggleModal} 
        setLoading={setLoading} 
        modal={modal} 
      />

      <div className="invoice p-20">

        <Topo empresa={empresa} hidePdf={true} />
        <div className="invoice-content">
          <button className="btn btn-orange btn-circle  mt-3" onClick={() => history.push(`/fornecedor/${window.location.pathname.split('/').pop()}`)}>
            <i className="fa fa-plus mr-2"></i>
              Produto
          </button>
        </div>

        <div className="list-produtos"
          style={{
            background: '#f9fafa',
            padding: '1rem',
            borderRadius: '5px'
          }}
        >
          {
            produtosStorage.length > 0 && (
              produtosStorage.map(({toShow}) => {
                return <div key={toShow.produto.id} style={{
                  background: '#fff',
                  padding: '.5rem 1rem',
                  boxShadow: '0px 10px 5px 0px #eff4f9',
                  marginBottom: '.9rem',
                  position: 'relative'
                }}>
                  {/* <h4>{toShow.produto.name}</h4> */}
                  <ProdutoCotacao
                    produto={toShow.produto}
                    observacao={toShow.observacao}
                    type={'list'}
                    toggleModal={toggleModalProduto}
                    setProdutoToModal={setProdutoToModal}
                  />
                  <div style={{
                    background: '#f2f3f4',
                    padding: '.5rem'
                  }}>
                    <p style={{
                      fontSize: '1rem',
                      fontWeight: '300',
                      margin: 0
                    }}><small>Quantidade:</small> <strong>{toShow.quantidade}</strong></p>
                    <p style={{
                      fontSize: '1rem',
                      fontWeight: '300',
                      margin: 0
                    }}><small>Unidade de Medida:</small> <strong>{toShow?.unidade?.name_portuguese || ''}</strong></p>
                  </div>
                  
                  <i className="fas fa-trash-alt" style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#e80000',
                    fontSize: '1rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => deleteProduto(toShow.produto.id)}></i>
                </div>
              })
            )
          }
        </div>

        <div className="invoice-price">
          <button className="btn btn-block btn-lg btn-primary" onClick={solicitar}>
            Solicitar Cotação
          </button>
        </div>
      </div>

    </>
  );
};

export default Cotar;
