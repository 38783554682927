import React from "react"
import {Modal, ModalHeader, Button, ModalFooter, ModalBody, Input, Row } from "reactstrap"
import "./style.css"
const ModalFiltro = ({modal, toggle}) =>{
    return(
        <Modal isOpen={modal} toggle={toggle} className={"modal-dialog modal-dialog-right "}>
        <ModalHeader toggle={toggle}>Pesquisa avançada</ModalHeader>
        <ModalBody>
        <Row className="mt-2 mb-2">
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Atividade econômica</label>
                <input
                  placeholder="Atividade econômica"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12">
                <label className="opacity-5 mb-1">Tipo da empresa</label>
                <div className="form-group">
                    <div className="checkbox checkbox-css">
                        <input name="1" type="checkbox" id="cssCheckbox1" />
                        <label htmlFor="cssCheckbox1">Fabricante</label>
                    </div>
                    <div className="checkbox checkbox-css">
                        <input name="2" type="checkbox" id="cssCheckbox3" />
                        <label htmlFor="cssCheckbox1">Atacadista</label>
                    </div>
                    <div className="checkbox checkbox-css">
                        <input name="3" type="checkbox" id="cssCheckbox5" />
                        <label htmlFor="cssCheckbox1">Distribuidor</label>
                    </div>
                    <div className="checkbox checkbox-css">
                        <input name="4" type="checkbox" id="cssCheckbox7" />
                        <label htmlFor="cssCheckbox1">importador</label>
                    </div>
                    <div className="checkbox checkbox-css">
                        <input name="5" type="checkbox" id="cssCheckbox2" />
                        <label htmlFor="cssCheckbox1">Exportador</label>
                    </div>
                    <div className="checkbox checkbox-css">
                        <input name="6" type="checkbox" id="cssCheckbox123" />
                        <label htmlFor="cssCheckbox1">Prestador de serviço</label>
                    </div>
                    <div className="checkbox checkbox-css">
                        <input type="checkbox" id="cssCheckbox134" />
                        <label htmlFor="cssCheckbox1">Fornecedor de Matėria Prima</label>
                    </div>
              </div>
            </div>
            <hr/>
            <div className="col-md-3">
                <div className="form-group">
                  <label className="opacity-5 mb-1">Estado</label>
                  <Input type="select" name="select" className="form-round" id="exampleSelect">
                    <option>Todos</option>
                    <option>AA</option>
                    <option>SP</option>
                    <option>RJ</option>
                </Input>
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <label className="opacity-5 mb-1">Cidade</label>
                <Input type="select" name="select" className="form-round" id="exampleSelect">
                    <option>Todos</option>
                    <option>Carapicuiba</option>
                    <option>Osasco</option>
                    <option>Barueri</option>
                </Input>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Categoria</label>
                <Input type="select" name="select" className="form-round" id="exampleSelect">
                    <option>Todos</option>
                    <option>Celular</option>
                </Input>
              </div>
            </div>
          </Row>

         </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Filtrar</Button>{' '}
        </ModalFooter>
      </Modal>
    )
}

export default ModalFiltro