import api from "../api";

export const getUser = (idUser) =>  api.get(`auth/users/${idUser}`)
export const saveUser = (idUser, data) =>  api.put(`auth/users/${idUser}`, data)
export const sendTokenToChangeEmail = (data) =>  api.post(`auth/tokenTrocarEmail`, data)
export const changeEmail = (data) =>  api.post(`auth/updateEmail`, data)

export const emailVerified = () => api.get('auth/email/resend')

export const sendTokenToDelete = () => {
    return new Promise( (resolve, reject)=>{
        api.post(`auth/send-token-delete-account`)
                .then( data =>{
                    resolve(data)
                } )
                .catch((err)=>{
                    reject(err)
                })

    } )
}

export const deletarConta = (token) => {
    console.log(token)
    return new Promise( (resolve, reject)=>{
        api.post(`auth/delete-account`, {token})
                .then( data =>{
                    resolve(data)
                } )
                .catch((err)=>{
                    reject(err)
                })
    } )
}