
export const menus = [
  { path: '/', icon: 'fas fa-chart-area', title: 'Dashboard', exact: true},
  {
    path: '/adminstracao',
    icon: 'fa fa-eye',
    title: 'Administração',
    children: [
      { path: '/usuarios', title: 'Usuários' },
      { path: '/perfis', title: 'Perfis' },
      { path: '/permissoes', title: 'Permissões' },
    ]
  },
  {
    path: '/painel-de-controle',
    icon: 'fa fa-eye',
    title: 'Painel de Controle',
    children: [
      { path: '/fornecedores', title: 'Fornecedores' },
      { path: '/compradores', title: 'Compradores' },
      { path: '/cotacoes', title: 'Cotações' },
      { path: '/produtos', title: 'Produtos' },
      { path: '/servicos', title: 'Serviços' },
    ]
  },
]
