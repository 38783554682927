import React from 'react'

export default function FormComentario({
    stars,
    setStars, 
    title, 
    setTitle, 
    comment,
    setComment,
    createComent,
    updateComent,
    type,
    id,
    titleSendButton
}) {

    return (
        <form className="comentar">
            <h4 className="media-heading">Seu comentário.</h4>
            <div className="rating mt-2 mb-1">
                <p className="mr-2">sua nota: {stars}</p>
                {
                    [1, 2, 3, 4, 5].map(item => (
                        <span
                            key={item}
                            className={`m-1 p-0 btn star ${item <= stars && 'active'}`}
                            onClick={() => setStars(item)}
                        ></span>
                    ))
                }
            </div>
            <div className="input-group mb-2">
                <input
                    type="text"
                    className="form-control bg-light"
                    placeholder="Título do coméntaio"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="input-group mb-2">
                <textarea
                    type="text"
                    className="form-control bg-light"
                    placeholder="Escreva seu coméntaio"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <button
                className="btn btn-green"
                type="button"
                onClick={() => {              
                    if (createComent) {
                        createComent({ title, comment, stars, type }, () => { setComment(""); setTitle("") })
                        return
                    }
                    updateComent({ id, title, comment, stars, type })
                    return 
                }}
            >
                <i className="fa fa-paper-plane"></i> {titleSendButton || 'Enviar'}
            </button>
        </form>
    )
}
