import api from "../api";
import { BASE_URL_ADMIN } from "../../config/api.config";

const baseUrl = `${BASE_URL_ADMIN}/companies`;
// {{base_url_server}}auth/admin/companies?type=1&active=1

export const companiesRequests = {
    list: (params = {}) => api.get(`${baseUrl}`, { params }),
    show: (id) => api.get(`${baseUrl}/${id}`),
    create: (values) => api.post(`${baseUrl}/add`, values),
    update: (values) => api.put(`${baseUrl}/${values.id}`, values),
    delete: (id) => api.delete(`${baseUrl}/${id}`),
    qtd: (params = {}) => api.get(`${baseUrl}/qtd-companies`, { params })
}
