import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../../config/api.config";

const btns = (item) => {
  return (
    <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
      {
        item.produto.active === 1 && (
          <Link
            to={`/produtos/editar/${item.produto?.id}?name=${item.produto?.name}&desc=${item.produto?.description}`}
            className="btn btn-success btn-icon btn-circle btn-lg m-3"
            title="Editar"
          >
            <i className="fa fa-edit"></i>
          </Link>
        )
      }
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setBloquear(item.produto);
        }}
        className="btn btn-warning btn-icon btn-circle btn-lg m-3"
        title={item.produto.active === 1 ? "Bloquear" : "Desbloquear"}
      >
        {
          item.produto.active === 1 ? 
            <i className="fa fa-ban"></i> :
            <i className="fal fa-play"></i>
        }
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setDeletar(item.produto);
        }}
        className="btn btn-danger btn-icon btn-circle btn-lg m-3"
        title="Deletar"
      >
        <i className="fa fa-times"></i>
      </Button>
    </div>
  );
};

const ProdutoItem = (props) => {
  const { produto } = props;

  const image = produto.images[0]?.path
    ? `${URL_TO_IMAGES}/${produto.images
        .find((img) => img.featured_image === 1)
        ?.path.replace("public", "")}`
    : "/assets/img/default-product.svg";

  return (
    <li style={{
      filter: produto.active === 1 ? 'none' : 'grayscale(1)'
    }}>
      <div
        to={`/produtos/${produto.id}`}
        className="result-image"
        style={{
          backgroundImage: `url( ${image} )`,
          backgroundSize: "contain",
          backgroundColor: "white",
        }}
      >
        <div className="p-3 d-sm-none" style={{ zIndex: 4 }}>
          {props.isUser ? btns(props) : null}
        </div>
      </div>
      <div className="result-info">
        <h4 className="title">
          <Link to={`/produtos/${produto.id}`}>{produto.name}</Link>
        </h4>
        <span className="badge badge-secondary">{produto.category.name}</span>

        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: produto.description }}
        ></div>

        <div className="btn-row">
          {produto.avg && (
            <Link
              to={`/produtos/${produto.id}`}
              data-toggle="tooltip"
              data-container="body"
              data-title="Analytics"
            >
              <>
                <i className="far fa-star"></i> {produto.avg}
              </>
            </Link>
          )}
          <Link
            to={`/produtos/${produto.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            <i className="far fa-eye"></i> {produto?.hit || 0}
          </Link>
        </div>
      </div>
      <div className="result-price" style={{ zIndex: 4 }}>
        <div className="d-none d-sm-block align-self-center align-self-sm-end">
          {btns(props)}
        </div>
        {
          produto.active === 1 && (
            <Link
              to={`/produtos/${produto.id}`}
              className="btn btn-yellow btn-block"
            >
              {props.servico ? "Ver serviço" : "Ver produto"}
            </Link>
          )
        }
      </div>
    </li>
  );
};

export default ProdutoItem;
