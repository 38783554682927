import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Modal, ModalHeader, Button, ModalFooter, ModalBody, Input, Row } from "reactstrap"
import { getCompanyType, getEconomicActivity } from "../../../services/companies"
import { getCities, getCountries, getUfs } from "../../../services/regions"
import { orderBy } from "../../../uteis"

import "./style.css"

const ModalFiltro = ({ modal, toggle, setLoading, getResults, params, setParams }) => {  
  const [atividades, setAtividades] = useState([])
  const [tipos, setTipos] = useState([])
  const [paises, setPaises] = useState([])
  const [estados, setEstados] = useState([])
  const [municipios, setMunicipios] = useState([])

  const pegaAtividades = async () => {
      setLoading(true)
      await getEconomicActivity()
          .then(response => {
              setAtividades(response.data.resposta.conteudo.companiesType)
          })
          .catch(error => console.log(error))
          .finally(() => setLoading(false))
  }
  const pegaTipos = async () => {
      setLoading(true)
      await getCompanyType()
          .then(response => {
              setTipos(response.data.resposta.conteudo.companiesType)
          })
          .catch(error => console.log(error))
          .finally(() => setLoading(false))
  }
  const pegaPaises = async () => {
      await getCountries()
          .then(response => {
              setPaises(response.data.resposta.conteudo.countries.data)
          })
          .catch(error => console.log(error))        
  }
  const pegaEstados = async (idCountry) => {
      await getUfs(idCountry)
          .then(response => {
              setEstados(response.data.resposta.conteudo.ufs)
          })
          .catch(error => console.log(error))        
  }
  const pegaMunicipios = async (idUf) => {
      await getCities(idUf, 'all')
          .then(response => {
              setMunicipios(response.data.resposta.conteudo.city)
          })
          .catch(error => console.log(error))        
  }

  useEffect(() => {
    pegaAtividades()
    pegaTipos()
    pegaPaises()
  }, [])

  return (
    <Modal isOpen={modal} toggle={toggle} className={"modal-dialog modal-dialog-right "}>
      <ModalHeader toggle={toggle}>Pesquisa avançada</ModalHeader>
      <ModalBody>
        <Row className="mt-2 mb-2">
          <div className="col-md-12">
            <div className="form-group">
              <label className="opacity-5 mb-1">Atividade econômica</label>
              {
                atividades && (
                  <Select
                    value={params.atividade}
                    options={orderBy(atividades, 'label')}
                    name="colors"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={event => {
                      setParams({ ...params, atividade: event ? event : '' })
                    }}
                    getOptionValue={option => option['description']}
                    getOptionLabel={option => `${option.description}`}
                  />
                )
              }
            </div>
          </div>
          <div className="col-md-12">
            <label className="opacity-5 mb-1">Tipo da empresa</label>            
            {
              tipos && (
                <Select
                  value={params?.tipo?.length > 0 ? params.tipo : []}
                  options={orderBy(tipos, 'label')}
                  name="colors"
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={event => {
                    setParams({ ...params, tipo: event ? event : [] })
                  }}
                  getOptionValue={option => option['description']}
                  getOptionLabel={option => `${option.description}`}
                />
              )
            }
          </div>
          <hr />
          <div className="col-md-12">
            <div className="form-group">
              <label className="opacity-5 mb-1">Pais</label>
              <Input
                type="select"
                name="select"
                className="form-round"
                onChange={event => {
                  pegaEstados(event.target.value)
                  setParams({ ...params, pais: paises.find(pais => pais.id === parseInt(event.target.value)) })
                }}
              >
                <option>Todos</option>  
                {
                  paises && paises.map(pais => (
                    <option key={pais.id} value={pais.id}>{pais.namePortuguese}</option>
                  ))
                }
              </Input>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="opacity-5 mb-1">Estado</label>
              <Input
                type="select"
                name="select"
                className="form-round"
                onChange={event => {
                  pegaMunicipios(event.target.value)
                  setParams({ ...params, uf: estados.find(estado => estado.id === parseInt(event.target.value)) })
                }}
                disabled={estados.length === 0}
              >
                <option>Todos</option>
                {
                  estados && estados.map(estado => (
                    <option key={estado.id} value={estado.id}>{estado.name}</option>
                  ))
                }
              </Input>
            </div>
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <label className="opacity-5 mb-1">Cidade</label>
              <Input
                type="select"
                name="select"
                className="form-round"
                onChange={event => {
                  console.log('params', );
                  setParams({ ...params, cidade: municipios.find(municipio => municipio.id === parseInt(event.target.value)) })
                }}
                disabled={municipios.length === 0}
              >
                <option>Todos</option>
                {
                  municipios && municipios.map(municipio => (
                    <option key={municipio.id} value={municipio.id}>{municipio.name}</option>
                  ))
                }
              </Input>
            </div>
          </div>
        </Row>

      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            toggle()
            getResults({
              atividade: params?.atividade?.description?.toLowerCase() || '',
              cidade: params?.cidade?.name?.toLowerCase() || '',
              uf: params?.uf?.sigla_uf?.toLowerCase() || '',
              pais: params?.pais?.namePortuguese?.toLowerCase() || '',
              pesquisar: params?.pesquisar?.toLowerCase() || '',
              tipo: params?.tipo?.map(tipo => tipo.description) || []
            })
          }}
        >
          Filtrar
        </Button>{' '}
        <Button
          color="danger"
          onClick={() => {
            toggle()
            setParams({pesquisar: 'fornecedor'})
            getResults({pesquisar: 'fornecedor'})
          }}
        >
          Limpar
        </Button>{' '}
      </ModalFooter>
    </Modal>
  )
}

export default ModalFiltro