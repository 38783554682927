import React from "react"
import {Card, CardBody, CardHeader, Collapse} from "reactstrap"
const ItemAcordion = ({nome, open, func, children, posicao, styles }) =>
<Card style={ styles || {}}>
<CardHeader  className={'card-header pointer-cursor ' + (!open ? 'collapsed ' : '')} onClick={() => func(!open)}>
    <div className="d-flex">
        <div className={`btnPosition ${open ? "bg-green" : "bg-black-transparent-4"}`}>{posicao}</div>
        {nome}

    </div>
</CardHeader>
<Collapse isOpen={open}>
    <CardBody>
        {children}
    </CardBody>
</Collapse>
</Card>

export default ItemAcordion