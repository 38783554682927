import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/loader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap';

import {
    InputGroup,
    InputGroupButtonDropdown
} from "reactstrap";
import { PageSettings } from "../../config/page-settings"

import TitlePage from "../../components/pages/title"
import SweetAlert from 'react-bootstrap-sweetalert';
import CountryControl from "./paisControler"
import { paises as fakePaisesInit } from "./paises"
import { getCountries, getCountry, getUfs, getCities } from "../../services/regions";
import { createServiceRegion, getServiceRegion } from "../../services/regions/regions-service";
import { store } from "react-notifications-component";

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [fakePaises, setFakePaises] = useState(fakePaisesInit)
    const [modalPaises, setModalPaises] = useState(false)
    const contextType = useContext(PageSettings);
    const [deletar, setDeletar] = useState(null)
    const [modalCities, setModalCities] = React.useState({show: false, cities: []});
    const [selectedState, setSelectedState] = React.useState(0)
    const [selectedSCountry, setSelectedSCountry] = React.useState(0)
    const [paisSelecionado, setPaisSelecionado] = useState({})

    const [paisesSelecionados, setPaisesSelecionados] = useState([])
    const [paises, setPaises] = useState([])
    const [totalPaises, setTotalPaises] = useState(0)
    const [regioes, setRegioes] = useState([])

    const openCountry = (i) => {
        const newCRegioes = regioes.map((regiao, index) => {
            return {...regiao, open: !regiao.open}
        })
        setRegioes(newCRegioes)
    }

    const onCancel = () => {
        setDeletar(false)
    }
    const selecionarTodosOsEstados = (pais) => {
        let newArray = [...fakePaises];
        const selecionar = newArray[pais].states.filter((item) => item.selected).length === newArray[pais].states.length
        let estadosTodos = newArray[pais].states.map((item) => {
            item.selected = selecionar ? false : true;
            return item
        })
        newArray[pais].states = estadosTodos
        setFakePaises(newArray)
    }

    const selecionarTodosAsCidades = (pais) => {
        let newArray = [...fakePaises];
        const selecionar = newArray[pais].states[selectedState].cities.filter((item) => item.selected).length === newArray[pais].states[selectedState].cities.length
        let estadosTodos = newArray[pais].states[selectedState].cities.map((item) => {
            item.selected = selecionar ? false : true;
            return item
        })
        newArray[pais].states[selectedState].cities = estadosTodos
        setFakePaises(newArray)
    }
    const trocarUmEstado = (i, pais) => {
        if (i === "todos") {
            selecionarTodosOsEstados(pais);
            return
        }
        let newArray = [...fakePaises]
        newArray[pais].states[i].selected = !newArray[pais].states[i].selected
        setFakePaises(newArray)
    }
    const trocarCidades = (i, state, pais) => {
        if (i === "todos")
            return selecionarTodosAsCidades(pais)
        let newArray = [...fakePaises]
        newArray[pais].states[state].cities[i].selected = !newArray[pais].states[state].cities[i].selected
        setFakePaises(newArray)

    }
    const deletarItem = async () => {
        setLoading(true)
        const countryToSave = { countries: [ { id: parseInt(deletar), active: 0 } ] }
        await createServiceRegion(countryToSave)
            .then(async response => {
                await getAreaDeAtendimento().then(() => {
                    avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
                })
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
                setDeletar(null)
            })
    }

    const toggleModalPaises = () => {
        if (modalPaises === false && paises.length === 0) {
            getPaises()
        }
        setModalPaises(!modalPaises)
    }
    const salvarPais = async () => {
        setLoading(true)
        const countryToSave = { countries: [ { id: parseInt(paisSelecionado), active: 1 } ] }
        const countries = regioes.map(country => ({id: country.id, active: 1}))
        countryToSave.countries.push(...countries)

        await createServiceRegion(countryToSave)
            .then(async response => {
                await getAreaDeAtendimento()
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
        toggleModalPaises()
    }

    const selecionar = (e) => {
        setPaisSelecionado(e.target.value)
    }

    const getPaises = async (dataParam = null, paises = []) => {
        setLoading(true)

        if (dataParam === null) {
            return await getCountries().then(response => {
                const data = response.data.resposta.conteudo.countries                
                setTotalPaises(data.total)
                paises = [...paises, ...data.data]
                getPaises(data, paises)
            }) 
        }
        if (dataParam?.last_page !== dataParam?.current_page) {
            await getCountries(dataParam?.next_page_url || '').then(response => {
                const data = response.data.resposta.conteudo.countries

                paises = [...paises, ...data.data]
                getPaises(data, paises)
            })
        }

        setPaises(paises)
        if (dataParam?.last_page === dataParam?.current_page || paises.length < 5) {
            setLoading(false)
        }
    }

    const getAreaDeAtendimento = async () => {
        setLoading(true)
        await getServiceRegion()
            .then(async response => {
                const dataArray = response.data.resposta.conteudo.regions.countries
                if (!dataArray) {
                    avisar("Nenhuma Aréa cadastrada", "Cadastre uma nova Região de atuação!", "warning")
                    setRegioes([])
                    return
                }

                const newCountries = dataArray.map(pais => {
                    return {...pais, open: false}
                })
                
                setRegioes(newCountries)
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        // getPaises()
        getAreaDeAtendimento()
    }, []);

    return (
        <>
            {(deletar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim, deletar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Você tem certeza?"
                    onConfirm={deletarItem}
                    onCancel={onCancel}
                >
                    Após essa ação Você não conseguira recuperar os dados.
      </SweetAlert>
            )}
            <Loader loading={loading} />

            <Modal isOpen={modalPaises} toggle={toggleModalPaises} centered>
                <ModalHeader toggle={toggleModalPaises}>Paises:</ModalHeader>
                <ModalBody >                    
                    {
                        paises.length > 0 && (
                            <>
                                <select
                                    className="form-control form-control-lg"
                                    onChange={selecionar}
                                >
                                    <option value="">Selecione um pais...</option>
                                    {
                                        paises.map((item, i) => {
                                            return (
                                                <option key={i} value={item.id}>{item.namePortuguese}</option>
                                            )
                                        })
                                    }
                                    {
                                        paises.length !== totalPaises && (
                                            <option value="">Carregando países...</option>
                                        )
                                    }
                                </select>
                            </>
                        )
                    }

                    <button type="button" className="mt-3 btn btn-secondary" onClick={salvarPais}>{loading ? 'Carregando' : 'Salvar' }</button>

                </ModalBody>
            </Modal>

            <TitlePage title="Região de atuação" desc="Se necessario digite sua região de atendimento." icon="fa fa-globe-americas" />
            <div className="row">
                <div className="col-md-12">
                    <div className="result-container">
                        <InputGroup size="lg" className="m-b-20">
                            <Input
                                placeholder="Procurar por pais..."
                                type="text"
                                className="input-white"
                            />
                            <InputGroupButtonDropdown
                                addonType="append"
                            >

                                <Button color="success" onClick={toggleModalPaises}>
                                    <i className="fa fa-plus fa-fw"></i>
                                </Button>
                            </InputGroupButtonDropdown>
                        </InputGroup>

                        {
                            regioes.length > 0 ? regioes.map((pais, i) => {
                                // return <p>{pais.name}</p>
                                return <CountryControl
                                    key={pais.id}
                                    index={i}
                                    pais={pais}
                                    setSelectedState={setSelectedState}
                                    selecionarTodosOsEstados={selecionarTodosOsEstados}
                                    openCountry={openCountry}
                                    setDeletar={setDeletar}
                                    trocarCidades={trocarCidades}
                                    setModalCities={setModalCities}
                                    trocarUmEstado={trocarUmEstado}
                                    setLoading={setLoading}
                                    avisar={avisar}
                                    getAreaDeAtendimento={getAreaDeAtendimento}
                                />
                            }) : (                                
                                <h4 className="mt-3">Nenhuma Região de atuação cadastrada</h4>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
