import React, { useState, useEffect, useContext } from "react"
import Loader from "../../components/loader"
import Campos from "./cadastrar/novoServico"

import { PageSettings } from "../../config/page-settings"

import Breadcrumb from "../../components/breakcumbs"
import TitlePage from "../../components/pages/title"
import { getService } from "../../services/service"

// cadastrar categoria

const Copiar = () => {
    const [loading, setLoading] = useState(false);
    const contextType = useContext(PageSettings);
    const [modalProduto, toggleModal] = useState(false)

    const [name, setName] = useState("")
    const [servico, setServico] = useState(null)

    const getServico = async () => {

        const servicoId = window.location.pathname.split('/').pop()

        await getService(servicoId)
            .then(response => {
                setServico(response.data.resposta.conteudo.service)
            })
            .catch(error => {
                // if (error && error.response?.data) {
                //     const messages = error.response.data.resposta.conteudo.messages
                //     Object.keys(messages).forEach(key => {
                //         messages[key].forEach(message => {
                //             avisar("Ops!!", message, "danger")
                //         })
                //     })
                //     return
                // }

                // avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        const URLParams = new URLSearchParams(window.location.search)
        setName(URLParams.get('name'))

        getServico()
    }, []);

    const toggleModalProduto = () => {
        toggleModal(!modalProduto)
    }

    return (
        servico && (
            <>
                <Loader loading={loading} />
                <Breadcrumb list={[{ name: "Home", path: "/" }, { name: "servicos", path: "/catalogo/servicos" }]} />
                <TitlePage title={`Copiar Serviço: ${name}`} icon="fal fa-box" />
                <Campos
                    modalProduto={modalProduto}
                    toggleModal={toggleModalProduto}
                    setLoading={setLoading}
                    servico={servico}
    
                    setLoading={setLoading}
                />
            </>
        )
    );
};

export default Copiar;
