import  {combineReducers}   from "redux"
import auth                 from "./auth/reducer"
import signUp               from "./singUp/reducer"
import search from "./pesquisa/reducer"
const rootReducer = combineReducers({
    auth,
    signUp,
    search
})

export default rootReducer