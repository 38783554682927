import React, { useState, useEffect, useContext } from "react";
import { store } from 'react-notifications-component';
import { Button,Row,Col,TabContent, TabPane, Nav, NavItem, NavLink, Alert } from "reactstrap";
import classnames from "classnames";

import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import BasicSingUp from "./parts/cadastroInfo"
import { PageSettings } from "../../config/page-settings"
import {logOut} from "../../store/auth/action"
import Loader from "../../components/loader"
import TitlePage from "../../components/pages/title"
import PlanoDeFundo from "./parts/planoDeFundo"
import ModalCodigo from "./modal"
import {useSelector} from "react-redux"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { saveUser, getUser, sendTokenToChangeEmail, changeEmail, sendTokenToDelete } from "../../services/auth/user.js";
import ModalDeletar from "./parts/modalDeletar"
const Configuracaoes = (props) => {
    const userState = useSelector(state => state.auth.user)
    const profile = userState.companies[0].profile
    const contextType = useContext(PageSettings);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modalCodigo, setModalCodigo] = useState(false)
    const [activeTab, setActiveTab] = useState("1");

    const [deleteModal, setDeleteModal] = useState(false)
    const [loadingDeletar, setLoadingDeletar] = useState(false)
    const toggleModalDelete = async()=> {

        setDeleteModal(!deleteModal)
    }

    const abrirModalSendToken =  async()=>{
        toggleModalDelete()
        setLoadingDeletar(true)
        const a = await sendTokenToDelete()
        setLoadingDeletar(false)
    }
    
    const [user, setUser] = useState(userState)    
    const [disabled, setDisabled] = useState(true)
    const [oldEmail, setOldEmail] = useState(userState.email)
    const [newEmail, setNewEmail] = useState(userState.email)
    const [codigo, setCodigo] = useState(0)
 
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const openModal = () => {
        enviarToken()
    }

    const salvarDados = async () => {
        setBtnLoading(true)
        setModalCodigo(false)
        
        if (oldEmail === newEmail) {
            const {name, cpf, cellphone} = user
            await saveUser(user.id, {name, cpf, cellphone})
                .then(response => {
                    getUsuario()
                    store.addNotification({
                        title: "Seu perfil atualizados!",
                        message: "Perfil alterado com sucesso !!!",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 10000,
                            onScreen: true
                        }
                    })
                })
                .catch(error => console.log(error))
                .finally(() => {
                    setBtnLoading(false)
                    setLoading(false)
                })
        } else {
            await changeEmail({email: newEmail, token: codigo})
                .then(response => {
                    store.addNotification({
                        title: "Email alterado!",
                        message: `Email do usuário alterado para ${newEmail}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 10000,
                            onScreen: true
                        }
                    })
                    getUsuario()
                })
                .catch(error => console.log(error))
                .finally(() => {
                    setBtnLoading(false)
                    setLoading(false)
                })
        }
    }

    const getUsuario = async () => {
        await getUser(userState.id)
            .then(response => {
                setUser(response.data.resposta.conteudo.user)
                setOldEmail(response.data.resposta.conteudo.user.email)
                setNewEmail(response.data.resposta.conteudo.user.email)
            })
            .catch(error => console.log(error))
            .finally(() => {
                setBtnLoading(false)
                setLoading(false)
            })
    }

    const enviarToken = async () => {        
        setBtnLoading(true)

        await sendTokenToChangeEmail({ email: oldEmail })
            .then(response => {
                console.log('response modal', response);
                const resposta = response.data.resposta.conteudo
                if (resposta.message.error) {                    
                    store.addNotification({
                        title: "Algo deu Errado!",
                        message: resposta.message.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 10000,
                            onScreen: true
                        }
                    })
                    return
                }
                
                setModalCodigo(true)
                store.addNotification({
                    title: "Código enviado!",
                    message: `Enviamos o Código de verificação para o ${oldEmail}`,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                })
            })
            .catch(error => console.log(error))
            .finally(() => {
                setBtnLoading(false)
            })
    }

    //Configuracaoes
    useEffect(() => {
        getUsuario()

        contextType.closeMobileSiderBar()
    }, [])

    const f_deletar_conta = () =>{
        console.log("deletar Conta")
    }

    return (
        <>
            <Loader loading={loading} />
            <TitlePage title="Configurações" desc="Informações e configurações disponiveis para a sua conta." icon="fal fa-cog" />
            <ModalCodigo
                modal={modalCodigo}
                toggleModal={setModalCodigo}
                salvarDados={salvarDados}
                user={user}
                setBtnLoading={setBtnLoading}
                setCodigo={setCodigo}
                codigo={codigo}
            />
            <ModalDeletar
                modal={deleteModal}
                toggle={toggleModalDelete}
                func={f_deletar_conta}
                loading={loadingDeletar}
            />
            <Row>
                <Col sm={12}>
                    <div className="bg-white">
                        <div className="col-12 pt-3 pb-3">
                            <Nav tabs className="nav-pills">
                                <NavItem className="m-3">
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        Dados da conta
                                    </NavLink>
                                </NavItem>
                                <NavItem className="m-3">
                                    <NavLink
                                        className={classnames({ active: activeTab === "2" })}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Planos de Fundo
                                    </NavLink>
                                </NavItem>
                                <NavItem className="m-3">
                                    <NavLink
                                        className={classnames({ active: activeTab === "3" })}
                                        onClick={() => {
                                            toggle("3");
                                        }}
                                    >
                                        Deletar conta
                                    </NavLink>
                                </NavItem>

                            </Nav>
                        </div>
                    </div>

                    <TabContent activeTab={activeTab} style={{ marginBottom: 30 }}>
                        <TabPane tabId="1">
                            <Panel theme="default">
                                <PanelBody>
                                    <BasicSingUp
                                        user={user}
                                        setUser={setUser}
                                        disabled={disabled}
                                        setDisabled={setDisabled}
                                        openModal={openModal}
                                        setNewEmail={setNewEmail}
                                        email={disabled ? oldEmail : newEmail}
                                    />
                                    <Button
                                        disabled={btnLoading}
                                        className="btn-rounded btn-lg bg-secondary"
                                        onClick={oldEmail !== newEmail ? openModal : salvarDados}
                                    >
                                        {btnLoading ? <i className="fas fa-spinner fa-spin text-inverse mr-1"></i> : null}
                                        Salvar
                                    </Button>
                                </PanelBody>
                            </Panel>
                        </TabPane>
                        <TabPane tabId="2">
                            <Panel theme="default">
                                <PanelBody>
                                    <PlanoDeFundo
                                        data={userState}
                                        profile={profile}
                                        setLoading={setLoading}
                                    />
                                </PanelBody>
                            </Panel>
                        </TabPane>
                        <TabPane tabId="3">
                            <Panel theme="danger">
                                <PanelBody>
                                        <Alert color="danger">
                                            <h1>Cuidado!</h1>
                                            <p>Essa operação irá excluir sua conta, caso deseje poderá reativa-la no futuro</p>
                                            <Button color="danger" onClick={abrirModalSendToken}>DELETAR CONTA</Button>
                                        </Alert>
                                </PanelBody>
                            </Panel>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );
};

export default Configuracaoes;
