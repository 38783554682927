import { API, URL_TO_IMAGES } from "../../config/api.config";
import api from "../api";

api.defaults.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`

const baseUrlBackground = `${API}/auth/background`

export const getBackgrounds = (idCompany) => api.get(`${baseUrlBackground}/${idCompany}`)
    .then(response => {
        response.data.resposta.conteudo.backgrounds.forEach(objBack => {
            const imgUrl = URL_TO_IMAGES + '/' + objBack.path.replace('public/', '')

            switch (objBack.type) {
                case 'perfil_empresa':
                    localStorage.setItem('background-perfil-empresa', imgUrl)
                    break;
                case 'produtos':
                    localStorage.setItem('background-produto', imgUrl)
                    break;
                case 'servicos':
                    localStorage.setItem('background-servico', imgUrl)
                    break;
                case 'sidebar':
                    localStorage.setItem('background-sidebar', imgUrl)
                    break;
                default:
                    break;
            }
        })
        return response
    })
/**
 * 
 * @param {*} idCompany 
 * @param {*} values = {company_id, type, name}
 * @returns 
 */
export const updateBackground = (idCompany, values) => api.put(`${baseUrlBackground}/${idCompany}`, values)
