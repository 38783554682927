import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Row, Input } from "reactstrap";

function CadastroInfo({user, setUser, disabled, setDisabled, setNewEmail, email}) {
  return (
    <form>
      <Row>
        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">Nome completo</label>
            <input
              placeholder="Nome Completo"
              type="text"
              className="form-control"
              value={user.name}
              onChange={e => setUser({...user, name: e.target.value})}
              disabled={!disabled}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">CPF</label>
            <input
              placeholder="000.000.000-00"
              type="text"
              className="form-control"
              value={user.cpf}
              onChange={e => setUser({...user, cpf: e.target.value})}
              disabled={!disabled}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">e-mail</label>
            {/* <input
              placeholder="seu_email@provedor.com"
              type="text"
              className="form-control"
              value={user.email}
              onChange={e => setUser({...user, email: e.target.value})}
              disabled
            /> */}
            <div className="input-group">              
              <input
                placeholder="seu_email@provedor.com"
                type="text"
                className="form-control"
                value={email}
                onChange={e => setNewEmail(e.target.value)}
                disabled={disabled}
              />
              <div className="input-group-append">
                <button className={`btn btn-${disabled ? 'primary' : 'danger'}`} type="button" onClick={() => setDisabled(!disabled)}>{disabled ? 'Editar' : 'Cancelar'}</button>
              </div>
            </div>

          </div>
        </div>
      </Row>
    </form>
  );
}

export default CadastroInfo;
