import React from 'react';
import Home                 from "./../pages/home"
import TrocarSenha          from "./../pages/trocarSenha"
import CadastroEmpresa      from "./../pages/perfilEmpresa"
import Produto              from "./../pages/catalogoDeProdutos"
import CadastrarServico     from "./../pages/catalogoServicos/cadastrar"
import EditarServico        from "./../pages/catalogoServicos/editar"
import Config               from "./../pages/configuracao"
import ProcurarFornecedor   from "./../pages/procurarFornecedor"
import ProcuraAvancada      from "./../pages/procurarAvancada"

import ProcurarCliente      from "./../pages/procurarFornecedor/cliente"
import ProcurarProduto      from "./../pages/procurarFornecedor/produto"
import ProcurarServico      from "./../pages/procurarFornecedor/servico"
import ProdutoSingle        from "./../pages/produtoFinal"
import LogIn                from "./../pages/login/Index"
import CadastrarProduto     from "./../pages/catalogoDeProdutos/cadastrar"
import EditarProduto        from "./../pages/catalogoDeProdutos/editar"
import CopiarProduto        from "./../pages/catalogoDeProdutos/copiar"

import CopiarServico       from "./../pages/catalogoServicos/copiar"

import VisualizarEmpresa    from "./../pages/empresa"
import MinhaAvaliacao           from "./../pages/avaliacao/minhaAvaliacao"
import AvaliacaoCliente         from "./../pages/avaliacao/cliente"
import ConversaMinhaAvaliacao   from "./../pages/avaliacao/minhaAvaliacaoConversa"
import ConversaCliente          from "./../pages/avaliacao/AvaliacaoCliente"

import RegiaoDeAtendimento    from "../pages/regiaoDeAtendimento"

import MinhaCarteira  from "../pages/minhaCarteira"

import Cotar from "../pages/cotacao/cotar"
// minhas cotacoes
import CotacaoMinhaListaPendentes   from "../pages/cotacao/minhasCotacoes/pendente"
import CotacaoMinhaListaRespondida  from "../pages/cotacao/minhasCotacoes/respondida"
// visualizacao cotacao
import VisualizacaoCotarPendente    from "../pages/cotacao/minhasCotacoes/aguardandoResposta"
import VisualizacaoCotarResposta    from "../pages/cotacao/minhasCotacoes/cotarResposta"

// cotacoes clientes
import CotacaoClienteistaPendentes    from "../pages/cotacao/clientes/pendente"
import CotacaoClienteistaRespondida   from "../pages/cotacao/clientes/respondida"

import ResponderCotacao               from "../pages/cotacao/clientes/responder"
import ResponderCotacaoRespondida     from "../pages/cotacao/clientes/responderRespondida"
import Cadastrar                      from "../pages/cadastro"
import Assinatura                     from "../pages/assinatura"
import Redefinir from '../pages/login/redefinirSenha'
import Users from '../pages/admin/user';
import UserForm from '../pages/admin/user/form';
import Permissions from '../pages/admin/permission';
import PermissionsForm from '../pages/admin/permission/form';
import Roles from '../pages/admin/role';
import RolesForm from '../pages/admin/role/form';
import Fornecedores from '../pages/admin/supplier';
import CompanieForm from './../pages/admin/companie';
import Compradores from './../pages/admin/costumer';
import Cotacoes from './../pages/admin/quotation';
import CotacoesShow from './../pages/admin/quotation/show';
import Produtos from './../pages/admin/products';
import Servicos from './../pages/admin/services';
import Dashboard from './../pages/admin/dashboard';


const routes = [
  // Administração:
  {
    path: '/',
    title: 'Página Inicial',
    exact: true,
    component: () => <Dashboard />,
  },
  {
    path: '/usuarios',
    title: 'Usuários',
    exact: true,
    component: () => <Users />
  },
  {
    path: '/usuarios/novo/:id?',
    title: 'Usuário',
    exact: true,
    component: () => <UserForm />
  },
  {
    path: '/perfis',
    title: 'Perfis',
    exact: true,
    component: () => <Roles />
  },
  {
    path: '/perfis/novo/:id?',
    title: 'Perfis',
    exact: true,
    component: () => <RolesForm /> 
  },
  {
    path: '/fornecedores',
    title: 'fornecedores',
    exact: true,
    component: () => <Fornecedores />
  },
  {
    path: '/fornecedores/novo/:id?',
    title: 'fornecedores',
    exact: true,
    component: () => <CompanieForm />
  },
  {
    path: '/compradores',
    title: 'compradores',
    exact: true,
    component: () => <Compradores />
  },
  // {
  //   path: '/compradores/novo/:id?',
  //   title: 'compradores',
  //   exact: true,
  //   component: () => <CompradoresForm />
  // },
  {
    path: '/compradores/novo/:id?',
    title: 'compradores',
    exact: true,
    component: () => <CompanieForm />
  },
  {
    path: '/cotacoes',
    title: 'Cotações',
    exact: true,
    component: () => <Cotacoes />
  },
  {
    path: '/cotacoes/:id?',
    title: 'Cotação',
    exact: true,
    component: () => <CotacoesShow />
  },
  {
    path: '/permissoes',
    title: 'Permissões',
    exact: true,
    component: () => <Permissions />
  },
  {
    path: '/permissoes/novo/:id?',
    title: 'Permissões',
    exact: true,
    component: () => <PermissionsForm /> 
  },
  {
    path: '/produtos',
    title: 'Produtos',
    exact: true,
    component: () => <Produtos />
  },  
  { 
    path: "/produtos/:id",
    title: 'Produto',
    exact: true,
    component: () => <ProdutoSingle />,
  },
  {
    path: "/produtos/editar/:id",
    title: 'Editar Produto',
    exact: true,
    component: () => <EditarProduto />,
  },
  {
    path: '/servicos',
    title: 'Serviços',
    exact: true,
    component: () => <Servicos />
  },
  { 
    path: "/servicos/:id",
    title: 'Produto',
    exact: true,
    component: () => <ProdutoSingle type="servico" />,
  },
  {
    path: "/servicos/editar/:id",
    title: 'Editar Serviço',
    exact: true,
    component: () => <EditarServico />,
  },
  // FIM DO ADMIN

  // {
  //   path: '/',
  //   title: 'Página Inicial',
  //   exact: true,
  //   component: () => <Home />,
  // },
  {
    path: '/trocar-senha',
    title: 'Trocar senha',
    exact: true,
    component: () => <TrocarSenha />,
  },
  {
    path: '/cadastroEmpresa',
    title: 'Página Inicial',
    exact: true,
    component: () => <CadastroEmpresa />,
  },
  {
    path: '/configuracoes',
    title: 'Configuraçoes',
    exact: true,
    component: () => <Config />,
  },

  {
    path: "/procurar/fornecedor",
    title: 'Procurar Fornecedor',
    exact: true,
    component: () => <ProcurarFornecedor />,
  },
  {
    path: "/procurar/cliente",
    title: 'Procurar cliente',
    exact: true,
    component: () => <ProcurarCliente />,
  },
  {
    path: "/procurar/produto",
    title: 'Procurar produto',
    exact: true,
    component: () => <ProcurarProduto />,
  },
  {
    path: "/procurar/servico",
    title: 'Procurar serviço',
    exact: true,
    component: () => <ProcurarServico />,
  },
  {
    path: "/procurar/avancada",
    title: 'Procurar Avançada',
    exact: true,
    component: () => <ProcuraAvancada />,
  },

  {
    path: "/servico/:id",
    title: 'Serviço',
    exact: true,
    component: () => <ProdutoSingle type="servico" />,
  },
  {
    path: "/fornecedor/:id?",
    title: 'Fornecedor ',
    exact: true,
    component: () => <VisualizarEmpresa />,
  },

  {
    path: "/catalogo/produtos",
    title: 'Produtos',
    exact: true,
    component: () => <Produto />,
  },

  {
    path: "/cadastrar/produto",
    title: 'Cadastrar Produto',
    exact: true,
    component: () => <CadastrarProduto />,
  },

  {
    path: "/cadastrar/produto/:id",
    title: 'Copiar Produto',
    exact: true,
    component: () => <CopiarProduto />,
  },

  {
    path: "/editar/produto/:id",
    title: 'Editar Produto',
    exact: true,
    component: () => <EditarProduto />,
  },

  {
    path: "/catalogo/servicos",
    title: 'Serviços',
    exact: true,
    component: () => <Servicos />,
  },

  {
    path: "/cotacao/cotar/:idCompany",
    title: 'Cotar',
    exact: true,
    component: () => <Cotar />,
  },

  {
    path: "/cotacao/clientes/pendentes",
    title: 'Cotação - Aguardando',
    exact: true,
    component: () => <CotacaoClienteistaPendentes />,
  },

  {
    path: "/cotacao/clientes/respondida",
    title: 'Cotação - Respondida',
    exact: true,
    component: () => <CotacaoClienteistaRespondida />,
  },

  {
    path: "/cotacao/minhascontacao/pendentes",
    title: 'Cotação - Aguardando',
    exact: true,
    component: () => <CotacaoMinhaListaPendentes />,
  },

  {
    path: "/cotacao/minhascontacao/respondida",
    title: 'Cotação - Respondida',
    exact: true,
    component: () => <CotacaoMinhaListaRespondida />,
  },

  {
    path: "/cotacao/minhascontacao/visualizar-cotacao/:id",
    title: 'Cotação',
    exact: true,
    component: () => <VisualizacaoCotarPendente />,
  },

  {
    path: "/cotacao/minhascontacao/visualizar-respondida",
    title: 'Cotação',
    exact: true,
    component: () => <VisualizacaoCotarResposta  />,
  },

  {
    path: "/cotacao/cliente/responder/:id",
    title: 'Responder - cotação',
    exact: true,
    component: () => <ResponderCotacao />,
  },

  {
    path: "/cotacao/cliente/respondida/:id",
    title: 'Responder - cotação',
    exact: true,
    component: () => <ResponderCotacaoRespondida />,
  },

  {
    path: "/cadastrar/servico",
    title: 'Cadastrar novo serviço',
    exact: true,
    component: () => <CadastrarServico />,
  },
  {

    path: "/cadastrar/servico/:id",
    title: 'Copiar Serviço',
    exact: true,
    component: () => <CopiarServico />,
  },

  {
    path: "/avaliacao/minhas-avaliacoes",
    title: 'Minhas avaliações',
    exact: true,
    component: () => <MinhaAvaliacao tipo="respondidas" />,
  },

  {
    path: "/avaliacao/clientes",
    title: 'Avaliações Clientes',
    exact: true,
    component: () => <AvaliacaoCliente tipo="nao-respondidas" />,
  },
  {
    path: "/avaliacao/minhas-avaliacoes/:id",
    title: 'Sua conversa com o cliente'
    ,
    exact: true,
    component: () => <ConversaMinhaAvaliacao />,
  },
  {
    path: "/avaliacao/clientes/:id",
    title: 'Sua conversa com o cliente',
    exact: true,
    component: () => <ConversaCliente />,
  },
  {
    path: "/editar/servico/:id",
    title: 'Editar Serviço',
    exact: true,
    component: () => <EditarServico />,
  },

  {
    path: "/regiao-de-atendimento",
    title: 'Região de Atendimento',
    exact: true,
    component: () => <RegiaoDeAtendimento />,
  },

  {
    path: "/minha-carteira",
    title: 'Minha carteira',
    exact: true,
    component: () => <MinhaCarteira />,
  },
  {
    path: "/assinatura",
    title: 'Sua assinatura',
    exact: true,
    component: () => <Assinatura />,
  }
];

const routesAuth = [
  {
    path: '/login',
    title: 'Logar',
    exact: true,
    component: () => <LogIn />,
  },
  {
    path: '/login/:valid',
    title: 'Logar',
    exact: true,
    component: () => <LogIn />,
  },
  {
    path: '/cadastro',
    title: 'Logar',
    exact: true,
    component: () => <Cadastrar />,
  },
  {
    path: '/forgot-password',
    title: 'Recuperar Senha',
    exact: true,
    component: () => <Redefinir />,
  },
]

export {routes, routesAuth};