import React, { useState, useEffect, useContext } from "react";

import Loader from "../../components/loader"
import { PageSettings } from "../../config/page-settings"
import Comentarios from "../../components/comentarios/comentarioAvaliacao"

import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import TitlePage from "../../components/pages/title"
import { Row, Col, TabContent, TabPane } from "reactstrap"
import { useHistory } from "react-router-dom";
import { createEvaluations, getMyEvaluations, updateEvaluations } from "../../services/evaluations";
import Pagination from "../../components/paginate/pagination";
import { store } from "react-notifications-component";
import { useSelector } from "react-redux";

const avisar = (title, msg, type) => {
  store.addNotification({
      title: title || "Usuário ou senha incorretos",
      message: msg || "Um código de verificação foi enviado para o seu email!",
      type: type || "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
          duration: 5000,
          onScreen: true
      }
  }); 
}

const Avaliacoes = (props) => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const [activeTab, setActiveTab] = useState("1");

  const [avaliacoes, setAvaliacoes] = useState({})
  
  const {user} = useSelector(state => state.auth)

  let history = useHistory();

  const pegaAvaliacoes = async (page = 1) => {
    setLoading(true)
    await getMyEvaluations({page})
      .then(response => {
        setAvaliacoes(response?.data?.resposta?.conteudo?.evaluations || {})
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()

    pegaAvaliacoes()

    const tabActive = props.tipo === 'nao-respondidas' ? "1" : props.tipo === 'respondidas' ? "2" : "3"
    setActiveTab(tabActive)

    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      contextType.handleSetPageContentFullHeight(false);
      contextType.handleSetPageContentFullWidth(false);
    }
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModalResponder = (id) => {
    history.push(`/avaliacao/minhas-avaliacoes/${id}`)

  }

  const updateComent = async ({ id, title, comment, stars}, callback) => {
    const input = {
      title: title,
      description: comment,
      status: "ACTIVE",
      stars: String(stars)
    }
    if (!comment) return avisar("Texto do comentário obrigatório.", "Texto não pode ser vazio", "danger")

    await updateEvaluations(id, input)
      .then(response => {
        avisar("Comentário editado", "Comentário editado com sucesso.", "success")
        if (callback) callback()
        pegaAvaliacoes()
      })
      .catch(err => {
        if (err && err.response && err.response.data?.resposta?.conteudo?.message) {
          avisar("Algo deu errado!", err.response.data.resposta.conteudo.message, "danger")
        }
      })
  }

  return (
    <>
      <Loader loading={loading} />
      <TitlePage icon="far fa-comments" title="Minhas avaliações" desc="Gerencie seus comentarios" />

      <Row>
        <Col sm={12}>
          <Panel theme="default">
            <PanelHeader>
              Todas as suas avaliaçōes
            </PanelHeader>  
            <PanelBody>
              {/* <div className="form-group m-r-10 float-right d-none d-sm-block " >
                <select className="form-control input-sm">
                  <option>Mais recentes</option>
                  <option>Mais antigas</option>
                </select>
              </div> */}

              <ul className="media-list media-list-with-divider mt-5">
                {
                  Object.keys(avaliacoes).length > 0 && avaliacoes.data.map(avaliacao => {
                    return <Comentarios
                      key={avaliacao.id}
                      minhas={true}
                      updateComent={updateComent}
                      item={avaliacao}
                      func={() => toggleModalResponder(false)}
                      edit
                      getItems={pegaAvaliacoes}
                      avisar={avisar}
                    />
                  })
                }
              </ul>

              <div className="clearfix m-t-20">
                <Pagination
                  firstPageUrl={avaliacoes.first_page_url}
                  lastPageUrl={avaliacoes.last_page_url}
                  nextPageUrl={avaliacoes.next_page_url}
                  previousPageUrl={avaliacoes.prev_page_url}

                  perPage={avaliacoes.per_page}
                  lastPage={avaliacoes.last_page}
                  currentPage={avaliacoes.current_page}
                  currentUrl={avaliacoes.currentUrlProducts}
                  path={avaliacoes.path}

                  {...avaliacoes}

                  getItems={pegaAvaliacoes}
                />
              </div>

            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>

  );
};

export default Avaliacoes;
