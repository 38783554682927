import React from "react";
import { Col, Row } from "reactstrap";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

function ContainerToForm({ title, children }) {

  return (
    <Row>
        <Col sm={12}>
            <Panel theme="default">
                <PanelHeader>
                    { title }
                </PanelHeader>
                <PanelBody>
                    { children }
                </PanelBody>
            </Panel>
        </Col>
    </Row>
  );
}

export default ContainerToForm
