import React from "react"
import { Row } from "reactstrap"
import { Editor } from 'react-draft-wysiwyg';

const BasicInfo = (props) => {
    return (
        <Row>
            <div className="col-md-12 mt-2">
                <div className="form-group">
                    <label className="opacity-5 mb-1">Nome</label>
                    <input
                        placeholder="Nome do serviço"
                        type="text"
                        value={props.nome}
                        onChange={(e) => props.setNome(e.target.value)}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="opacity-5 mb-1">Código do Serviço</label>
                    <input
                        placeholder="código"
                        type="text"
                        className="form-control"
                        value={props.codigo}
                        onChange={(e) => props.setCodigo(e.target.value)}
                    />
                </div>
            </div>

            <div className="col-md-6">
                <div className="form-group">
                    <label className="opacity-5 mb-1">Categoria</label>
                    <input
                        placeholder="Categoria"
                        type="text"
                        className="form-control"
                        value={props.categoria}
                        onChange={(e) => {
                            props.searchCategoria(e.target.value)
                            props.setCategoria(e.target.value)
                        }}
                        list="categorias"
                    />
                    <datalist id="categorias">
                        {
                            props.categorias.map((categoria, i) => {
                                return (
                                    <option key={categoria.id} value={categoria.name}>{categoria.name}</option>
                                )
                            })
                        }
                    </datalist>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group">
                    <label className="opacity-5 mb-1">Descrição</label>
                    <Editor
                        editorState={props.descricao}
                        editorClassName="form-control min-height-editor"
                        toolbar={{ options: ["inline", "blockType"] }}
                        onEditorStateChange={(e) => props.setDescricao(e)}
                    />
                </div>
            </div>
            <Row>

            </Row>
            <button type="button" className="mt-3 btn btn-secondary" onClick={props.func_acao}>Proximo</button>
        </Row>
    )
}
export default BasicInfo