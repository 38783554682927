import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../../config/api.config";

const btns = (item) => {
  return (
    <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
      {
        item.servico.active === 1 && (
          <Link
            to={`/servicos/editar/${item.servico?.id}?name=${item.servico?.name}&desc=${item.servico?.description}`}
            className="btn btn-success btn-icon btn-circle btn-lg m-3"
            title="Editar"
          >
            <i className="fa fa-edit"></i>
          </Link>
        )
      }
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setBloquear(item.servico);
        }}
        className="btn btn-warning btn-icon btn-circle btn-lg m-3"
        title="Bloquear"
      >
        <i className="fa fa-ban"></i>
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setDeletar(item.servico);
        }}
        className="btn btn-danger btn-icon btn-circle btn-lg m-3"
        title="Deletar"
      >
        <i className="fa fa-times"></i>
      </Button>
    </div>
  );
};

const ServicoItem = (props) => {
  const { servico } = props;

  const image = servico.images[0]?.path
    ? `${URL_TO_IMAGES}/${servico.images
        .find((img) => img.featured_image === 1)
        ?.path.replace("public", "")}`
    : "/assets/img/default-product.svg";

  return (
    <li style={{
      filter: servico.active === 1 ? 'none' : 'grayscale(1)'
    }}>
      <div
        to={`/servicos/${servico.id}`}
        className="result-image"
        style={{
          backgroundImage: `url( ${image} )`,
          backgroundSize: "contain",
          backgroundColor: "white",
        }}
      >
        <div className="p-3 d-sm-none" style={{ zIndex: 4 }}>
          {props.isUser ? btns(props) : null}
        </div>
      </div>
      <div className="result-info">
        <h4 className="title">
          <Link to={`/servicos/${servico.id}`}>{servico.name}</Link>
        </h4>
        <span className="badge badge-secondary">{servico.category.name}</span>

        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: servico.description }}
        ></div>

        <div className="btn-row">
          {servico.avg && (
            <Link
              to={`/servicos/${servico.id}`}
              data-toggle="tooltip"
              data-container="body"
              data-title="Analytics"
            >
              <>
                <i className="far fa-star"></i> {servico.avg}
              </>
            </Link>
          )}
          <Link
            to={`/servicos/${servico.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            <i className="far fa-eye"></i> {servico?.hit || 0}
          </Link>
        </div>
      </div>
      <div className="result-price" style={{ zIndex: 4 }}>
        <div className="d-none d-sm-block align-self-center align-self-sm-end">
          {btns(props)}
        </div>
        {
          servico.active === 1 && (
            <Link
              to={`/servicos/${servico.id}`}
              className="btn btn-yellow btn-block"
            >
              {props.servico ? "Ver serviço" : "Ver servico"}
            </Link>
          )
        }
      </div>
    </li>
  );
};

export default ServicoItem;
