import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';

const ModalConfig = (props) => {
  const {
    modal,
    salvarDados,
    toggleModal,
    setCodigo,
    codigo
  } = props;

  const [btn, setBtn] = useState(true)

  const closeModal = () => {
    toggleModal(false)
  }

  useEffect(() => {
    if (codigo > 5)
      setBtn(false)
    else
      setBtn(true)
  }, [codigo])

  return (
    <>
      <Modal fade={false} isOpen={modal} toggle={closeModal} size="sm" centered>
        <ModalHeader toggle={closeModal}>Código de verificação.</ModalHeader>
        <ModalBody>

          <Row>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1"> Digite o código que foi enviado para o seu email ou telefone</label>
                <input
                  placeholder="#####"
                  type="text"
                  onChange={(e) => setCodigo(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" disabled={btn} onClick={salvarDados}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalConfig;