import React,  {useState} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {logOut} from "../../../store/auth/action"
import { URL_TO_IMAGES } from '../../../config/api.config';
 
const DropdownProfile = (props)=> {

	const [dropdownOpen, setdropdownOpen] = useState(false)
	const dispatch = useDispatch();
	const {user, current_companie} = useSelector(state => state.auth)

	const toggle = () => {
		setdropdownOpen(!dropdownOpen)
	}

	const logOutHeader = ()=>{
		dispatch(logOut(props.history));
	}

	if(!user) return null
	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li">
			<DropdownToggle tag="a">
				<img src={current_companie?.company_logo ? URL_TO_IMAGES+'/'+current_companie?.company_logo.replace('public', '') : "/assets/img/user/user-13.jpg"} alt="" />
		
				<span className="d-none d-md-inline">{user.name || user.email}</span> <b className="caret"></b>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
				<Link to="/configuracoes"><DropdownItem>Configuração</DropdownItem></Link>
				<div className="dropdown-divider"></div>
				<DropdownItem onClick={logOutHeader}>Sair</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
	
};

export default withRouter(DropdownProfile);
