import React, {useEffect, useState, memo} from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { API, URL_TO_IMAGES } from "../../config/api.config";
import {getProductVisits} from  "../../services/product"
const btns = item => {
    return (
        <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
            <Link to={`/editar/produto/${item.produto?.id}?name=${item.produto?.name}&desc=${item.produto?.description}`} className="btn btn-success btn-icon btn-circle btn-lg m-3">
                <i className="fa fa-edit"></i>
            </Link>
            <Link to={`/cadastrar/produto/${item.produto?.id}?name=${item.produto?.name}&desc=${item.produto?.description}`} className="btn btn-warning btn-icon btn-circle btn-lg m-3">
                <i className="fa fa-clone"></i>
            </Link>
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    item.setDeletar(item.produto)
                }}
                className="btn btn-danger btn-icon btn-circle btn-lg m-3"
            >
                <i className="fa fa-times"></i>
            </Button>
        </div>
    )
}

const Produto = (props) => {
  const {produto} = props
  const [loadingVisits, setLoadingVisits] = useState(false)

  const image = produto.images[0]?.path ? `${URL_TO_IMAGES}/${produto.images.find(img => img.featured_image === 1)?.path.replace('public', '')}` : '/assets/img/default-product.svg'
  const [visits, setVisits] = useState(0)

  const getInfo = async(id)=>{
    if(!id) return null
    setLoadingVisits(true)
    const number = await getProductVisits(id)
    console.log(id, number)
    setLoadingVisits(false)

    setVisits(number)
  }
  useEffect(()=>{
    // getInfo(produto.id)
    
  }, [produto.id])
  return (
    <li>
    <div
      to={`/produto/${produto.id}`}
      className="result-image"
      style={{
        backgroundImage: `url( ${image} )`, 
        backgroundSize: "contain",
        backgroundColor: "white"
      }}
      
    >
    
    <div className="p-3 d-sm-none" style={{zIndex:4}}>
      { props.isUser ? btns(props) : null}
    </div>
    </div>
    <div className="result-info">
      <h4 className="title">
        <Link to={`/produto/${produto.id}`}>
          {produto.name}
        </Link>
      </h4> 
      <span className="badge badge-secondary">{produto.category.name}</span>

      <div className="desc" dangerouslySetInnerHTML={{__html: produto.description}}></div>

        <div className="btn-row">
          {
            produto.avg && (
              <Link
                to={`/produto/${produto.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Analytics"
              >
                <>
                  <i className="far fa-star"></i> {produto.avg}
                </>
              </Link>
            )
          }
          <Link
            to={`/produto/${produto.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            {/* <i className="far fa-eye"></i> {loadingVisits ? <i className="fas fa-spinner fa-spin"></i>: visits || 0}  */}
            <i className="far fa-eye"></i> {produto?.hit || 0}
          </Link>

      </div>
    </div>
    <div className="result-price" style={{zIndex:4}}>
      <div className="d-none d-sm-block align-self-center align-self-sm-end"> 
        { props.isUser ? btns(props) : null}
      </div>

      <Link to={`/produto/${produto.id}`} className="btn btn-yellow btn-block">
        {props.servico ? "Ver serviço" : "Ver produto"}
      </Link>

      <Link
        to={`/cotacao/cotar/${parseInt(produto.company_id)}?produto=${produto.id}`}
        className="btn btn-outline-primary btn-block">
          <i className="fa fa-plus mr-2"></i>
          Adicionar a Cotação
      </Link>
    </div>
  </li>
  );
};

export default memo(Produto);
