import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import TableDefault from "../../components/table/default";
import TitlePage from "../../components/pages/title";
import { Button } from "reactstrap";

export default function PageDefault({
  title,
  titlePlural,
  type,
  dataRequest,
  baseUrl,
  columnsTable,
  params,
  withoutNew,
  tiposDeBusca = [
    { key: "name", label: "Nome" },
    { key: "code", label: "Código" },
    { key: "cnpj", label: "CNPJ" },
  ],
  hasDelete = true,
}) {
  const history = useHistory();

  const [paginate, setPaginate] = useState({
    page: 1,
    per_page: 30,
  });

  const handleEdit = (id) => history.push(`/${baseUrl}/novo/${id}`);

  return (
    <>
      <ToastContainer />

      <TitlePage
        title={titlePlural}
        desc={`Listagem de ${titlePlural}.`}
        icon="fas fa-data"
      />

      {!withoutNew && (
        <div className="d-flex justify-content-end mb-3">
          <Button
            className="btn-rounded btn-lg bg-secondary"
            type="button"
            onClick={() => history.push(`/${baseUrl}/novo`)}
          >
            Novo(a) {title}
          </Button>
        </div>
      )}
      
      {
        tiposDeBusca && (
          <TableDefault
            columns={columnsTable}
            editCallback={handleEdit}
            paginate={paginate}
            setPaginate={setPaginate}
            dataRequest={dataRequest}
            type={type}
            defaultParams={params}
            tiposDeBusca={tiposDeBusca}
            hasDelete={hasDelete}
          />
        )
      }
    </>
  );
}
