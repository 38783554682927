import { API } from "../../config/api.config";
import api from "../api";

api.defaults.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`

const baseUrlCompanie = `${API}/auth/dashboard`

export const getProductsQtd = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/qtd-products`)
                .then(result => resolve(result.data.resposta.conteudo.qtdProducts))
                .catch( err => reject(err) )
    } )
}
export const getquotationsQtd = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/qtd-quotations`)
                .then(result => resolve(result.data.resposta.conteudo.qtdQuotations))
                .catch( err => reject(err) )
    } )
}

export const getVisitesQtd = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/number-visits`)
                .then(result => resolve(result.data.resposta.conteudo.numberVisits))
                .catch( err => reject(err) )
    } )
}

export const getServicesQtd = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/qtd-services`)
                .then(result => resolve(result.data.resposta.conteudo.qtdServices))
                .catch( err => reject(err) )
    } )
}

export const getTotalQuoted = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/total-quoted`)
                .then(result => resolve(result.data.resposta.conteudo.totalQuoted))
                .catch( err => reject(err) )
    } )
}

export const getTimeToResponse = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/response-time`)
                .then(result => resolve(result.data.resposta.conteudo.responseTime))
                .catch( err => reject(err) )
    } )
}

export const getInfoDashboad = () => {
    return new Promise( (resolve, reject) =>{
        api.get(`${baseUrlCompanie}/get-dashboard`)
                .then(result => resolve(result.data.resposta.conteudo.dashboard))
                .catch( err => reject(err) )
    } )
}