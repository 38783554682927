import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { formatDistance } from 'date-fns'

import { ptBR } from 'date-fns/locale'

import { URL_TO_IMAGES } from "../../config/api.config"
import { deleteEvaluations } from "../../services/evaluations"
import FormComentario from "./formComentario"

const notas = [1, 2, 3, 4, 5]
const Comentario = ({
    item,
    type,
    updateComent,
    edit,
    getItems,
    avisar,
    setLoading,
    loading
}) => {
    const { user } = useSelector(state => state.auth)
    const [profileImage, setProfileImage] = useState('/assets/img/user/user-13.jpg')
    const [showEdit, setShowEdit] = useState(false)
    const [stars, setStars] = useState(item.stars)
    const [title, setTitle] = useState(item.title)
    const [comment, setComment] = useState(item.description)

    const [deletar, setDeletar] = useState(null)

    const imageURL = url => url ? `${URL_TO_IMAGES}${url.replace('public', '')}` : ''

    const confirmar = async () => {
        setLoading(true)
        window.scrollTo(0, 0)
        await deleteEvaluations(deletar.id)
            .then(response => {
                getItems()
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
            })
            .catch(error => {
                if (error && error.response?.data) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setDeletar(null)
                setLoading(false)
            })
    }
    const deletarItem = () => {
        setDeletar(null)
    }

    useEffect(() => {
        if (item.user && item.user.companies.length > 0) {
            setProfileImage(imageURL(item.user.companies[0].company_logo))
        }
        
        if (showEdit) {
            setShowEdit(false)
        }
    }, [item])

    return (
        <li className={`${user.id === item.customer_id ? "bg-yellow-transparent-3 border-warning" : "bg-white mt-3"}`}>

            {(deletar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim, deletar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Você tem certeza?"
                    onConfirm={confirmar}
                    onCancel={deletarItem}
                >
                    Após essa ação Você não conseguira recuperar os dados.
                </SweetAlert>
            )}
            <div
                className={`media media-sm comentario`}
                style={{
                    background: user.id === item.customer_id ? "transparent" : "white"
                }}
            >
                <img
                    src={profileImage || '/assets/img/user/user-13.jpg'}
                    alt={`Logo da empresa`}
                    className="media-object rounded-corner mr-3"
                    style={{
                        width: '64px',
                        height: '64px'
                    }}
                />

                <div className="media-body">
                    <h4 className="media-heading">{item.title}</h4>
                    <p className="m-b-5">
                        {item.description}
                    </p>
                    <div className="rating mt-2 mb-1">
                        <p className="mr-2 text-orange">Nota: {item.stars}</p>
                        {item.stars ?
                            notas.map((stars) => {
                                return <span key={stars} className={`star ${stars <= item.stars ? "active" : null}`}></span>
                            })
                            : null
                        }

                    </div>
                    
                    {
                        item.created_at && (
                            <i className="label label-danger">
                                {
                                    formatDistance(
                                        new Date(item.created_at),
                                        new Date(),
                                        {
                                            addSuffix: true,
                                            locale: ptBR
                                        },
                                    )
                                }
                            </i>
                        )
                    }
                    <br/>
                    
                    {
                        item.user && item.user.companies.length > 0 && (
                            <i className="text-muted">Avaliado por: <Link to={`/fornecedor/${item.user.companies[0].id}`}> {item.user.companies[0].company_name} </Link></i>
                        )
                    }
                    {
                        edit && (
                            <div
                                className="edit"
                                style={{
                                    textAlign: 'end'
                                }}
                            >
                                <a
                                    className="btn btn-success btn-icon btn-circle btn-lg m-3"
                                    onClick={() => {
                                        setShowEdit(oldValue => !oldValue)}
                                    }
                                >
                                    <i className="fa fa-edit"></i>
                                </a>
                                <Button
                                    onClick={(e) => {
                                        setDeletar(item)
                                        window.scrollTo(0, 0)
                                    }}
                                    className="btn btn-danger btn-icon btn-circle btn-lg m-3"
                                >
                                    <i className="fa fa-times"></i>
                                </Button>
                            </div>
                        )
                    }
                </div>
            </div>
            <div
                style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingBottom: '1px'
                }}
            >
                {
                    edit && showEdit && (
                        <FormComentario
                            stars={stars}
                            setStars={setStars}
                            title={title}
                            setTitle={setTitle}
                            comment={comment}
                            setComment={setComment}
                            type={type}
                            id={item.id}
                            updateComent={updateComent}
                            titleSendButton='Editar'
                        />
                    )
                }
            </div>
        </li>
    )
}

export default Comentario
