import React, {memo} from "react"
import ItemProduto from "../../components/produto"
import SkeletonItem from "../../components/skeleton/skeleton-item"; 
const ListarProduto = ({listaProdutos, setDeletar})=>{
    
    if(!listaProdutos) return null
    return(
        <ul className="result-list">
        {
            listaProdutos ? (
                listaProdutos.map((produto => {
                    return <ItemProduto key={produto.id} isUser={true} produto={produto} setDeletar={setDeletar} />
                }))
            ) : (
                    <>
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                    </>
                )
        }
    </ul>
    )
}

export default memo(ListarProduto)