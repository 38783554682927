import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { PageSettings } from "../../../config/page-settings";
import TitlePage from "../../../components/pages/title";
import Result from "./result";

import { productsRequests } from "../../../services/admin/products";
import SweetAlert from "react-bootstrap-sweetalert";
import { store } from "react-notifications-component";
import SearchFor from "../../../components/busca/SearchFor";

const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

async function udpateItem(item, handleProducts, setLoading, setBloquear) {
  const statusMsg = !item.active ? 'ativado' : 'desativado';
  return await productsRequests
    .update(item.id, {...item, active: !item.active})
    .then(() => {
      handleProducts();
      avisar(
        `Produto ${statusMsg} com sucesso!`,
        `O produto agora está ${statusMsg}`,
        "success"
      );
    })
    .catch((error) => {
      if (error && error.response?.data?.resposta) {
        const messages = error.response.data?.resposta?.conteudo?.messages;
  
        if (error.response.data.resposta?.conteudo?.error && !messages) {
          avisar(
            "Ops!!",
            error.response.data.resposta?.conteudo?.error,
            "danger"
          );
        }
  
        if (messages) {
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              avisar("Ops!!", message, "danger");
            });
          });
        }
        return;
      }
  
      avisar("Ops!!", "Erro interno no servidor!", "danger");
    })
    .finally(() => {
      setLoading(false);
      setBloquear(null);
    });
}

async function deleteItem(item, handleProducts, setLoading, setDeletar) {
  await productsRequests
  .delete(item.id)
  .then((response) => {
    handleProducts();
    avisar("Sucesso!", response.data.resposta.conteudo.message, "success");
  })
  .catch((error) => {
    if (error && error.response?.data) {
      const messages = error.response.data.resposta.conteudo.messages;
      Object.keys(messages).forEach((key) => {
        messages[key].forEach((message) => {
          avisar("Ops!!", message, "danger");
        });
      });
      return;
    }
    avisar("Ops!!", "Erro interno no servidor!", "danger");
  })
  .finally(() => {
    setLoading(false);
    setDeletar(null);
  });
}

const params = { pesquisar: "produtos" };
export default function Products() {
  const [loading, setLoading] = useState(false);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const contextType = useContext(PageSettings);
  const dados = useSelector((state) => state.search);
  const [searchFor, setSearchFor] = useState("name");
  const [keyword, setKeyword] = useState("");
  const [deletar, setDeletar] = useState(null);
  const [bloquear, setBloquear] = useState(null);
  
  const [produtos, setProdutos] = useState();

  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };

  const setarPosition = () => {
    var doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    localStorage.setItem("top-serach", top);
  };

  const pegaResultados = async (resetSearch = false) => {
    if (resetSearch) {
      handleProducts({})
      return
    }
    handleProducts({[searchFor]: keyword})
  };

  const handleProducts = async (currentParams = {}) => {
    setLoading(true);
    await productsRequests
      .list({ ...currentParams })
      .then((response) => {
        setProdutos(response.data.resposta.conteudo.products);
      })
      .finally(() => setLoading(false));
  };

  const confirmar = async (type = 'deletar') => {
    setLoading(true);
    if (type === 'bloquear') {
      await udpateItem(bloquear, handleProducts, setLoading, setBloquear);
      return
    }

    await deleteItem(deletar, handleProducts, setLoading, setDeletar);

  };
  const deletarItem = () => {
    setDeletar(null);
  };
  const bloquearItem = () => {
    setBloquear(null);
  };

  useEffect(() => {
    handleProducts(params);
  }, []);

  useEffect(() => {
    if (dados.result)
      return window.scrollTo(0, localStorage.getItem("top-serach"));
    contextType.closeMobileSiderBar();

    return () => {
      setarPosition();
    };
  }, []);

  return (
    <>
      <Loader loading={loading} />

      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={deletarItem}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}
      {bloquear && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim, Bloquear!"
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={() => confirmar('bloquear')}
          onCancel={bloquearItem}
        >
          Após essa ação o Produto irá ficar {!bloquear.active ? 'ativado' : 'desativado'}.
        </SweetAlert>
      )}

      <TitlePage
        title="Produtos"
        desc="Encontre Produtos"
        icon="fab fa-simplybuilt"
      />
      <div className="row">
        <div className="col-md-12">
          <SearchFor
            pegaResultados={pegaResultados}
            tiposDeBusca={[
              {key: 'name', label: 'Nome'},
              {key: 'code', label: 'Código'},
              {key: 'cnpj', label: 'CNPJ'},
            ]}
            searchFor={searchFor}
            setSearchFor={setSearchFor}
            keyword={keyword}
            setKeyword={setKeyword}
          />
          
          {
            produtos && (
              <Result
                dados={produtos}
                setarPosition={setarPosition}
                pegaResultados={pegaResultados}
                params={params}
                setDeletar={setDeletar}
                setBloquear={setBloquear}
              />
            )
          }
        </div>
      </div>
    </>
  );
}
