import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import {routes, routesAuth} from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';

import AuthMiddleWare from "../../components/auth"
function setTitle(path, routeArray) {
	var pageTitle;
	for (var i=0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = 'Achei Forcenedor | ' + routeArray[i].title;
		}
	}
	document.title = (pageTitle) ? pageTitle : 'Achei Fornecedor';
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);
	}
	UNSAFE_componentWillMount() {
    this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
    });
  }

	render() {

		return (
			<PageSettings.Consumer>
				{({pageContentFullWidth, pageContentClass, pageContentInverseMode}) => (
					<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
						<Switch>
						{routes.map((route, index) => (
							<AuthMiddleWare
								key={index}
								route={route}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
						))}
						{routesAuth.map((route, index) => (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								component={route.component}
							/>
						))}
						</Switch>
					</div>
				)
			}
			</PageSettings.Consumer>
		)
	}
}

export default withRouter(Content);
