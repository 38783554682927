import React from "react";

import PageDefault from "../pageDefault";
import { permissionsRequests } from "../../../services/admin/permissions";

export default function Permissions() {
  return (
    <PageDefault
      title={"Permissão"}
      titlePlural={"Permissões"}
      type="permissions"
      dataRequest={permissionsRequests}
      baseUrl={"permissoes"}
      columnsTable={[
        { key: "name", name: "Nome" },
        { key: "description", name: "Descrição", hide: "md" },
        {
          key: "status",
          name: "Status",
          hide: "sm"
        },
      ]}
      tiposDeBusca={[{ key: "name", label: "Nome" }]}  
    />
  );
}