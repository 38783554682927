import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input, Spinner } from 'reactstrap';
import { store } from "react-notifications-component";
import { useDispatch, useSelector } from 'react-redux';
import {logOut} from "../../../store/auth/action"
import { withRouter } from 'react-router-dom';
import { deletarConta } from "../../../services/auth/user";

import { toast, ToastContainer } from "react-toastify";

const ModalDeletar = ({modal, toggle, history, loading}) => {
  const dispatch = useDispatch();
  const avisar = async (token) => {

    setDisabled(true)

    try {
      const result = await deletarConta(token)
      if (result) {
        toast.success(
          "Sua conta foi deletada com sucesso.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          }
        )
        setTimeout(() => {
          dispatch(logOut(history));
        }, 2000);
      }

      // store.addNotification({
      //   title: "Conta deletada",
      //   message: "Sua conta foi deletada com sucesso.",
      //   type: "danger",
      //   insert: "top",
      //   container: "top-right",
      //   onRemoval: () => {
      //     dispatch(logOut(history))
      //   },
      //   animationIn: ["animated", "fadeIn"],
      //   animationOut: ["animated", "fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //     onScreen: true
      //   }
      // });

    } catch (err) {      
      toast.error(
        "Erro ao tentar deletar a conta, verifique o token enviado",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        }
      )
      // store.addNotification({
      //   title: "Erro ao deletar a conta",
      //   message: "Erro ao tentar deletar a conta, verifique o token enviado",
      //   type: "danger",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animated", "fadeIn"],
      //   animationOut: ["animated", "fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //     onScreen: true
      //   }
      // });
    }
  }
  const [cod, setCod] = useState("")
  const [disabled, setDisabled] = useState(true)

  useEffect(()=>{
    setCod("")
    setDisabled(true)
  }, [modal])

  useEffect(()=>{
    if(cod.length > 4){
        setDisabled(false)
    }else{
        setDisabled(true)
    }
  }, [cod])

  return (
    <>
      <ToastContainer/>
      <Modal isOpen={modal} toggle={toggle}  size="lg" centered>
        <ModalHeader toggle={toggle}>Deletar conta</ModalHeader>
        <ModalBody>
        {loading ?
          <div className="text-center">
            <Spinner size="large" />
          </div>
          
        :
        <Alert color="danger">
            <h3>Confirmar ação.</h3>
            <p>digite o código de validação que foi enviado ao seu e-mail.</p>
            <Input color="danger" placeholder={"Código de validação"} value={cod} onChange={(e)=> setCod(e.target.value)}/>

        </Alert>
        }

        </ModalBody>
        <ModalFooter>
          <Button block color="danger" disabled={disabled} onClick={()=>avisar(cod)}>Confirmar</Button>
          <Button block color="secondary" onClick={toggle}>Cancelar</Button>

        </ModalFooter>
      </Modal>
    </>
  );
}

export default withRouter(ModalDeletar);