import React, { useState, useEffect, useContext } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap"
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";
import { PageSettings } from "../../../config/page-settings"
import TitlePage from "../../../components/pages/title"
import Loader from "../../../components/loader";
import AreaDePreco from "../../../components/cotacao/areaDePreco"
import Topo from "../../../components/cotacao/topo"
import Frete from "../../..//components/cotacao/frete"
import Produto from "../../../components/cotacao/produto"
import CotarModal from "../cotarModal"
import ModalProduto from "../parts/modalProduto";
import { getCompanie } from "../../../services/companies";
import { getMyQuotation, responseQuotation } from "../../../services/quotations";
import { useHistory, useLocation } from "react-router";
import { store } from "react-notifications-component";
import pdfMake from "pdfmake/build/pdfmake";
import {withRouter} from "react-router-dom"
import pdfFonts from "pdfmake/build/vfs_fonts";
import { cnpjMask, moneyMask } from "../../../uteis/mask"
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const avisar = (title, msg, type) => {
	store.addNotification({
		title: title || "Usuário ou senha incorretos",
		message: msg || "Um código de verificação foi enviado para o seu email!",
		type: type || "danger",
		insert: "top",
		container: "top-right",
		animationIn: ["animated", "fadeIn"],
		animationOut: ["animated", "fadeOut"],
		dismiss: {
			duration: 5000,
			onScreen: true
		}
	});
}

const Cotar = (props) => {
	const location = useLocation()
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [tooltipOpenFOB, setTooltipOpenFOB] = useState(false);
	const [modalProduto, setModalProduto] = useState(false)
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState(false);
	const [responder, setResponder] = useState(false);
	const cotacaoId = props.match.params.id
	const queryParams = new URLSearchParams(location.search)
	const [isRespondida] = useState(queryParams.get('respondida')?.trim() === 'sim')

	const [produtos, setProdutos] = useState([])
	const [produtoToModal, setProdutoToModal] = useState({})
	const [precoCotacao, setPrecoCotacao] = useState({})
	const [frete, setFrete] = useState({})
	const [formaPamento, setFormaPamento] = useState("")
	const [prazoEntrega, setPrazoEntrega] = useState("")
	const [validade, setValidade] = useState(new Date())

	const [empresa, setEmpresa] = useState({})
	const [cotacao, setCotacao] = useState({})
	const [loadPDF, setLoadPDF] = useState(false)
	const contextType = useContext(PageSettings)
	const history = useHistory()

	const toggleModal = () => {
		setModal(!modal)
	}

	const toggleModalProduto = () => setModalProduto(!modalProduto)
	const toggle = () => setTooltipOpen(!tooltipOpen);
	const toggleFOB = () => setTooltipOpenFOB(!tooltipOpenFOB);

	const getCotacao = async idCotacao => {
		setLoading(true)
		await getMyQuotation(idCotacao)
			.then(async response => {
				const resCota = response.data.resposta.conteudo.quotation

				if (isRespondida) {
					const resposta = resCota.quotationResponse[0]
					setPrecoCotacao({
						discount: resposta.discount,
						tax: resposta.tax,
						total: resposta.total,
						subtotal: resposta.subtotal
					})
					setFrete({
						freight: resposta.freight,
						cost_of_freight: resposta.cost_of_freight
					})
					setFormaPamento(resposta.payment_methods)
					setValidade(moment(new Date(resposta.deadline)).format('YYYY-MM-DD'))
					setPrazoEntrega(resposta.validity_proposal)
				}
				await getEmpresa(isRespondida ? resCota.quotationDetails.company2_id : resCota.company2_id).then(() => setCotacao(resCota))
			})
			.catch(error => console.log(error))
			.finally(() => setLoading(false))
	}
	const gerarPdf = async () =>{
		setLoadPDF(true)
		const result = await getMyQuotation(cotacaoId)
								.then( res =>{
									return res.data.resposta.conteudo.quotation
								} )
									
		let produtos = [];
		const {products} = result
		if(products){
			for(let i = 0; i < products.length; i++){						
				const linha = [ 
					{text: `Produto: ${products[i].name}
							Observação: ${products[i].observations ? products[i].observations : ""}
							unidade de medida: ${products[i].unit_measurements_name}
							Quant.: ${products[i].quantity}
						`}
				]
				produtos.push(linha)
			}
		}else{
			const products = result.quotationDetails.products
			for(let i = 0; i < products.length; i++){						
				const linha = [ 
					{text: `Produto: ${products[i].name}
							Observação: ${products[i].observations ? products[i].observations : ""}
							unidade de medida: ${products[i].unit_measurements_name}
							Quant.: ${products[i].quantity}
						`}
				]
				produtos.push(linha)
			}
		}

		
		if(isRespondida){
			let docDefinition = {
				content: [
					{text: 'Cotação: #' + result.quotationDetails.quotation_code, style: "header"},
					{text: 'Status: ' + result.quotationDetails.status, style: "small"},
					{
						table: {
							headerRows: 1,
							widths: ["star", 90],
							body: [
								[{
								text : [
									{text:`Fornecedor: ${empresa.company_name}\n`, fontSize: 15},
									`CNPJ: ${cnpjMask(empresa.cnpj)}\n`,
									`Endereço: ${empresa.address}`,
								], style: ["small", "bold"]},
								{text : "Data da solicitação: " + moment(isRespondida ? new Date(cotacao?.quotationDetails?.created_at) : new Date(cotacao?.created_at)).format('DD/MM/YYYY'), fontSize: 9, textAlign: "right", style:[ "small", "light", "right"]}],
							]
						},
						layout: 'noBorders',
						style: 'tableExample',
						margin: [0, 10, 0, 0]
					},
					{text: "______________________________________________________________________________________________", style: "light"},
					{
						table: {
							widths: ["star"],
							headerRows: 1,
							body: produtos
						},
						layout: "lightHorizontalLines",
						style: 'tableExample',
						margin: [0, 10, 0, 0]
					},
					{text: "______________________________________________________________________________________________", style: "light"},
					{text:  "Forma de pagamento: " + formaPamento ? formaPamento : " ", margin: [0, 10, 0, 0]},
					{text: 'Frete: ' + result.quotationResponse[0].freight + " | Valor: " + moneyMask(frete?.cost_of_freight) || "Valor frete", margin: [0, 10, 0, 0]},
					{text: "(subtotal) " + moneyMask(precoCotacao.subtotal) + " + (taxas) " +  (precoCotacao.tax ? moneyMask(parseFloat(precoCotacao.tax)) : "R$ 0,00") + " - (desconto) " + (precoCotacao.discount ? moneyMask(parseFloat(precoCotacao.discount)): "R$ 0,00")},

					{text:  "Validade da proposta até: " + moment(validade).format("DD/MM/YYYY")},
					{text:  "Total: " + moneyMask(precoCotacao.total), style: "header",  margin: [0, 10, 0, 0]}

				],
				styles: {
					tableExample:{
						width: "100%",
						
					},
					right:{
						textAlign:"right"
					},
					bold: {
						bold: true
					},
					header: {
						fontSize: 22,
						bold: true
					},
					light: {color: "darkgray"},
					subheader: {
						fontSize: 15,
						bold: true
					},
					quote: {
						italics: true
					},
					small: {
						fontSize: 10
					}
				}
			};
			setLoadPDF(false)
			pdfMake.createPdf(docDefinition).download(result.quotation_code);
		}else{
			let docDefinition = {
				content: [
					{text: 'Cotação: #' + result.quotation_code, style: "header"},
					{text: 'Status:' + result.status, style: "small"},
					{
						table: {
							headerRows: 1,
							widths: ["star", 90],
							body: [
								[{
								text : [
									{text:`Fornecedor: ${empresa.company_name}\n`, fontSize: 15},
									`CNPJ: ${cnpjMask(empresa.cnpj)}\n`,
									`Endereço: ${empresa.address}`,
								], style: ["small", "bold"]},
								{text : "Data da solicitação: " + moment(isRespondida ? new Date(cotacao?.quotationDetails?.created_at) : new Date(cotacao?.created_at)).format('DD/MM/YYYY'), fontSize: 9, textAlign: "right", style:[ "small", "light", "right"]}],
							]
						},
						layout: 'noBorders',
						style: 'tableExample',
						margin: [0, 10, 0, 0]
					},
					{text: "______________________________________________________________________________________________", style: "light"},
					{
						table: {
							widths: ["star"],
							headerRows: 1,
							body: produtos
						},
						layout: "lightHorizontalLines",
						style: 'tableExample',
						margin: [0, 10, 0, 0]
					},
				],
				styles: {
					tableExample:{
						width: "100%",
						
					},
					right:{
						textAlign:"right"
					},
					bold: {
						bold: true
					},
					header: {
						fontSize: 22,
						bold: true
					},
					light: {color: "darkgray"},
					subheader: {
						fontSize: 15,
						bold: true
					},
					quote: {
						italics: true
					},
					small: {
						fontSize: 10
					}
				}
			};
			setLoadPDF(false)
			pdfMake.createPdf(docDefinition).download(result.quotation_code);
		}


	}
	const getEmpresa = async idEmpresa => {
		setLoading(true)
		await getCompanie(idEmpresa)
			.then(response => {
				setEmpresa(response.data.resposta.conteudo.empresa)
			})
			.catch(error => console.log(error))
			.finally(() => setLoading(false))
	}

	const confirmar = async () => {
		setLoading(true)
		const dataToSave = {
			product_quotations_id: parseInt(window.location.pathname.split('/').pop()),
			...precoCotacao,
			...frete,
			products: produtos,
			payment_methods: formaPamento,
			validity_proposal: prazoEntrega,
			deadline: validade
		}
		await responseQuotation(dataToSave)
			.then(response => {
				avisar("Sucesso", response.data.resposta.conteudo.message, "success")
				history.push('/cotacao/clientes/respondida')
			})
			.catch(error => console.log(error))
			.finally(() => {
				setLoading(false)
				setResponder(false)
			})
	}

	useEffect(() => {
		getCotacao(window.location.pathname.split('/').pop())
		window.scrollTo(0, 0)
		contextType.closeMobileSiderBar()
	}, []);
	// add btn info ok
	// modal
	// modal info

	return (
		<>
			<ModalProduto modal={modalProduto} toggle={toggleModalProduto} produto={produtoToModal} />
			<Loader loading={loading} />
			<TitlePage title="Minha cotação" desc={cotacao?.quotationDetails?.status || cotacao?.status || ''} icon="fal fa-box" />

			<CotarModal toggle={toggleModal} setLoading={setLoading} modal={modal} />
			{(responder &&
				<SweetAlert warning showCancel
					confirmBtnText="Sim, Confirmar!"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title="Confira todas as informações, não será possivel editar essa cotação depois."
					onConfirm={confirmar}
					onCancel={confirmar}
				>
					Após essa ação Você não conseguira recuperar os dados.
      			</SweetAlert>
			)}
			<div className="invoice p-20">
				<Topo loadPDF={loadPDF} 
						gerarPdf={gerarPdf} 
						empresa={empresa} 
						quotation={cotacao} 
						dataSolicitacao={moment(isRespondida ? new Date(cotacao?.quotationDetails?.created_at) : new Date(cotacao?.created_at)).format('DD/MM/YYYY')} />
				<div className="invoice-content">
					{
						new URLSearchParams(location.search).get('respondida')?.trim() === 'sim' ? (
							Object.keys(cotacao).length > 0 && cotacao.quotationDetails.products.map(prod => {
								return <Produto
									key={prod.product_id}
									produto={prod}
									respostaProduto={cotacao.quotationResponse[0].products.find(rProd => rProd.product_id === prod.product_id)}
									isRespondida={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
									images={prod.images}
									disabled={true}
									hideField={true}
									toggleModal={toggleModalProduto}
									setProdutoToModal={setProdutoToModal}
								/>
							})
						) : (
							cotacao.products && cotacao.products.map(prod => {
								return <Produto
									key={prod.product_id}
									produto={prod}
									isRespondida={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
									images={prod.images}
									disabled={true}
									hideField={true}
									toggleModal={toggleModalProduto}
									setProdutoToModal={setProdutoToModal}
								/>
							})
						)
					}

					{/* campos da resposta */}
					{
						isRespondida && (
							<>
								<div className="invoice-price mt-4">
									<AreaDePreco
										precos={produtos}
										precoCotacao={precoCotacao}
										setPrecoCotacao={setPrecoCotacao}
										disabled={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
									/>
								</div>
								<div className="invoice-price mt-2">
									<Frete
										
										toggle={toggle}
										toggleFOB={toggleFOB}
										tooltipOpen={tooltipOpen}
										tooltipOpenFOB={tooltipOpenFOB}
										frete={frete}
										setFrete={setFrete}
										disabled={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
									/>
								</div>
								<Row>
									<Col xs={12} className="mt-4">
										<div className="sub-price">
											<FormGroup>
												<small>Forma de pagamento </small>
												<Input
													type="textarea"
													name="text"
													placeholder="Forma de pagamento"
													value={formaPamento}
													disabled={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
													onChange={e => setFormaPamento(e.target.value)}
												/>
											</FormGroup>
										</div>
									</Col>

									<Col xs={12} className="mt-1">
										<div className="sub-price">
											<FormGroup>
												<small>Prazo de entrega </small>
												<Input
													type="textarea"
													name="text"
													placeholder="Prazo para entrega"
													value={prazoEntrega}
													disabled={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
													onChange={e => setPrazoEntrega(e.target.value)}
												/>
											</FormGroup>
										</div>
									</Col>
								</Row>
								<div className="mt-4 justify-content-end d-flex">
									<FormGroup className="">
										<Label>Validade da proposta até</Label>
										<Input
											type="date"
											name="date"
											placeholder="Valor"
											style={{ maxWidth: 220 }}
											value={validade}
											disabled={new URLSearchParams(location.search).get('respondida')?.trim() === 'sim'}
											onChange={e => setValidade(e.target.value)}
										/>
									</FormGroup>
								</div>
							</>
						)
					}
				</div>

				<div className="invoice-footer">
					<p className="text-center m-b-5 f-w-600">
						Obrigado pela sua solicitação
					</p>
				</div>
				<hr />
				{/* <Conversas /> */}

			</div>

		</>
	);
};

export default withRouter(Cotar);
