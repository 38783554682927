import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

import ItemCotacao from "../Item"
import {PageSettings} from "../../../config/page-settings"
import Loader   from "../../../components/loader";
import Breadcrumb     from "../../../components/breakcumbs"
import TitlePage      from "../../../components/pages/title"
import { getRequestQuotations } from "../../../services/quotations";
import { cnpjMask } from "../../../uteis/mask";
import Pagination from "../../../components/paginate/pagination";

const Cotar = ( ) => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] =  useState(false);
  const [cotacoes, setCotacoes] = useState({data: []});
  const [params, setParams] = useState({status: 'Pendente', order: 'desc', per_page: 30})

  const contextType = useContext(PageSettings);
  const toggleModal = () =>{
      setModal(!modal)
  }

  const getContacoesRecebidas = async (currentParams = {}) => {
    setLoading(true)
    await getRequestQuotations({...params, ...currentParams})
      .then(reponse => {
        setCotacoes(reponse.data.resposta.conteudo.quotations)
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getContacoesRecebidas()
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
  }, []);

  useEffect(() => {
    getContacoesRecebidas()
  }, [params])

  return (
    <>
      <Loader loading={loading} />
      <TitlePage title="Cotações de clientes" desc="Pendentes" icon="fal fa-box" />
      <form className="form-inline mb-3">
        <div className="form-group m-r-10">
          <label className="mr-2">Ordenar</label>
          <select
            className="form-control"
            onChange={e => setParams({...params, order: e.target.value})}
          >
            <option value="desc">Mais recente</option>
            <option value="asc">Mais antiga</option>
          </select>
        </div>

        <div className="form-group m-r-10">
          <label className="mr-2">Exibir</label>
          <select
            className="form-control"
            onChange={e => setParams({ ...params, per_page: e.target.value })}
          >
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
          </select>
        </div>
      </form>
      <div >
        <div className="row">

          {
            cotacoes.data.length > 0 ? cotacoes.data.map(cota => {
              return <ItemCotacao key={cota.id}
                cotacao={{
                  number: cota.quotation_code,
                  cnpj: cnpjMask(cota.company.cnpj) || '00.0000/0000-00',
                  link: `/cotacao/cliente/responder/${cota.id}`,
                  de: 'Minha Empresa',
                  para: cota.company.company_name || 'Empresa Fornecedora',
                  status: cota.status,
                  preco: cota.preco || null,
                  data: moment(new Date(cota.created_at)).format('DD/MM/YY'),
                  valido: cota.valid || null,
                  ...cota
                }}
                companyOrCompany2={'company'}
                tipo="cliente"
              />
            }) : (
              <h2>Sem cotações</h2>
            )
          }

        </div>
        <div className="clearfix m-t-20">
          <Pagination
            firstPageUrl={cotacoes.first_page_url}
            lastPageUrl={cotacoes.last_page_url}
            nextPageUrl={cotacoes.next_page_url}
            previousPageUrl={cotacoes.prev_page_url}

            perPage={cotacoes.per_page}
            lastPage={cotacoes.last_page}
            currentPage={cotacoes.current_page}
            currentUrl={cotacoes.currentUrlProducts}
            path={cotacoes.path}

            params={params}

            {...cotacoes}

            getItems={getContacoesRecebidas}
          />
        </div>
      </div>
    </>
  );
};

export default Cotar;
