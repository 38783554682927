import React, { useEffect, useState } from 'react'

import { URL_TO_IMAGES } from '../../../config/api.config'
import product from '../../../components/cotacao/product.svg'

    export default function ProdutoCotacao({produto, observacao, type, setProdutoToModal, toggleModal}) {
    const [imageURL, setTmageURL] = useState(product)
    useEffect(() => {
        if (produto.images?.length > 0) {
            const images = produto.images
            if (images.some(i => i.featured_image === 1)) {
                setTmageURL(URL_TO_IMAGES+'/'+images.find(i => i.featured_image === 1).path.replace('public', ''))
            } else {
                setTmageURL(URL_TO_IMAGES+'/'+images[0].path.replace('public', ''))
            }
        } else {
            setTmageURL(product)
        }
    })
    return (
        <div key={produto.id} style={{
            padding: '1rem',
            display: 'flex'
        }}>
            <div style={{
                width: '150px',
                height: '100px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img className="img-fluid" src={imageURL} alt="" />
            </div>
            <div style={{
                padding: '0 15px',
                width: 'fit-content',
                height: '100px',
                overflow: 'hidden'
            }}>
                <h5 className="media-heading">
                    {produto.name}
                    {
                        type === 'list' && (
                            <button
                                className="btn btn-info ml-2 btn-icon btn-circle btn-sm" 
                                onClick={() => {
                                    setProdutoToModal(produto)
                                    return toggleModal ? toggleModal({...produto, imageURL}) : null}
                                }
                            >
                                <i className="fas fa-info "></i>
                            </button>
                        )
                    }
                </h5>
                <div className="desc" dangerouslySetInnerHTML={{ __html: type === 'list' ? observacao : produto.description }}></div>
            </div>
        </div>
    )
}