import React, { useState, useEffect, useContext } from "react";
import Loader   from "../../components/loader";
import Campos from "./cadastrar/novoServico"

import {PageSettings} from "../../config/page-settings"

import Breadcrumb     from "../../components/breakcumbs"
import TitlePage      from "../../components/pages/title"

// cadastrar categoria


const Home = ( ) => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const [modalProduto, toggleModal] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  
  const toggleModalProduto = () =>{
    toggleModal(!modalProduto)
  }
  return (
    <>
      <Loader loading={loading} />
      <TitlePage title="Cadastrar novo serviço" desc="Cadastre todos os seus serviço." icon="fal fa-box" />
      <Campos modalProduto={modalProduto} toggleModal={toggleModalProduto} setLoading={setLoading} /> 
    </>
  );
};

export default Home;
