import * as types from "./types"


export const signUp = (values) => {
    return {
        type: types.SING_UP,
        payload: {values}
    }
}

export const singUpSuccess = () => {
    return{
        type: types.SIGN_SUCCESS,
        payload: true
    }
}

export const singUpFailed = () => {
    return{
        type: types.SIGN_SUCCESS,
        payload: true
    }
}