import React, { useEffect, useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
// verificar se o usuario esta logado no amplify
const Authmiddleware = ({ component: Component, path }) => {
	const expired = localStorage.getItem("expires_at") | 0

	const [userInfo, setuserInfo] = useState({email_verified_at: null})

	useEffect(() => {
		setuserInfo(localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo") || ""): "")
	}, [])
	
	return (
		<Route
			path={path}
			render={props => {
				const local = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo") || ""): ""
				if (!localStorage.getItem("access_token") || Date.now() > new Date(localStorage.getItem("expires_at") || 0) || !!local.email_verified_at !== true) {
					if (!!userInfo.email_verified_at !== true) {
						return <Redirect to={{ pathname: "/login/nao-valido", state: { from: props.location } }} />
					}
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}
				return (
					<>
						<Component  {...props} />
					</>
				);
			}}
		/>
	)
};

export default withRouter(Authmiddleware);