import api from "../api";
import { BASE_URL_PRODUCTS, API } from '../../config/api.config'

api.defaults.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`

export const getProduct = (productId) => api.get(`${BASE_URL_PRODUCTS}/${productId}`)
export const getProducts = (params) => api.get(`${BASE_URL_PRODUCTS}`, {
    params
})

export const getOtherProducts = (params) => api.get(`${API}/auth/products-company`, {params})

export const deleteProduct = (productId) => api.delete(`${BASE_URL_PRODUCTS}/${productId}`)
export const deleteProductImage = (imageId) => api.delete(`auth/product-image/${imageId}`)
export const createProduct = (product, productId = null) => {
    return api.post(`${BASE_URL_PRODUCTS}${productId ? '/'+productId : ''}`, product)
}

export const createImages = (values) => api.post(`auth/products-images`, values)
export const updateImages = (values, productId) => api.post(`auth/products-images/${productId}`, values)

export const duplicateImage = ({product_id, new_product_id}) => api.post(`auth/duplicate-image`, {product_id, new_product_id})


export const getProductVisits = (id) => {
    return new Promise( (resolve, reject) =>{
        api.get(`${API}/auth/dashboard/number-visits-product/${id}`)
                .then(result => resolve(result.data.resposta.conteudo.numberVisitsProduct))
                .catch( err => reject(err) )
    })
}


