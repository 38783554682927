import React, {useState, useEffect, useContext} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {Badge} from "reactstrap"

import { PageSettings } from './../../config/page-settings.js';
import back1 from "../../assets/img/backgrounds/1back.jpg";
import { URL_TO_IMAGES } from '../../config/api.config.js';

import sideBarBack1 from "../../assets/img/backgrounds/sidebar1.jpg"
import { getBackgrounds } from '../../services/background/index.js';

const SidebarProfile = (props)  => {
	const [profileActive, setProfileActive] = useState(0) 
	const [companie, setCompanie] = useState({})

    const {user, current_companie} = useSelector(state => state.auth)
    const [back, setBack] = useState(back1)
    
    const contextType = useContext(PageSettings);

	const handleProfileExpand = (e) => {
		e.preventDefault();
		setProfileActive(!profileActive)
    }
    const pegaBackgrounds = async (idCompany) => {
        await getBackgrounds(idCompany)
            .then(response => {contextType.handleSetChangeBackground(true)})
            .catch(err => console.log(err))
            .finally(() => {})
    }

    useEffect(() => {
		setBack(localStorage.getItem('background-sidebar') || sideBarBack1)
        contextType.handleSetChangeBackground(false);
    }, [contextType.changeBackground])

	useEffect(() => {
		if (current_companie && Object.keys(current_companie).length > 0) {
			setCompanie(current_companie)
			pegaBackgrounds(current_companie.id)
		}
	}, [current_companie])
    
    if(!user) return null
    
	return (
		<PageSettings.Consumer>
			{({pageSidebarMinify}) => (
				<ul className="nav">
					<li className={"nav-profile " + (profileActive ? "expand " : "")}>
						<Link to="/" onClick={handleProfileExpand}>
							<div className="cover with-shadow" style={{ backgroundImage: `url(${back})` }}></div>
							
							<div className="image">
								<img
									src={current_companie?.company_logo ? URL_TO_IMAGES+'/'+current_companie?.company_logo.replace('public', '') : "/assets/img/user/user-13.jpg"}
									alt=""
									style={{
										width: '100%',
    									height: '100%'
									}}
								/>
							</div>
							<div className="info">
								<b className="caret pull-right"></b>
								{user.companies ? current_companie.company_name : user.email}<br/>
								<Badge className="bg-danger mt-2">Administrador</Badge>
							</div>
						</Link>
					</li>
					<li>
						<ul className={"nav nav-profile " + (profileActive && !pageSidebarMinify ? "d-block " : "")}>
							<li><Link to="/configuracoes"><i className="fad fa-cog"></i> Configuraçāo</Link></li>
							<li><Link to="/trocar-senha"><i className="fad fa-shield-alt"></i>Alterar senha</Link></li>
						</ul>
					</li>
				</ul>
			)}
		</PageSettings.Consumer>
	)
	
}

export default SidebarProfile;