import React, { useState, useEffect, useContext } from "react";
import Loader   from "../../../components/loader";

import ItemCotacao from "../Item"
import {PageSettings} from "../../../config/page-settings"

import Breadcrumb     from "../../../components/breakcumbs"
import TitlePage      from "../../../components/pages/title"

import CotarModal from "../cotarModal"
import ItemCotado from "../Item";
import { getMyQuotations } from "../../../services/quotations";
import moment from "moment";
import { cnpjMask } from "../../../uteis/mask";
import Pagination from "../../../components/paginate/pagination";

const Cotar = ( ) => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] =  useState(false);
  const [cotacoes, setCotacoes] = useState({data: []});
  const [params, setParams] = useState({status: 'Pendente', order: 'desc', per_page: 30})

  const contextType = useContext(PageSettings);
  const toggleModal = () =>{
      setModal(!modal)
  }

  const getMinhasCotações = async (currentParams = {}) => {
    setLoading(true)
    await getMyQuotations({...params, ...currentParams})
      .then(reponse => {
        setCotacoes(reponse.data.resposta.conteudo.quotations)
      })
      .catch(error => console.log(error))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    getMinhasCotações()
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
  }, [])
  
  useEffect(() => {
    getMinhasCotações()
  }, [params])

  const cotacoesFake = [
    {number: "#090906", cnpj: "12321.12312/213",link: "/cotacao/minhascontacao/visualizar-cotacao" ,de: "Sua Empresa", para: "Empresa de Fulano", status: "Aguardando", preco: null, data: null, valido: false}
  ]

  return (
    <>
      <Loader loading={loading} />
      <TitlePage title="Minhas cotações" desc="Cotações pendentes." icon="fal fa-box" />
      <CotarModal toggle={toggleModal} setLoading={setLoading} modal={modal}/>

      <form className="form-inline mb-3">
        <div className="form-group m-r-10">
          <label className="mr-2">Ordenar</label>          
          <select
            className="form-control"
            onChange={e => setParams({...params, order: e.target.value})}
          >
            <option value="desc">Mais recente</option>
            <option value="asc">Mais antiga</option>
          </select>
        </div>


        <div className="form-group m-r-10">
          <label className="mr-2">Exibir</label>
          <select
            className="form-control"            
            onChange={e => setParams({...params, per_page: e.target.value})}
          >
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={60}>60</option>
            <option value={70}>70</option>
          </select>
        </div>

      </form>
      <div >
        <div className="row">
          {
            cotacoes.data.length > 0 ? cotacoes.data.map(cota => {
              return <ItemCotacao key={cota.id} cotacao={{
                number: cota.quotation_code,
                cnpj: cnpjMask(cota.company2.cnpj) || '00.0000/0000-00',
                link: `/cotacao//${cota.id}`,
                de: 'Minha Empresa',
                para: cota.company2.company_name || 'Empresa Fornecedora',
                status: cota.status,
                preco: cota.preco || null,
                data: moment(new Date(cota.created_at)).format('DD/MM/YY'),
                valido: cota.valid || null,
                ...cota
              }} />
            }) : (
              <h2>Sem cotações</h2>
            )
          }

        </div>

        <div className="clearfix m-t-20">
          <Pagination
            firstPageUrl={cotacoes.first_page_url}
            lastPageUrl={cotacoes.last_page_url}
            nextPageUrl={cotacoes.next_page_url}
            previousPageUrl={cotacoes.prev_page_url}

            perPage={cotacoes.per_page}
            lastPage={cotacoes.last_page}
            currentPage={cotacoes.current_page}
            currentUrl={cotacoes.currentUrlProducts}
            path={cotacoes.path}
            params={params}

            {...cotacoes}

            getItems={getMinhasCotações}
          />
        </div>
      </div>
    </>
  );
};

export default Cotar;
