import React, { useState, useEffect, useContext } from "react";
import { store } from "react-notifications-component";
import SweetAlert from 'react-bootstrap-sweetalert'

import ItemProcurar from "../../components/carteiras/solicitaoes"
import { PageSettings } from "../../config/page-settings"
import Breadcrumb from "../../components/breakcumbs"
import TitlePage from "../../components/pages/title"
import Loader from "../../components/loader";
import { getPendingRequests, aceiteDoFornecedor, aceitePortfolio, recusaDoCliente } from "../../services/portfolios";

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const MinhaCarteiraSolicitacoes = ({setHasChange}) => {
    const contextType = useContext(PageSettings);

    const [loading, setLoading] = useState(true);
    const [isRecusar, setIsRecusar] = useState(null);
    const [solicitaoes, setSolicitacoes] = useState([])

    const getSolicitacoes = async () => {
        await getPendingRequests()
            .then(response => {
                setSolicitacoes(response.data.resposta.conteudo.pendingRequests)
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const aceitar = async (id_pending_requests, type = "fornecedor") => {
        setLoading(true)
        await aceitePortfolio(id_pending_requests)
            .then(response => {
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
                getSolicitacoes()
                setHasChange(true)
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const recusar = async (id_pending_requests, type = "fornecedor") => {
        setLoading(true)
        await recusaDoCliente(id_pending_requests)
            .then(response => {
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
                getSolicitacoes()
            }) 
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const confirmar = async () => {
        recusar(isRecusar.idRequest, isRecusar.type)
        setIsRecusar(null)
    }
    const cancelar = () => {
        setIsRecusar(null)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        getSolicitacoes()
    }, []);

    return (
        <>
            {(isRecusar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim"
                    confirmBtnBsStyle="outline-success"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Cancelar"
                    title="Deseja Recusar o pepido dessa empresa?"
                    onConfirm={confirmar}
                    onCancel={cancelar}
                >
                </SweetAlert>
            )}
            <Loader loading={loading} />
            <div className="row">
                <div className="col-md-12">
                    <div className="result-container">
                        <ul className="result-list">
                            {
                                solicitaoes?.length > 0 ? solicitaoes.map(solicitacao => (
                                    <ItemProcurar
                                        key={solicitacao.id}
                                        type={solicitacao.type}
                                        item={solicitacao}
                                        aceitar={aceitar}
                                        setIsRecusar={setIsRecusar}
                                    />
                                )) : (
                                        <h4 className="mt-3">Nenhuma solicitação pendente</h4>
                                    )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MinhaCarteiraSolicitacoes;
