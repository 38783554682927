import React, { useEffect } from "react"
import { useState } from "react"
import { Row, Input, Col, FormGroup } from "reactstrap"
import { moneyMask, clearMask } from "../../uteis/mask"
import { convertIntToFloadDot } from "../../uteis"

const AreaDePreco = ({ disabled, precos, precoCotacao, setPrecoCotacao  }) => {
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [taxas, setTaxas] = useState(0)
    const [desconto, setDesconto] = useState(0)

    const getDesconto = (value, porc) => {
        return (porc / 100) * value
    }
    
    useEffect(() => {
        const newSubtotal = precos.map(preco => clearMask(preco.unitary_value) * preco.amount).reduce((soma, i) => {return soma + i}, 0)
        const newTotal = convertIntToFloadDot(parseInt(newSubtotal) + parseInt(clearMask(taxas)))
        if (disabled) {
            return   
        }
        setSubtotal(newSubtotal)

        setTotal( (newTotal - getDesconto(newTotal, desconto)).toFixed(2) )

        setPrecoCotacao({
            discount: desconto,
            tax: convertIntToFloadDot(clearMask(taxas))
        })
    }, [precos, subtotal, total, taxas, desconto])

    return <>
        <div className="invoice-price-left">
            <div className="invoice-price-row">
                <div className="sub-price">
                    <small>SUBTOTAL</small>
                    <span className="text-inverse">
                        {disabled ? 'R$ ' + precoCotacao.subtotal : moneyMask(subtotal)}
                    </span>
                </div>
                <div className="sub-price">
                    <i className="fa fa-plus text-muted"></i>
                </div>
                <div className="sub-price">
                    <small>Taxas </small>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    disabled={disabled}
                                    value={moneyMask(disabled ? precoCotacao.tax : taxas || '')}
                                    onChange={(e) => {
                                        setTaxas(e.target.value)
                                    }}
                                    type="text"
                                    placeholder="Valor"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <div className="sub-price">
                    <i className="fa fa-minus text-muted"></i>
                </div>
                <div className="sub-price">
                    <small>Desconto (%) </small>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    name="desconto" 
                                    placeholder="Valor"
                                    type="number"
                                    disabled={disabled}
                                    value={disabled ? precoCotacao.discount : desconto}
                                    onChange={e => setDesconto(parseFloat(e.target.value))}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        <div className="invoice-price-right">
            {/* <small>TOTAL</small> <span className="f-w-600">{disabled ? 'R$ '+ precoCotacao.total : moneyMask(total)}</span> */}
            <small>TOTAL</small> <span className="f-w-600">{disabled ? 'R$ '+ precoCotacao.total : moneyMask(total)}</span>
        </div>
    </>
}

export default AreaDePreco