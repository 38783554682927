import React, { useState, useEffect, useContext } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";

import classnames from "classnames";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import { store } from "react-notifications-component";
import BasicSingUp from "./parts/cadastroInfo";
import Horarios from "./parts/horarios";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
} from "reactstrap";

import { PageSettings } from "../../../config/page-settings";
import Loader from "../../../components/loader";
import TitlePage from "../../../components/pages/title";
import Contato from "./parts/contato";
import Topo from "./parts/Topo";
import {
  updateCompanie,
  saveBusinessHour,
  updateBusinessHour,
  getCompanie,
} from "../../../services/companies";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stringHtmlDataToDraftState } from "../../../uteis/draft-jsCustom.js";

import { toast, ToastContainer } from "react-toastify";

import { getCompanie as getCompanieStore } from "../../../store/auth/action.js";
import { companiesRequests } from "../../../services/admin/companies.js";
import { useParams } from "react-router-dom";

const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

const PerfilEmpresa = (props) => {
  const { user } = useSelector((state) => state.auth);

  const contextType = useContext(PageSettings);
  const [activeTab, setActiveTab] = useState("1");
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empresa, setEmpresa] = useState({
    company_name: "",
    fantasy_name: "",
    cnpj: "",
    companies_type: [],
    postal_code: "",
    address: "",
    complement: "",
    number: "",
    neighborhood: "",
    description: EditorState.createEmpty(),
    economic_activity: [],
    phone: "",
    financial_phone: "",
    whatsapp: "",
    email: "",
    website: "",
    profile: "",
    active: "",
    youtube: "",
    instagram: "",
    facebook: "",
    linkedin: "",
    twitter: "",
  });
  const [businessHour, setBusinessHour] = useState([
    { nome: "Segunda-feira", tipo: "Aberto 24h", de: "", ate: "" },
    { nome: "Terça-feira", tipo: "Aberto 24h", de: "", ate: "" },
    { nome: "Quarta-feira", tipo: "Aberto 24h", de: "", ate: "" },
    { nome: "Quinta-feira", tipo: "Aberto 24h", de: "", ate: "" },
    { nome: "Sexta-Feira", tipo: "Aberto 24h", de: "", ate: "" },
    { nome: "Sábado", tipo: "Aberto 24h", de: "", ate: "" },
    { nome: "Domingo", tipo: "Aberto 24h", de: "", ate: "" },
  ]);

  const [current_companie, setCurrent_companie] = useState({});

  const { id } = useParams();

  const getEmpresaStore = async () => {
    await getEmpresa(id);
  };

  const getEmpresa = async (idEmpresa) => {
    setLoading(true);
    await companiesRequests.show(idEmpresa)
      .then((response) => {
        setEmpresa(response.data.resposta.conteudo.empresa);
        setCurrent_companie(response.data.resposta.conteudo.empresa);

        if (!response.data.resposta.conteudo.empresa.business_hours[0]) return;

        setBusinessHourFunction(
          response.data.resposta.conteudo.empresa.business_hours[0]
        );
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const setBusinessHourFunction = (result) => {
    const newBusinessHour = [
      {
        nome: "Segunda-feira",
        tipo: result.week_day_status_0,
        de: result.opening_0,
        ate: result.closing_0,
      },
      {
        nome: "Terça-feira",
        tipo: result.week_day_status_1,
        de: result.opening_1,
        ate: result.closing_1,
      },
      {
        nome: "Quarta-feira",
        tipo: result.week_day_status_2,
        de: result.opening_2,
        ate: result.closing_2,
      },
      {
        nome: "Quinta-feira",
        tipo: result.week_day_status_3,
        de: result.opening_3,
        ate: result.closing_3,
      },
      {
        nome: "Sexta-Feira",
        tipo: result.week_day_status_4,
        de: result.opening_4,
        ate: result.closing_4,
      },
      {
        nome: "Sábado",
        tipo: result.week_day_status_5,
        de: result.opening_5,
        ate: result.closing_5,
      },
      {
        nome: "Domingo",
        tipo: result.week_day_status_6,
        de: result.opening_6,
        ate: result.closing_6,
      },
    ];

    setBusinessHour(newBusinessHour);
  };

  const salvarDados = async (day) => {
    setBtnLoading(true);
    const [segunda, terca, quarta, quinta, sexta, sabado, domingo] =
      businessHour;
    const newBusinessHour = {
      company_id: user.companies[0].id,
      week_day_0: "segunda-feira",
      week_day_status_0: segunda.tipo,
      opening_0: segunda.de,
      closing_0: segunda.ate,
      week_day_1: "terca-feira",
      week_day_status_1: terca.tipo,
      opening_1: terca.de,
      closing_1: terca.ate,
      week_day_2: "quarta-feira",
      week_day_status_2: quarta.tipo,
      opening_2: quarta.de,
      closing_2: quarta.ate,
      week_day_3: "quinta-feira",
      week_day_status_3: quinta.tipo,
      opening_3: quinta.de,
      closing_3: quinta.ate,
      week_day_4: "sexta-feira",
      week_day_status_4: sexta.tipo,
      opening_4: sexta.de,
      closing_4: sexta.ate,
      week_day_5: "sabado",
      week_day_status_5: sabado.tipo,
      opening_5: sabado.de,
      closing_5: sabado.ate,
      week_day_6: "domingo",
      week_day_status_6: domingo.tipo,
      opening_6: domingo.de,
      closing_6: domingo.ate,
    };
    if (current_companie?.business_hours?.length > 0) {
      const oldHour = current_companie?.business_hours.pop();

      await updateBusinessHour(oldHour.id, newBusinessHour).then((response) => {
        console.log("update newBusinessHour", response);
      });
    } else {
      await saveBusinessHour(newBusinessHour).then((response) => {
        console.log("save newBusinessHour", response);
      });
    }

    const descriptionContent =
      typeof empresa.description === "string"
        ? stringHtmlDataToDraftState(empresa.description || "")
        : empresa.description;
    const companies_types = empresa.companies_types.map((e) => e.id);
    const economic_activities = empresa.economic_activities.map((e) => e.id);
    await companiesRequests
      .update({
        id: user.companies[0].id,
        ...empresa,
        companies_types,
        economic_activities,
        cnpj: empresa.cnpj.replace(/\D/g, ""),
        description: empresa.description
          ? draftToHtml(convertToRaw(descriptionContent.getCurrentContent()))
          : null,
      })
      .then((response) => {
        toast.success("Seus dados foram atualizados com suceso!!!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages;
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              avisar("Ops!!", message, "danger");
            });
          });
          return;
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger");
      })
      .finally(() => {
        setLoading(false);
        setBtnLoading(false);
      });
  };

  //perfilUsuario
  useEffect(() => {
    contextType.closeMobileSiderBar();
    getEmpresaStore();
  }, []);

  useEffect(() => {
    const description = stringHtmlDataToDraftState(
      current_companie.description || ""
    );

    setEmpresa({
      description,
      ...current_companie,
    });

    if (
      current_companie?.business_hours &&
      current_companie?.business_hours.length > 0
    ) {
      const oldHour = current_companie?.business_hours.pop();
      setBusinessHour([
        {
          nome: "Segunda-feira",
          tipo: oldHour.week_day_status_0,
          de: oldHour.opening_0,
          ate: oldHour.closing_0,
        },
        {
          nome: "Terça-feira",
          tipo: oldHour.week_day_status_1,
          de: oldHour.opening_1,
          ate: oldHour.closing_1,
        },
        {
          nome: "Quarta-feira",
          tipo: oldHour.week_day_status_2,
          de: oldHour.opening_2,
          ate: oldHour.closing_2,
        },
        {
          nome: "Quinta-feira",
          tipo: oldHour.week_day_status_3,
          de: oldHour.opening_3,
          ate: oldHour.closing_3,
        },
        {
          nome: "Sexta-Feira",
          tipo: oldHour.week_day_status_4,
          de: oldHour.opening_4,
          ate: oldHour.closing_4,
        },
        {
          nome: "Sábado",
          tipo: oldHour.week_day_status_5,
          de: oldHour.opening_5,
          ate: oldHour.closing_5,
        },
        {
          nome: "Domingo",
          tipo: oldHour.week_day_status_6,
          de: oldHour.opening_6,
          ate: oldHour.closing_6,
        },
      ]);
    }
  }, [current_companie]);

  return (
    <>
      <ToastContainer />
      <Loader loading={loading} />

      <TitlePage
        title="Cadastro da Empresa"
        desc="Para ter destaque nas pesquisas sempre preencha todos os campos."
      />
      <Row>
        <Col sm={12}>
          <Panel theme="default">
            <PanelHeader>Sobre a Empresa</PanelHeader>
            <PanelBody>
              <Topo
                empresa={empresa}
                getEmpresa={getEmpresaStore}
                setLoading={setLoading}
              />
              <Nav tabs className="nav-pills">
                <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Informações basicas
                  </NavLink>
                </NavItem>
                <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Contato
                  </NavLink>
                </NavItem>
                <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Horário de funcionamento
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <BasicSingUp
                    setLoading={setLoading}
                    empresa={empresa}
                    setEmpresa={setEmpresa}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <Contato empresa={empresa} setEmpresa={setEmpresa} />
                </TabPane>
                <TabPane tabId="3">
                  <Horarios
                    businessHour={businessHour}
                    setBusinessHour={setBusinessHour}
                  />
                </TabPane>
                <Button
                  disabled={btnLoading}
                  className="btn-rounded btn-lg bg-secondary"
                  onClick={salvarDados}
                >
                  {btnLoading ? (
                    <i className="fas fa-spinner fa-spin text-inverse mr-1"></i>
                  ) : null}
                  Salvar
                </Button>
              </TabContent>
            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default PerfilEmpresa;
