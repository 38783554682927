import { API } from "../../../config/api.config";
import api from "../../api";

const baseUrlCotegory = `${API}/auth/categoryService`

api.defaults.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`

export const getCategory = (categoryId) => api.get(`${baseUrlCotegory}/${categoryId}`)
export const getCategories = (searchItem) => api.get(`${baseUrlCotegory}?name=${searchItem}`)

export const createCategory = (category) => api.post(baseUrlCotegory, category)