
import * as types from "./types"
const InitialState = {
    loading: false,
    error: "",
    signUp: null
}
export default function name(state = InitialState, action) {
    switch (action.type) {
        case  types.SING_UP:
            return {...state, loading: true};
        case  types.SIGN_SUCCESS:
            return {...state, loading: false, signUp: action.payload};
        case  types.SIGN_FAILED:
            return {...state, loading: false, error: action.payload};
        default:
            return state
    }
}