import React from "react"
import { Row, Input } from "reactstrap"
import { orderBy } from "../../../uteis"
const Complemento = (props) => {
    return (
        <Row>
            <div className="col-md-12">
                <div className="form-group">
                    <label className="opacity-5 mb-1">GTIN/EAN</label>
                    <input
                        name="gtinEan"
                        type="text"
                        className="form-control"
                        value={props.gtinEan}
                        onChange={(e) => props.setGtinEan(e.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group">
                    <label className="opacity-5 mb-1">Código do Produto</label>
                    <input
                        name="codigo"
                        type="text"
                        className="form-control"
                        value={props.codigo}
                        onChange={(e) => props.setCodigo(e.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group">
                    <label className="opacity-5 mb-1">Unidade de medida</label>
                    <select
                        className="form-control"
                        name="unidadeMedida"
                        onChange={(e) => props.setUnidadeMedida(e.target.value)}
                        value={props.unidadeMedida}
                    >
                        <option >Selecione a unidade de medida</option>
                        {
                            props.unidadesMedidas && orderBy(props.unidadesMedidas, 'name_portuguese').map(unid => (
                                <option key={unid.id} value={unid.id}> {unid.name_portuguese} ({unid.acronym})</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </Row>
    )
}
export default Complemento