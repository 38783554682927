import React, {memo} from "react"
import ItemProduto from "../../../components/imProcurar/multiplos"
import Pagination from "../../../components/paginate/pagination"
const Result = ({dados, setarPosition, loading, resultados, params, pegaResultados})=>{
    return(
        <div className="result-container">

        <ul className="result-list">

            {
                dados && dados?.result?.data.length > 1 ? (
                    dados?.result.data?.map((item, index) => {
                        return <ItemProduto
                            key={index}
                            setarPosition={setarPosition}
                            item={{
                                tipo: item.tipo,
                                nome: item.name || item.company_name || '',
                                estrelas: item.avg,
                                visualizacoes: 234,
                                categoria: item.categoria || '',
                                descricao: item.description || '',
                                id: item.id,
                                ...item
                            }}
                        />
                    })
                ) : (    
                    <h2>Nenhum resultado...</h2>
                )
            }
        </ul>
        {
            loading && (
                <p className="text-center mb-5 mt-2"><i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando...</p>
            )
        }
        
        <div className="clearfix m-t-20">
            {dados?.result?.data?.length > 1 ?
            
            <Pagination
                firstPageUrl={dados.result?.first_page_url}
                lastPageUrl={dados.result?.last_page_url}
                nextPageUrl={dados.result?.next_page_url}
                previousPageUrl={dados.result?.prev_page_url}

                perPage={dados.result?.per_page}
                lastPage={dados.result?.last_page}
                currentPage={dados.result?.current_page}
                currentUrl={dados.result?.currentUrlProducts}
                path={dados.result?.path}

                {...resultados}

                params={params}

                getItems={pegaResultados}
            />: null
            }

        </div>
    </div>
    )
}

export default memo(Result)