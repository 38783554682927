import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import DateTime from 'react-datetime';

import moment from 'moment';
import 'moment/locale/pt-br';

import 'react-datetime/css/react-datetime.css';

const ModalHorario = ({modal, toggle, item, salvarItem, indice})=>{
    const [de, setDe] = useState("")
    const [ate, setAte] = useState("")
    const func = () => salvarItem()
    const f_setDe = (e) => {
        if (typeof e === 'object') {
            setDe(e.format("h:mm a"))
        }
    }
    const f_setAte = (e) => {
        if (typeof e === 'object') {
            setAte(e.format("h:mm a"))
        }
    }
    const f_salvarItem = () => salvarItem(indice, de, ate)
    
    return(
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>{item.nome}</ModalHeader>
            <ModalBody>
                <DateTime locale="pt-br" dateFormat={false} inputProps={{ placeholder: 'De' }} onChange={f_setDe}/>
                <DateTime locale="pt-br" dateFormat={false} inputProps={{ placeholder: 'Ate' }}  className="mt-2" onChange={f_setAte}/>
            </ModalBody>
            <ModalFooter>
                <Button onClick={salvarItem} color="primary" onClick={f_salvarItem}>Salvar</Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default ModalHorario