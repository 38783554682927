import React from "react";
import ReactTags from "react-tag-autocomplete";
import "react-datetime/css/react-datetime.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Row, Input } from "reactstrap";
import { phoneMask, celMask } from "../../../../uteis/mask";

function Contato({ empresa, setEmpresa }) {
    const { phone, financial_phone, youtube, email, website, whatsapp, instagram, facebook, linkedin, twitter } = empresa

    return (
        <>
            <Row>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Telefone comercial:</label>
                        <input
                            name="phone"
                            placeholder="(00) 0000-0000"
                            type="text"
                            className="form-control"
                            value={phone || ""}
                            maxLength={14}
                            onChange={(event) => {
                                setEmpresa({...empresa, phone: event.target.value.length < 10 ? event.target.value : phoneMask(event.target.value)})
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Telefone financeiro:</label>
                        <input
                            name="financial_phone"
                            placeholder="(00) 0000-0000"
                            type="text"
                            className="form-control"
                            value={financial_phone || ""}
                            maxLength={14}
                            onChange={(event) => {
                                setEmpresa({...empresa, financial_phone: event.target.value.length < 10 ? event.target.value : phoneMask(event.target.value)})
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Whatsapp:</label>
                        <input
                            name="whatsapp"
                            placeholder="(00) 00000-0000"
                            type="text"
                            className="form-control"
                            value={whatsapp || ""}
                            maxLength={15}
                            onChange={(event) => {
                                setEmpresa({...empresa, whatsapp: event.target.value.length < 11 ? event.target.value : celMask(event.target.value)})
                            }}
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">email</label>
                        <input
                            name="email"
                            placeholder="seuemail@mail.com"
                            type="text"
                            className="form-control"
                            value={email || ""}
                            onChange={(event) => setEmpresa({...empresa, email: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Site</label>
                        <input
                            name="website"
                            placeholder="Seu site"
                            type="text"
                            className="form-control"
                            value={website || ""}
                            onChange={(event) => setEmpresa({...empresa, website: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1"><i className="fab fa-youtube mr-1"></i>youtube</label>
                        <input
                            name="youtube"
                            placeholder="seu canal do youtube"
                            type="text"
                            className="form-control"
                            value={youtube || ""}
                            onChange={(event) => setEmpresa({...empresa, youtube: event.target.value})}
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-3">
                    <div className="form-group">
                        <label className="opacity-5 mb-1"><i className="fab fa-instagram mr-1"></i>Instagram</label>
                        <input
                            name="instagram"
                            placeholder="instagram.com/fulano"
                            type="text"
                            className="form-control"
                            value={instagram || ""}
                            onChange={(event) => setEmpresa({...empresa, instagram: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label className="opacity-5 mb-1"><i className="fab fa-facebook mr-1"></i> Facebook</label>
                        <input
                            name="facebook"
                            placeholder="facebook.com/fulano"
                            type="text"
                            className="form-control"
                            value={facebook || ""}
                            onChange={(event) => setEmpresa({...empresa, facebook: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label className="opacity-5 mb-1"><i className="fab fa-linkedin mr-1"></i>Linkedin</label>
                        <input
                            name="linkedin"
                            placeholder="linkedin.com/fulano"
                            type="text"
                            className="form-control"
                            value={linkedin || ""}
                            onChange={(event) => setEmpresa({...empresa, linkedin: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label className="opacity-5 mb-1"><i className="fab fa-twitter mr-1"></i>Twitter</label>
                        <input
                            name="twitter"
                            placeholder="twitter.com/fulano"
                            type="text"
                            className="form-control"
                            value={twitter || ""}
                            onChange={(event) => setEmpresa({...empresa, twitter: event.target.value})}
                        />
                    </div>
                </div>
            </Row>
        </>

    );
}

export default Contato;
