import * as type from "./types";

const initialState = {
  result: null,
  type: "produtos",
  currentPage: 1,
  loading: false,
  error: null,
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case type.SET_SEARCH_DATA_REQUESTED:
      return { ...state, loading: true };
    case type.SET_SEARCH_DATA_SUCCESSED:
      return { ...state, loading: false, result: action.payload };
    case type.SET_SEARCH_TYPE_PARAMS_SUCCESS:
      return { ...state, loading: false, type: action.payload };
    case type.SET_SEARCH_PAGE_PARAMS_SUCCESS:
      return { ...state, loading: false, currentPage: action.payload };
    case type.SET_SEARCH_DATA_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
