export const paises = [
    {
        name: "Estados Unidos",
        abbreviation: "USA",
        selected: false,
        flag: "us",
        open: false,
        states: [
            {
                "name": "Arizona",
                "abbreviation": "AZ",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "Arkansas",
                "abbreviation": "AR",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "California",
                "abbreviation": "CA",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "Colorado",
                "abbreviation": "CO",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "Connecticut",
                "abbreviation": "CT",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
        ]
    },

    {
        name: "Brasil",
        abbreviation: "BRA",
        selected: false,
        open: false,
        flag: "de",
        states: [
            {
                "name": "Arizona",
                "abbreviation": "AZ",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "Arkansas",
                "abbreviation": "AR",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "California",
                "abbreviation": "CA",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "Colorado",
                "abbreviation": "CO",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
            {
                "name": "Connecticut",
                "abbreviation": "CT",
                selected: true,
                cities: [
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS",
                        selected: true
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ",
                        selected: true
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR",
                        selected: true
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA",
                        selected: true
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO",
                        selected: true
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT",
                        selected: true
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE",
                        selected: true
                    },
                ]
            },
        ]
    },
]


