import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap"
import { URL_TO_IMAGES } from "../../config/api.config";
   
const ItemProcurar = ({ item, type, naoSeguir, getItems }) => {

    return (
        <li>
            <Link
                to={`/fornecedor/${item.company_id}`}
                className="result-image result-image-empresa"
                >

                    <img className="rounded-corner" src={item?.company_logo ? URL_TO_IMAGES+'/'+item?.company_logo.replace('public', '') : '/assets/img/gallery/gallery-52.jpg'} alt="Imagem do Logotipo" />
                </Link>
            <div className="result-info">
                <h4 className="title">
                    <Link to={`/fornecedor/${item.company_id}`}>
                        {item?.company_name}
                    </Link>
                </h4>
                <Badge className="">{type === "fornecedor" ? "fornecedor" : "cliente"}</Badge>

                <div className="btn-row">
                    <button className="btn btn-black btn-circle mt-3" onClick={() => naoSeguir(item.id, getItems)}>Remover</button>
                </div>
            </div>
            <div className="result-price">
                <Link to={`/fornecedor/${item.company_id}`} className="btn btn-yellow btn-block">
                    Ver perfil
                </Link>
            </div>
        </li>
    );
};

export default ItemProcurar;
