import React, { useState, useEffect, useContext } from "react";
import { store } from "react-notifications-component";

import Loader from "../../components/loader";
import ItemProcurar from "../../components/carteiras/cliente"
import { PageSettings } from "../../config/page-settings"
import { getCustomers, getSuppliers } from "../../services/portfolios";

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const MinhaCarteira = ({naoSeguir, hasChange, setHasChange}) => {
    const contextType = useContext(PageSettings);
    const [loading, setLoading] = useState(true);
    const [clientes, setClientes] = useState([]);

    const getClientes = async () => {
        setLoading(true)
        await getCustomers()
            .then(response => {
                if (response.data.resposta.conteudo.customers) {
                    setClientes(response.data.resposta.conteudo.customers)
                }
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        getClientes()
    }, []);

    useEffect(() => {
        if (hasChange) {
            getClientes()
            setHasChange(false)
        }
    }, [hasChange])

    return (
        <>
            <Loader loading={loading} />
            <div className="row">
                <div className="col-md-12">
                    <div className="result-container">
                        <ul className="result-list">
                            {
                                !clientes || clientes?.length === 0 ? (
                                    <h4 className="mt-3">Nenhum Cliente</h4>
                                ) : clientes.map(cliente => (
                                    <ItemProcurar
                                        key={cliente.id}
                                        type={'comprador'}
                                        item={cliente}
                                        naoSeguir={naoSeguir}
                                        getItems={getClientes}
                                    />
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MinhaCarteira;
