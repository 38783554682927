import * as type from "./types"

export const getUser =(user) =>{
    return{
        type: type.SET_USER_REQUESTED,
        payload: user
    }
}


export const getCompany = (company) =>{
    return{
        type: type.SET_EMPRESA_REQUESTED,
        payload: company
    }
}