import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import ContainerToForm from '../../../components/containerToForm';
import Loader from '../../../components/loader';
import TitlePage from "../../../components/pages/title"
import { permissionsRequests } from '../../../services/admin/permissions';

export default function Form() {
    const { id } = useParams();
    const history = useHistory();

    const [permission, setPermission] = useState({});
    const [loading, setLoading] = useState(false);

    const getPermission = async () => {
        await permissionsRequests.show(id)
            .then(response => {
                if (response?.data) {
                    const { permission } = response?.data?.resposta?.conteudo
                    console.log('permission', permission);
                    setPermission(permission)
                }
            })
            .finally(() => setLoading(false))
    };

    const handleSubmit = async event => {
        event.preventDefault()

        setLoading(true)
        
        const { name, description, status } = permission
        await permissionsRequests[id ? 'update' : 'create']({id, name, description, status})
            .then(response => {
                if (response?.data) {
                    const { message } = response?.data?.resposta?.conteudo
                    toast.success(
                        message || "Usuário atualizado com suceso!!!",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false
                        }
                    )
                    history.push('/usuarios')
                }
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.message) {
                    const { message } = error.response.data.resposta.conteudo
                    toast.error(
                        message || "Algo deu errado!!!",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false
                        }
                    )
                    return
                }
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (id) {
            getPermission()
        }
    }, [id])

    return (
        <>
            <ToastContainer />
            <Loader loading={loading} />

            <TitlePage
                title={`${id ? 'Edição' : 'Cadastro'} de Permissão`}
                icon="far fa-permission"
            />
            <ContainerToForm title={'Permissão'}>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="opacity-5 mb-1">Nome</label>
                                <input
                                    required
                                    name="name"
                                    placeholder="Nome"
                                    type="text"
                                    className="form-control"
                                    value={permission?.name || ""}
                                    onChange={(event) => setPermission({ ...permission, name: event.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="opacity-5 mb-1">Descrição</label>
                                <input
                                    required
                                    name="description"
                                    placeholder="Descrição"
                                    type="text"
                                    className="form-control"
                                    value={permission?.description || ""}
                                    onChange={(event) => setPermission({ ...permission, description: event.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="opacity-5 mb-1">Status</label>
                                <input
                                    name="status"
                                    placeholder="Status"
                                    type="text"
                                    className="form-control"
                                    value={permission?.status || ""}
                                    onChange={(event) => setPermission({ ...permission, status: event.target.value })}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Button
                            disabled={loading}
                            className="btn-rounded btn-lg bg-secondary"
                            type='submit'
                        >
                            {loading ? <i className="fas fa-spinner fa-spin text-inverse mr-1"></i> : null}
                            Salvar
                        </Button>
                    </Row>
                </form>
            </ContainerToForm>

        </>
    )
}