export const cpfMask = value => {
    if (!value) return
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = value => {
    if (!value) return
    return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
}

export const cepMask = value => {
    if (!value) return
    return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{3})?(\d{3})?/, "$1.$2-$3");
}

export const phoneMask = value => {
    if (!value) return
    return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{4})?(\d{4})?/, "($1) $2-$3");
}

export const celMask = value => {
    if (!value) return
    return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{5})?(\d{4})?/, "($1) $2-$3");
}

export const moneyMask = value => {
    if (!value) return

    let temp = value.toString().replace(/\D/g, '')
    temp = temp.replace(/([0-9]{2})$/g, ",$1");
    switch (temp.length) {
        case 7:
        case 8:
        case 9:
            temp = temp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
            break;
        case 10:
        case 11:
        case 12:
            temp = temp.replace(/([0-9]{3})([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");
            break;
        case 13:
        case 14:
        case 15:
            temp = temp.replace(/([0-9]{3})([0-9]{3})([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3,$4");
            break;
        default:
            break;
    }
    return "R$ "+temp
}

export const clearMask = value => value.toString().replace('R$ ', '').replace('.', '').replace(',', '')

// export const moneyMask = (value, includeZeros = false) => {
//     var tmp = value+'';

//     if (includeZeros) {
//         AddZerosRight(value)
//     }

//     tmp = tmp.replace('.', '');
//     tmp = tmp.replace(/([0-9]{2})$/g, tmp.length === 2 ? "0,$1" : ",$1");
//     if( tmp.length > 6 )
//             tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

//     return 'R$ '+tmp;
// }

// export const AddZerosRight = (value) => {
//     let decimalsRegex = /\.([0-9]{1,2})/;
//     let valor = parseFloat(value);
//     let result = decimalsRegex.exec(parseFloat(value));
//     // In case there is only one decimal
//     if (result && result[1].length < 2) {
//         valor = valor+'0'
//     } else if (!result) {
//         valor = valor+'00'
//     }
    
//     return valor
// }
