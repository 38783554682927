import * as type from "./types"

export const logIn = (email, senha, history) =>{
    return{
        type: type.LOG_IN_REQUESTED,
        payload: {email, senha, history}
    }
}

export  const logOut = (history) => {
    return{
        type: type.LOG_OUT_REQUESTED,
        payload: {history}
    }
}

export const getCompanie = (companieId) =>{
    return{
        type: type.COMPANIE_REQUESTED,
        payload: {companieId}
    }
}
