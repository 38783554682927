import React from 'react'

const SkeletonItem = () => {
    return (
        <li
            className="skeleton-item"
        >
            <div
                className="result-image skeleton-box"
                style={{
                    background: '#c1c1c1'
                }}
            >
            </div>
            <div className="result-info">
                <h4
                    className="title"
                    style={{
                        background: '#c1c1c1',
                        height: '25px',
                    }}
                >
                </h4>
                <div style={{
                    background: '#c1c1c1',
                    height: '13px',
                    width: '100px',
                    margin: '5px 0',
                    borderRadius: '50px'
                }}
                >
                </div>

                <div
                    className="desc"
                >
                    <p                    
                        style={{
                            background: '#c1c1c1',
                            height: '80px',
                        }}
                    ></p>
                </div>

                <div className="btn-row">
                    <a href="#"
                        style={{
                            width: '33px',
                            height: '33px',
                            background: '#c1c1c1c1'
                        }}
                    >
                    </a>
                    <a href="#"
                        style={{
                            width: '33px',
                            height: '33px',
                            background: '#c1c1c1c1'
                        }}
                    >
                    </a>

                </div>
            </div>
            <div className="result-price" style={{ zIndex: 4 }}>
                <div className="d-none d-sm-block align-self-center align-self-sm-end">
                    <div className="d-flex mb-2 justify-content-end">
                        <div className="btn btn-success btn-icon btn-circle btn-lg m-3"
                            style={{
                                height: '34px',
                                width: '34px',
                                background: '#c1c1c1',
                                borderRadius: '50px'
                            }}
                        ></div>
                        <div className="btn btn-success btn-icon btn-circle btn-lg m-3"
                            style={{
                                height: '34px',
                                width: '34px',
                                background: '#c1c1c1',
                                borderRadius: '50px'
                            }}
                        ></div>
                        <div className="btn btn-success btn-icon btn-circle btn-lg m-3"
                            style={{
                                height: '34px',
                                width: '34px',
                                background: '#c1c1c1',
                                borderRadius: '50px'
                            }}
                        ></div>
                    </div>
                </div>

                <a href="#"
                    className="btn btn-block"
                    style={{
                        height: '33px',
                        background: '#c1c1c1'
                    }}
                >
                </a>
            </div>
        </li>
    )
}

export default SkeletonItem