import {all} from "redux-saga/effects"
import auth from "./auth/saga"
import signUp from "./singUp/saga"
import search from "./pesquisa/saga"
export default function* rootSaga(){
    yield all([
        auth(),
        signUp(),
        search()
    ])
}