import React from 'react';
import ReactPlayer from 'react-player/youtube'
import { store } from 'react-notifications-component';
import {Row, Input, Button, Alert} from "reactstrap"
const VideoEmbed = (props) => {
    const avisar = (title, msg, type)=>{
        store.addNotification({
          title: title || "Usuário ou senha incorretos",
          message: msg || "Um código de verificação foi enviado para o seu email!",
          type: type || "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    console.log(props)
    return(
        <Row>
        <div className="col-md-12">
          <div className="form-group">
            <label className="opacity-5 mb-1"><i className="fab fa-youtube mr-1"></i>Link para o video</label>
            <Input type="text" placeholder="https://www.youtube.com/watch?v=FSA4ngDZAxQ" name="text" id="exampleText" value={props.url} onChange={(e)=> props.setURL(e.target.value) }/>

            {props.url && 
            <div className="mt-2 position-relative">
                <ReactPlayer width={"100%"} url={props.url} onError={()=>avisar("Video errro", "url fornecida é invalida", "danger")} />
                <Button className="btn btn-danger btn-sm btn-circle btn-lg m-3 btnDeletarFoto" onClick={()=>props.setURL("")}>
                  Remover <i className="fa fa-times"></i>
                </Button> 
            </div>
            }
            {props.hideButton ? null :  <button type="button" className="mt-3 btn btn-secondary" onClick={props.func_acao}>Proximo</button>}

          </div>
        </div>
      </Row>
    )
}

export default VideoEmbed