import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap"

import Loader from "../../components/loader";
import Comentarios from "../../components/comentarios/comentarioAvaliacao"
import { PageSettings } from "../../config/page-settings"
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import TitlePage from "../../components/pages/title"
import { useHistory } from "react-router-dom";
import { getCustomerEvaluations } from "../../services/evaluations";
import Pagination from "../../components/paginate/pagination";

const Avaliacoes = (props) => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const [setActiveTab] = useState("1");
  const [avaliacoes, setAvaliacoes] = useState({})
  const [params, setParams] = useState({order: 'desc', page: 1, per_page: 30})

  let history = useHistory();

  const pegaAvaliacoes = async (currentParams = {}) => {
    setLoading(true)
    window.scrollTo(0, 0)
    await getCustomerEvaluations({...params, ...currentParams})
      .then(response => {
        setAvaliacoes(response?.data?.resposta?.conteudo?.evaluations || {})
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    pegaAvaliacoes()

    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    return () => {
      contextType.handleSetPageContentFullHeight(false);
      contextType.handleSetPageContentFullWidth(false);
    }
  }, []);
  
  useEffect(() => {
    pegaAvaliacoes()
  }, [params])


  const toggleModalResponder = () => {
    history.push("/avaliacao/clientes/:id")
  }

  return (
    <>
      <Loader loading={loading} />
      <TitlePage icon="far fa-comments" title="avaliações de clientes" desc="Gerencie seus comentarios" />

      <Row>
        <Col sm={12}>
          <Panel theme="default">
            <PanelHeader>Todas as suas avaliaçōes

          </PanelHeader>
            <PanelBody>
              {/* <div className="form-group m-r-10 float-right d-none d-sm-block " >
                <select
                  className="form-control input-sm"
                  onChange={e => setParams({...params, order: e.target.value})}
                >
                  <option value="desc">Mais recente</option>
                  <option value="asc">Mais antiga</option>
                </select>
              </div> */}

              <ul className="media-list media-list-with-divider">
                {
                  Object.keys(avaliacoes).length > 0 && avaliacoes.data.map(avaliacao => {
                    const costomer_company = avaliacao?.user?.companies[0] || {}
                    return <Comentarios
                      key={avaliacao.id}
                      item={{...avaliacao, costomer_company}}
                      func={() => toggleModalResponder(false)}
                    />
                  })
                }
              </ul>

              <div className="clearfix m-t-20">
                <Pagination
                  firstPageUrl={avaliacoes.first_page_url}
                  lastPageUrl={avaliacoes.last_page_url}
                  nextPageUrl={avaliacoes.next_page_url}
                  previousPageUrl={avaliacoes.prev_page_url}

                  perPage={avaliacoes.per_page}
                  lastPage={avaliacoes.last_page}
                  currentPage={avaliacoes.current_page}
                  currentUrl={avaliacoes.currentUrlProducts}
                  path={avaliacoes.path}

                  {...avaliacoes}

                  getItems={pegaAvaliacoes}
                />
              </div>

            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>

  );
};

export default Avaliacoes;
