import React from 'react';
import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap";
const RecuperarSenha = (props) =>{


    return(
      (
        <>
              <>
                <InputGroup className={"input-group-focus"}>
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fal fa-envelope"></i>
                    </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email..." type="text" className="input-lg" 
                        onChange={(e)=> props.setEmail(e.target.value)}
                        >

                    </Input>
                </InputGroup>
                <Button
                    className="btn btn-lg bg-primary text-white btn-block mt-5"
                    color="primary"
                    onClick={props.f_email}
                    disabled={props.loading}
                    size="lg"
                  >
                    {props.loading ? "Carregando" : "Salvar" }
                </Button>
              </>
            

        </>
      )
    )
  }

  export default RecuperarSenha