import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input } from 'reactstrap';
import { store } from 'react-notifications-component';
import Comentario from "../../../components/comentarios/comentarioAvaliacao"
const ModalExample = ({modalProduto, toggleModal}) => {


  const enviarMensagem = ()=>{
    store.addNotification({
      title: "Mensagem salvar!",
      message: "Mensagem salva!!!",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 10000,
        onScreen: true
      }
    });
    toggleModal(false)
  }
  
  return (
    <>
      <Modal isOpen={modalProduto} toggle={toggleModal}  size="lg" centered>
        <ModalHeader toggle={toggleModal}> Editar avaliação</ModalHeader>
        <ModalBody>
              <label>nota:</label>
              <div className="form-group">
              <div className="rating mt-1 mb-1">
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star active"></span>
                  <span className="star"></span>
                  <span className="star"></span>
              </div>
                <Input
                  placeholder="Mensagem"
                  type="textarea"
                  className="form-control"
                />
              </div>

        </ModalBody>
        <ModalFooter>
          <Button block color="secondary" onClick={enviarMensagem}>Salvar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ModalExample;