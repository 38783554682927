import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../config/api.config";
import {getServiceVisits} from "../../services/service"
const btns = item => {
    return (
        <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
            <Link to={`/editar/servico/${item.servico?.id}?name=${item.servico?.name}&desc=${item.servico?.description}`} className="btn btn-success btn-icon btn-circle btn-lg m-3">
                <i className="fa fa-edit"></i>
            </Link>
            <Link to={`/cadastrar/servico/${item.servico?.id}?name=${item.servico?.name}&desc=${item.servico?.description}`} className="btn btn-warning btn-icon btn-circle btn-lg m-3">
                <i className="fa fa-clone"></i>
            </Link>
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    item.setDeletar(item.servico)
                }}
                className="btn btn-danger btn-icon btn-circle btn-lg m-3"
            >
                <i className="fa fa-times"></i>
            </Button>
        </div>
    )
}

const Servico = (props) => {
  const {servico} = props;
  const [loadingVisits, setLoadingVisits] = useState(false)
  const image = servico.images[0]?.path ? `${URL_TO_IMAGES}/${servico.images.find(img => img.featured_image === 1 || servico.images.length === 1)?.path.replace('public', '')}` : '/assets/img/default-product.svg'
  const [visits, setVisits] = useState(0)
  const getInfo = async(id)=>{
    if(!id) return null
    setLoadingVisits(true)
    const number = await getServiceVisits(id)
    console.log(id, number)
    setVisits(number)
    setLoadingVisits(false)

  }
  useEffect(()=>{
    // getInfo(servico.id)
    
  }, [servico.id])

  return (
    <li>
    <div
      to={`/servico/${servico.id}`}
      className="result-image"
      style={{
        backgroundImage: `url( ${image || "/assets/img/default-product.svg"} )`,
      }}
    >

    <div className="p-3 d-sm-none" style={{zIndex:4}}>
      { props.isUser ? btns(props) : null}
    </div>

    </div>
    <div className="result-info">
      <h4 className="title">
        <Link to={`/servico/${servico.id}`}>
          {servico.name}
        </Link>
      </h4>
      <span className="badge badge-secondary">{servico.category.name}</span>
      <div className="desc" dangerouslySetInnerHTML={{__html: servico.description}}>
      </div>
      <div className="btn-row">        
        {
          servico.avg && (
            <Link
              to={`/servico/${servico.id}`}
              data-toggle="tooltip"
              data-container="body"
              data-title="Analytics"
            >
              <>
                <i className="far fa-star"></i> {servico.avg}
              </>
            </Link>
          )
        }
        <Link
          to={`/servico/${servico.id}`}
          data-toggle="tooltip"
          data-container="body"
          data-title="Tasks"
        >
          {/* <i className="far fa-eye"></i> {loadingVisits ? <i className="fas fa-spinner fa-spin"></i>: visits || 0}  */}
          <i className="far fa-eye"></i> {servico?.hit || 0} 
        </Link>

      </div>
    </div>
    <div className="result-price" style={{zIndex:4}}>
      <div className="d-none d-sm-block align-self-center align-self-sm-end"> 
        { props.isUser ? btns(props) : null}
      </div>

      <Link to={`/servico/${servico.id}`} className="btn btn-yellow btn-block">
        Ver Serviço
      </Link>
    </div>
  </li>
  );
};

export default Servico;
