import React from "react";

import PageDefault from "../pageDefault";
import { rolesRequests } from "../../../services/admin/roles";

export default function Permissions() {
  return (
    <PageDefault
      title={"Perfil"}
      titlePlural={"Perfis"}
      type="roles"
      dataRequest={rolesRequests}
      baseUrl={"perfis"}
      columnsTable={[
        { key: "name", name: "Nome" },
        { key: "description", name: "Descrição", hide: 'md' },
        {
          key: "status",
          name: "Status",
          hide: 'sm'
        },
      ]}
      tiposDeBusca={[{ key: "name", label: "Nome" }]}  
    />
  );
}