import React, { useState, useEffect, useContext } from "react";
import Loader   from "../../components/loader";
import ModalFiltro from "./parts/modalFiltroServico"
import {
  InputGroup,
  Input,
  InputGroupButtonDropdown,
  Button,
} from "reactstrap";
import {PageSettings} from "../../config/page-settings"
import ItemProduto    from "../../components/produto"
import Pagination     from "./parts/pagination"
import Breadcrumb     from "../../components/breakcumbs"
import TitlePage      from "../../components/pages/title"


// cadastrar categoria
const listaProdutos = [
  {
    nome: "produto 1",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "312213",
  },
  {
    nome: "produto 3",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "242345",
  },
  {
    nome: "produto 3",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "12312",
  },
  {
    nome: "produto 4",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "423423",
  },
]

const Home = ( ) => {
  const [loading, setLoading] = useState(true);
  const [modalFiltro, setModalFiltro] = useState(false)
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const contextType = useContext(PageSettings);
  const [deletar, setDeletar] = useState(false)
  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const confirmar = ()=>{
    console.log("confirmar")
    setDeletar(false)
  }
  const deletarItem = ()=>{
    console.log("confirmar")
    setDeletar(false)
  }

  const toggleModalFiltro = () => {
    setModalFiltro(!modalFiltro)
  }
  return (
    <>

      <Loader loading={loading} />

      
      <Breadcrumb list={[{name: "Home", path: "/"} ,{name: "Procurar produto", path: "/cadastroEmpresa"}]} />
      <TitlePage title="Procurar produto" desc="Pesquise por nome, setor de atividade" icon="fab fa-simplybuilt" />
      <div className="row">
      <ModalFiltro modal={modalFiltro} toggle={toggleModalFiltro} />
        <div className="col-md-12">
          <div className="result-container">
            <InputGroup size="lg" className="m-b-20">
              <Input
                placeholder="Pesquise o seu produto..."
                type="text"
                className="input-white"
              />
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={splitButtonOpen}
                toggle={toggleSplit}
              >
                <Button color="success" >
                <i className="fa fa-search fa-fw"></i>
                </Button>
              </InputGroupButtonDropdown>
            </InputGroup>
            <button className="btn btn-yellow mb-3" onClick={toggleModalFiltro}>Pesquisa Avançada</button>
            <ul className="result-list">
              {
                listaProdutos.map((produto =>{
                  return <ItemProduto isUser={false} servico={false} type="" key={Math.random()}  produto={produto} setDeletar={setDeletar}/>
                }))
              }
            </ul>
            <div className="clearfix m-t-20">
              <Pagination />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
