import React, { useEffect, useState } from "react"
import{Row, Col, Container} from "reactstrap"
import product from "./product.png"
import {Link} from "react-router-dom"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TitlePage      from "../../../components/pages/title"
import ReactPlayer from "react-player";
import { URL_TO_IMAGES } from "../../../config/api.config";
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const Produto = (props) => {
    const [imagens, setImagens] = useState([])
    const { produto, type } = props

    const stringProduto = `${produto.code ? 'Código : '+ produto.code +' | ': ''}
        ${produto.gtin_ean ? 'GTIN/EAN: '+produto.gtin_ean + ' | ' : ''}
        ${produto.category?.name ? 'Categoria: '+produto.category?.name+' |' : ''}
        ${produto.brand?.name ? 'Marca: '+produto.brand.name : ''}`
    
    const stringServico = `${produto.code ? 'Código : '+ produto.code +' | ': ''}
        ${produto.category?.name ? 'Categoria: '+produto.category?.name+' |' : ''}`

    const infoTopProduto = props.type !== 'servico' ? stringProduto : stringServico

    useEffect(() => {
        const arrImages = []
        if (produto?.images) {
            arrImages.push(...produto.images)
        }
        if (produto?.video_link) {
            arrImages.push(produto.video_link)
        }
        setImagens(arrImages)
    }, [produto])


    // imagem descricao
    return (
      <Container fluid={true} className="product  bg-white " >
        <Row>
            <Col>
                <h1 className="page-header mb-2">
                    {produto.name} <br />
                    <small>
                        {infoTopProduto}
                    </small>
                </h1>
            </Col>
        </Row>
        <Row>
            <Col className="" md={8} lg={6}>
                <Carousel responsive={responsive} >
                    {
                        imagens ? (
                            imagens.map(image => {
                                if (typeof image !== 'string') {
                                    return <div key={image.id} className="product-image mt-2">
                                        <div className="product-main-image type-img" data-id="main-image">
                                            <img
                                                src={`${URL_TO_IMAGES}/${image.path.replace('public', '')}`}
                                                alt={`Foto do Produto: ${produto.name}`}
                                                className="img-fluid m-auto"
                                            />
                                        </div>
                                    </div>
                                }
                                return <div key={image} className="product-image mt-2">
                                    <div className="embed-responsive embed-responsive-21by9 type-img">
                                        <ReactPlayer width={"100%"} url={image} onError={() => console.log("Video errro", "url fornecida é invalida", "danger")} />
                                    </div>
                                </div>
                            })
                        ) : (
                                <div className="product-image mt-2">
                                    <div
                                        style={{
                                            backgroundImage: `url(/assets/img/default-product.svg)`,
                                            width: '100%',
                                            height: '300px',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                    </div>
                                </div>
                            )
                    }
                </Carousel>
            </Col>
            <Col className="d-flex align-items-center" md={4} lg={6}>
                <div className="product-info product-pd mt-3">
                    <div className="product-info-header ">
                        <h4>Descricão:</h4>
                        
                        <div dangerouslySetInnerHTML={{__html: produto.description}} style={{
                            maxHeight: '400px',
                            overflow: 'auto',
                            marginBottom: '1rem'
                        }}></div>
                        {
                            produto?.unit_measurement && (
                                <p><strong>Unidade de medida: </strong> {produto?.unit_measurement?.name_portuguese} ({produto?.unit_measurement?.acronym})</p>
                            )
                        }
                            <div className="rating mt-2 mb-3">
                                <p className="mr-2 text-orange">Nota: {produto.avg}</p>
                                {
                                    [1, 2, 3, 4, 5].map(item => (
                                        <span
                                            key={item}
                                            className={`star ${item <= (produto.avg || 0) && 'active'}`}
                                        ></span>
                                    ))
                                }
                            </div>

                        {
                            type !== 'servico' && (
                                <p className="mt-4">
                                    <span className="hidden-print" onClick={()=> props.openModal(true)}>
                                        <Link
                                            to={`/cotacao/cotar/${parseInt(produto.company_id)}?produto=${produto.id}`}
                                            className="btn btn-lg btn-orange btn-circle"
                                        >
                                            <i className="fa fa-comment-dots t-plus-1 fa-fw fa-lg"></i> Cotar
                                        </Link>
                                    </span>
                                </p>
                            )
                        }
                    </div>
                </div>
            </Col>
            </Row>        
        </Container>


    )

};

export default Produto


