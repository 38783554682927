import React from "react";
import { Link } from "react-router-dom";
import { Badge, NavItem } from "reactstrap";
import { cepMask } from "../../uteis/mask";

const escolherTipo = (tipo, item, setarPosition) => {
  switch (tipo) {
    case "produto":
      return (
        <li>
          <Link
            onClick={() => setarPosition()}
            to={`/produtos/${item.id}`}
            className="result-image"
            style={{
              backgroundImage: `url(${
                item.imagem || "/assets/img/gallery/gallery-52.jpg"
              })`,
            }}
          ></Link>
          <div className="result-info">
            <h4 className="title">
              <Link onClick={() => setarPosition()} to={`/produtos/${item.id}`}>
                {item.nome}{" "}
                <small className="text-muted">
                  Categoria: {item?.category?.name} | Marca: {item?.brand?.name}
                </small>
              </Link>
            </h4>
            <p className="location">
              <Badge className="bg-orange">{"Produto"}</Badge>
            </p>

            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.descricao }}
            ></div>

            <div className="btn-row">
              {item.estrelas && (
                <Link
                  onClick={() => setarPosition()}
                  to={`/produtos/${item.id}`}
                  data-toggle="tooltip"
                  data-container="body"
                  data-title="Analytics"
                >
                  <>
                    <i className="far fa-star"></i> {item.estrelas}
                  </>
                </Link>
              )}
              <Link
                onClick={() => setarPosition()}
                to={`/produtos/${item.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Tasks"
              >
                <i className="far fa-eye"></i> {item.hit || 0}
              </Link>
            </div>
            {item?.company && (
              <p className="text-muted mt-3">
                Empresa:
                <Link
                  onClick={() => setarPosition()}
                  to={`/fornecedor/${item.company?.id}`}
                  className="ml-2"
                >
                  {item.company?.company_name ? item.company.company_name : ""}
                </Link>
                <br />
                {item.address ||
                item.neighborhood ||
                item.complement ||
                item.city ||
                item.postal_code ? (
                  <>
                    Endereço:
                    {`
                                        ${
                                          item.address
                                            ? item.address + ", "
                                            : ""
                                        }
                                        ${
                                          item.neighborhood
                                            ? item.neighborhood + ", "
                                            : ""
                                        }
                                        ${
                                          item.complement ? item.complement : ""
                                        }
                                        ${
                                          item.city
                                            ? " - " + item?.city?.name + "/"
                                            : ""
                                        }${
                      item.uf
                        ? item?.uf?.sigla_uf + (item.postal_code ? ", " : "")
                        : ""
                    }
                                        ${
                                          item.postal_code
                                            ? cepMask(item.postal_code)
                                            : ""
                                        }
                                    `}
                  </>
                ) : null}
              </p>
            )}
          </div>
          <div className="result-price">
            <Link
              onClick={() => setarPosition()}
              to={`/produtos/${item.id}`}
              className="btn btn-yellow btn-block"
            >
              Ver produto
            </Link>
          </div>
        </li>
      );
    case "servico":
      return (
        <li>
          <Link
            onClick={() => setarPosition()}
            to={`/servico/${item.id}`}
            className="result-image"
            style={{
              backgroundImage: `url(${
                item.imagem || "/assets/img/gallery/gallery-52.jpg"
              })`,
            }}
          ></Link>
          <div className="result-info">
            <h4 className="title">
              <Link to={`/servico/${item.id}`}>
                {item.nome}{" "}
                <small className="text-muted">
                  Categoria: {item?.category?.name || ""}
                </small>
              </Link>
            </h4>
            <p className="location">
              <Badge className="bg-green">
                {parseInt(item.profile) === 1 ? "Produto" : "Serviço"}
              </Badge>
            </p>

            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.descricao }}
            ></div>

            <div className="btn-row">
              {item.estrelas && (
                <Link
                  to={`/servico/${item.id}`}
                  data-toggle="tooltip"
                  data-container="body"
                  data-title="Analytics"
                >
                  <>
                    <i className="far fa-star"></i> {item.estrelas}
                  </>
                </Link>
              )}
              <Link
                to={`/servico/${item.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Tasks"
              >
                <i className="far fa-eye"></i> {item.hit || 0}
              </Link>
            </div>

            {item?.company && (
              <p className="text-muted mt-3">
                Empresa:
                <Link to={`/fornecedor/${item.company?.id}`} className="ml-2">
                  {item.company?.company_name ? item.company.company_name : ""}
                </Link>
                <br />
                {item.address ||
                item.neighborhood ||
                item.complement ||
                item.city ||
                item.postal_code ? (
                  <>
                    Endereço:
                    {`
                                        ${
                                          item.address
                                            ? item.address + ", "
                                            : ""
                                        }
                                        ${
                                          item.neighborhood
                                            ? item.neighborhood + ", "
                                            : ""
                                        }
                                        ${
                                          item.complement ? item.complement : ""
                                        }
                                        ${
                                          item.city
                                            ? " - " + item?.city?.name + "/"
                                            : ""
                                        }${
                      item.uf
                        ? item?.uf?.sigla_uf + (item.postal_code ? ", " : "")
                        : ""
                    }
                                        ${
                                          item.postal_code
                                            ? cepMask(item.postal_code)
                                            : ""
                                        }
                                    `}
                  </>
                ) : null}
              </p>
            )}
          </div>
          <div className="result-price">
            <Link
              to={`/servico/${item.id}`}
              className="btn btn-yellow btn-block"
            >
              Ver serviço
            </Link>
          </div>
        </li>
      );
    case "empresa":
      return (
        <li>
          <Link
            onClick={() => setarPosition()}
            to={`/fornecedor/${item.id}`}
            className="result-image result-image-empresa"
          >
            <img
              className="rounded-corner"
              src={item.imagem || "/assets/img/gallery/gallery-52.jpg"}
              alt="Imagem do Logotipo"
            />
          </Link>
          <div className="result-info">
            <h4 className="title">
              <Link
                onClick={() => setarPosition()}
                to={`/fornecedor/${item.id}`}
              >
                {item.nome || item.fantasy_name}
              </Link>
            </h4>
            {/* <p className="location"><Badge>{parseInt(item.profile) === 1 ? "fornecedor" : "cliente"}</Badge></p> */}
            <p className="location">
              <Badge>
                {parseInt(item.profile) === 1 ? "fornecedor" : "cliente"}
              </Badge>
            </p>

            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.descricao }}
            ></div>
            {parseInt(item.profile) === 1 && (
              <p className="m-r-15 text-inverse-lighter">
                <i className="fa fa-boxes fa-fw fa-lg m-r-3"></i>Produtos:{" "}
                {item.products_count || 0} |{" "}
                <i className="fa fa-people-carry fa-fw fa-lg m-r-3"></i>
                Serviços: {item.services_count || 0}
              </p>
            )}
            <div className="btn-row">
              {parseInt(item.profile) === 1 && item.estrelas && (
                <Link
                  onClick={() => setarPosition()}
                  to={`/fornecedor/${item.id}`}
                  data-toggle="tooltip"
                  data-container="body"
                  data-title="Analytics"
                >
                  <i className="far fa-star"></i> {item.estrelas}
                </Link>
              )}
              <Link
                onClick={() => setarPosition()}
                to={`/fornecedor/${item.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Tasks"
              >
                <i className="far fa-eye"></i> {item.hit || 0}
              </Link>
            </div>
            <p className="text-muted mt-3">
              {item.address ||
              item.neighborhood ||
              item.complement ||
              item.city ||
              item.postal_code ? (
                <>
                  Endereço:
                  {`
                                ${item.address ? item.address + ", " : ""}
                                ${
                                  item.neighborhood
                                    ? item.neighborhood + ", "
                                    : ""
                                }
                                ${item.complement ? item.complement : ""}
                                ${
                                  item.city
                                    ? " - " + item?.city?.name + "/"
                                    : ""
                                }${
                    item.uf
                      ? item?.uf?.sigla_uf + (item.postal_code ? ", " : "")
                      : ""
                  }
                                ${
                                  item.postal_code
                                    ? cepMask(item.postal_code)
                                    : ""
                                }
                            `}
                </>
              ) : null}
            </p>
          </div>
          <div className="result-price">
            <Link
              onClick={() => setarPosition()}
              to={`/fornecedor/${item.id}`}
              className="btn btn-yellow btn-block"
            >
              Ver perfil
            </Link>
          </div>
        </li>
      );
    case "cliente":
      return (
        <li>
          <Link
            onClick={() => setarPosition()}
            to={`/fornecedor/${item.id}`}
            className="result-image"
            style={{
              backgroundImage: "url(/assets/img/gallery/gallery-52.jpg)",
            }}
          ></Link>
          <div className="result-info">
            <h4 className="title">
              <Link
                onClick={() => setarPosition()}
                to={`/fornecedor/${item.id}`}
              >
                {item.nome}
              </Link>
            </h4>
            <p className="text-muted">Atividade economica: texto</p>
            <p className="location">
              <Badge className="bg-dark">{"cliente"}</Badge>
            </p>

            <div
              className="desc"
              dangerouslySetInnerHTML={{ __html: item.descricao }}
            ></div>

            <div className="btn-row">
              <Link
                onClick={() => setarPosition()}
                to={`/fornecedor/${item.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Tasks"
              >
                <i className="far fa-eye"></i> {item.hit || 0}
              </Link>
            </div>
            <p className="text-muted mt-3">
              Local: Rua fulano de tal, Vila de Tal - SP
            </p>
          </div>
          <div className="result-price">
            <Link
              onClick={() => setarPosition()}
              to={`/fornecedor/${item.id}`}
              className="btn btn-yellow btn-block"
            >
              Ver perfil
            </Link>
          </div>
        </li>
      );
    default:
      return null;
  }
};
const ItemProcurar = ({ item, isFornecedor, setarPosition }) => {
  if (!item) return null;
  return escolherTipo(item.tipo, item, setarPosition);
};

export default ItemProcurar;
