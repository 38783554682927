import React, { memo } from "react";
import ProdutoItem from "./productItem";
import Pagination from "../../../components/paginate/pagination";
const Result = ({
  dados,
  loading,
  params,
  pegaResultados,
  setDeletar,
  setBloquear
}) => {

  return (
    <div className="result-container">
      <ul className="result-list">
        {dados && dados?.data.length > 0 ? (
          dados?.data?.map((item, index) => {
            return (
              <ProdutoItem
                key={index}
                isUser={true}
                produto={item}
                setDeletar={setDeletar}
                setBloquear={setBloquear}
              />
            );
          })
        ) : (
          <h2>Nenhum resultado...</h2>
        )}
      </ul>
      {loading && (
        <p className="text-center mb-5 mt-2">
          <i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando...
        </p>
      )}

      <div className="clearfix m-t-20">
        {dados?.data.length > 0 ? (
          <Pagination
            {...dados}
            firstPageUrl={dados?.first_page_url}
            lastPageUrl={dados?.last_page_url}
            nextPageUrl={dados?.next_page_url}
            previousPageUrl={dados?.prev_page_url}
            perPage={dados?.per_page}
            lastPage={dados?.last_page}
            currentPage={dados?.current_page}
            currentUrl={dados?.currentUrlProducts}
            path={dados?.path}
            params={params}
            getItems={pegaResultados}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(Result);
