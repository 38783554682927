import React, { useEffect, useState } from "react"
import { Tooltip, Input, Label, FormGroup } from "reactstrap"
import { convertIntToFloadDot } from "../../uteis"
import { clearMask, moneyMask } from "../../uteis/mask"

const Frete = ({ frete, setFrete, tooltipOpen, toggle, toggleFOB, tooltipOpenFOB, disabled }) => {
    const [tipoFrete, setTipoFrete] = useState("")
    const [valor, setValor] = useState(0)

    const [showFrete, setShowFrete] = useState(false)

    const handleTipoFrete = item => {
        const fieldCIF = document.getElementById('cif')
        const fieldFOB = document.getElementById('fob')
        setShowFrete(fieldCIF.checked)
        const checks = fieldCIF.checked && fieldFOB.checked ? "CIF/FOB" : fieldCIF.checked && !fieldFOB.checked ? "CIF" : !fieldCIF.checked && fieldFOB.checked ? "FOB" : ""

        setTipoFrete(checks)
    }
    useEffect(() => {
        setFrete({
            freight: tipoFrete,
            cost_of_freight: convertIntToFloadDot(clearMask(valor))
        })
    }, [tipoFrete, valor])

    useEffect(() => {
        if (Object.keys(frete).length > 0) {
            // setValor(frete?.cost_of_freight)
        }
    }, [frete])

    return <>
        <div className="invoice-price-left">
            <div className="invoice-price-row">
                <div className="sub-price">
                    <small>Tipo do frete </small>

                    <FormGroup row>
                        <FormGroup check>
                            <Label id="TooltipExample">
                                <Input
                                    id="cif"
                                    value="CIF"
                                    onChange={e => handleTipoFrete(e.target)}
                                    type="checkbox"
                                    name="CIF"
                                    className="mt-1"
                                    disabled={disabled}
                                    checked={frete?.freight?.includes('CIF') || frete?.freight?.includes('CIF/FOB')}
                                />
                                CIF
                                <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
                                    CIF: a responsabilidade do embarcador vai até a entrega das mercadorias em seu destino.
                                </Tooltip>
                            </Label>
                        </FormGroup>
                        <FormGroup check className="ml-2">
                            <Label id="TooltipExampleFob">
                                <Input
                                    id="fob"
                                    value="FOB"
                                    onChange={e => handleTipoFrete(e.target)}
                                    type="checkbox"
                                    name="FOB"
                                    disabled={disabled}
                                    checked={frete?.freight?.includes('FOB') || frete?.freight?.includes('CIF/FOB')}
                                />
                                <Tooltip placement="bottom" isOpen={tooltipOpenFOB} target="TooltipExampleFob" toggle={toggleFOB}>
                                    FOB: a responsabilidade do embarcador termina no despacho das mercadorias.
                                </Tooltip>
                                FOB
                            </Label>
                        </FormGroup>

                    </FormGroup>
                </div>
                {
                    disabled && (frete?.freight?.includes('CIF') || frete?.freight?.includes('CIF/FOB')) && (
                        <div className="sub-price">
                            <FormGroup>
                                <small>Valor do frete </small>
                                {/* <Input
                                    type="number"
                                    onChange={e => setValor(e.target.value)}
                                    disabled={disabled}
                                    name="number"
                                    placeholder={frete?.cost_of_freight || "Valor frete"}
                                /> */}
                                <Input
                                    disabled={disabled}
                                    type="text"
                                    placeholder={moneyMask(frete?.cost_of_freight) || "Valor frete"}
                                    value={moneyMask(valor || '')}
                                    onChange={(e) => {
                                        setValor(e.target.value)
                                    }}
                                />
                            </FormGroup>
                        </div>
                    )
                }
                {
                    showFrete && (
                        <div className="sub-price">
                            <FormGroup>
                                <small>Valor do frete </small>
                                <Input
                                    disabled={disabled}
                                    type="text"
                                    placeholder={moneyMask(frete?.cost_of_freight) || "Valor frete"}
                                    value={moneyMask(valor || '')}
                                    onChange={(e) => {
                                        setValor(e.target.value)
                                    }}
                                />
                            </FormGroup>
                        </div>
                    )
                }

            </div>
        </div>
    </>
}

export default Frete