import React, { useState, useEffect, useContext } from "react"
import Loader from "../../components/loader"
import Campos from "./cadastrar/novoProduto"

import { PageSettings } from "../../config/page-settings"

import Breadcrumb from "../../components/breakcumbs"
import TitlePage from "../../components/pages/title"
import { getProduct } from "../../services/product"

// cadastrar categoria


const Copiar = () => {
    const [loading, setLoading] = useState(true);
    const contextType = useContext(PageSettings);
    const [modalProduto, toggleModal] = useState(false)
    const [name, setName] = useState("")
    const [desc, setDesc] = useState("")
    const [produto, setProduto] = useState(null)

    const getProduto = async () => {

        const produtoId = window.location.pathname.split('/').pop()

        await getProduct(produtoId)
            .then(response => {
                const resProd = response.data.resposta.conteudo.product
                setProduto(resProd)
            })
            .catch(error => {
                // if (error && error.response?.data) {
                //     const messages = error.response.data.resposta.conteudo.messages
                //     Object.keys(messages).forEach(key => {
                //         messages[key].forEach(message => {
                //             avisar("Ops!!", message, "danger")
                //         })
                //     })
                //     return
                // }

                // avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        const URLParams = new URLSearchParams(window.location.search)
        setName(URLParams.get('name'))
        setDesc(URLParams.get('desc'))

        getProduto()

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const toggleModalProduto = () => {
        toggleModal(!modalProduto)
    }

    return (
        produto && (
            <>
                <Loader loading={loading} />
                <Breadcrumb list={[{ name: "Home", path: "/" }, { name: "produtos", path: "/catalogo/produtos" }]} />
                <TitlePage title={`Copiar Produto: ${name}`} icon="fal fa-box" />
                <Campos
                    modalProduto={modalProduto}
                    toggleModal={toggleModalProduto}
                    setLoading={setLoading}

                    isCopy={true}

                    produto={produto}
                />
            </>
        )
    );
};

export default Copiar;
