import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Row } from "reactstrap";
import ContainerToForm from "../../../components/containerToForm";
import Loader from "../../../components/loader";
import TitlePage from "../../../components/pages/title";
import { rolesRequests } from "../../../services/admin/roles";
import { usersRequests } from "../../../services/admin/users";
import { cpfMask } from "../../../uteis/mask";

export default function Form() {
  const { id } = useParams();
  const history = useHistory();

  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUser = async () => {
    await usersRequests
      .show(id)
      .then((response) => {
        if (response?.data) {
          const { user } = response?.data?.resposta?.conteudo;
          setUser(user);
        }
      })
      .finally(() => setLoading(false));
  };

  const getRoles = async () => {
    await rolesRequests
      .list()
      .then((response) => {
        if (response?.data) {
          const { roles } = response?.data?.resposta?.conteudo;
          setRoles(roles.data);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitRoleToUser = async (roleId, remove = false) => {
    const endpointAPI = remove
      ? "deleteRoleToUser"
      : "addRoleToUser";
    await rolesRequests[endpointAPI]({
      user_id: id,
      role_id: roleId,
    })
      .then((response) => {
        if (response?.data) {
          const { message } = response?.data?.resposta?.conteudo;
          toast.success(
            message || "Perfil adicionado ao usuário com sucesso.",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            }
          );
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.message) {
          const { message } = error.response.data.resposta.conteudo;
          toast.error(message || "Algo deu errado!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
          return;
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const { name, email, cpf, password, roles } = user;
    await usersRequests[id ? "update" : "create"]({
      id,
      name,
      email,
      cpf,
      password,
    })
      .then((response) => {
        if (response?.data) {
          const { message, user } = response?.data?.resposta?.conteudo;

          toast.success(message || "Usuário atualizado com suceso!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
          history.push("/usuarios");
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.message) {
          const { message } = error.response.data.resposta.conteudo;
          toast.error(message || "Algo deu errado!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
          return;
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) {
      getUser();
    }
    getRoles();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <Loader loading={loading} />

      <TitlePage
        title={`${id ? "Edição" : "Cadastro"} de Usuário`}
        desc="Todos os campos são obrigatórios."
        icon="far fa-user"
      />

      <ContainerToForm title="Usuário">

        <form onSubmit={handleSubmit}>
          <Row>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Nome</label>
                <input
                  required
                  name="name"
                  placeholder="Nome"
                  type="text"
                  className="form-control"
                  value={user?.name || ""}
                  onChange={(event) =>
                    setUser({ ...user, name: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">E-mail</label>
                <input
                  required
                  name="email"
                  placeholder="E-mail"
                  type="email"
                  className="form-control"
                  value={user?.email || ""}
                  onChange={(event) =>
                    setUser({ ...user, email: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="opacity-5 mb-1">CPF</label>
                <input
                  required={!id}
                  disabled={id}
                  name="cpf"
                  placeholder="CPF"
                  type="text"
                  className="form-control"
                  value={cpfMask(user?.cpf) || ""}
                  onChange={(event) =>
                    setUser({ ...user, cpf: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="opacity-5 mb-1">Senha</label>
                <input
                  required
                  name="password"
                  placeholder="Senha"
                  type="password"
                  className="form-control"
                  value={user?.password || ""}
                  onChange={(event) =>
                    setUser({ ...user, password: event.target.value })
                  }
                />
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12">
              <h5>Perfis</h5>
            </div>
            {roles &&
              roles.map((role) => (
                <div key={role.id} className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={user?.roles?.find((rol) => +rol.id === +role.id)}
                      value={role.id}
                      id={role.id}
                      onChange={(event) => {
                        const userRoles = user?.roles || [];
                        let newsRolesToUser = [...userRoles, +event.target.value];
                        if (
                          userRoles.find((role) => role === +event.target.value)
                        ) {
                          handleSubmitRoleToUser(+event.target.value, true);
                          newsRolesToUser = newsRolesToUser.filter(
                            (role) => role !== +event.target.value
                          );
                        } else {
                          handleSubmitRoleToUser(+event.target.value, false);
                        }
                        setUser({ ...user, roles: newsRolesToUser });
                      }}
                    />
                    <label className="form-check-label" htmlFor={role.id}>
                      <strong>{role.name}</strong>{" "}
                      <small>- {role.description}</small>
                    </label>
                  </div>
                </div>
              ))}
          </Row>
          <Row>
            <Button
              disabled={loading}
              className="btn-rounded btn-lg bg-secondary mt-3 pl-4 pr-4"
              type="submit"
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin text-inverse mr-1"></i>
              ) : null}
              Salvar
            </Button>
          </Row>
        </form>

      </ContainerToForm>

    </>
  );
}
