import React from 'react';

import { companiesRequests } from '../../../services/admin/companies';
import PageDefault from '../pageDefault';
import { cnpjMask } from '../../../uteis/mask'

export default function Suppliers() {
    return (
      <PageDefault
        title={"Fornecedor"}
        titlePlural={"Fornecedores"}
        type="companies"
        dataRequest={companiesRequests}
        baseUrl={"fornecedores"}
        columnsTable={[
          { key: "company_name", name: "Nome" },
          { key: "fantasy_name", name: "Nome Fantasia", hide: "sm" },
          { key: "cnpj", name: "CNPJ", hide: "md", render: cell => cell && cnpjMask(cell) },
        ]}
        params={{type: 1}}
        withoutNew
        tiposDeBusca={[
          {key: 'name', label: 'Nome'},
          {key: 'cnpj', label: 'CNPJ'},
          {key: 'email', label: 'E-mail'},
        ]}
        hasDelete={false}
      />
    )
}