import React, { useEffect, useState } from "react"
import moment from "moment"
import { toast, ToastContainer } from "react-toastify";
import { quotaionsRequests } from "../../../services/admin/quotaions"
import Loader from "../../../components/loader"
import TitlePage from "../../../components/pages/title"
import ItemCotacao from "../../cotacao/Item"
import { cnpjMask } from "../../../uteis/mask"
import Pagination from "../../../components/paginate/pagination"
import SearchFor from "../../../components/busca/SearchFor"
import { Button, Col, Input, InputGroup, InputGroupButtonDropdown, Label, Row } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

export default function Users() {
  const [itemDelete, setItemDelete] = useState(null)
  const [quotaions, setQuotaions] = useState({})
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    order: 'desc',
    per_page: 30,
  })
  const [filterDate, setFilterDate] = useState({
    dateInitial: '',
    dateFinal: '',
  })
  const [searchFor, setSearchFor] = useState("name");
  const [keyword, setKeyword] = useState("");
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);

  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };

  const handleQuotations = async (currentParams = {}) => {
    setLoading(true)
    await quotaionsRequests.list({...params, ...currentParams})
      .then(response => {
        setQuotaions(response.data.resposta.conteudo.productsQuotations)
      })
      .finally(() => setLoading(false))
  }
  const pegaResultados = async (resetSearch = false) => {
    if (resetSearch) {
      handleQuotations({})
      return
    }
    handleQuotations({[searchFor]: keyword})
  };

  const handleDelete = id => setItemDelete(id)
  const confirmar = async () => {
    setLoading(true);
    await quotaionsRequests.delete(itemDelete)
      .then(async () => {
        toast.success("Cotação deletada com sucesso!!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        setItemDelete(null)
        await handleQuotations();
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    handleQuotations()
  }, [params])

  useEffect(() => {
    setParams({...params, ...filterDate})
  }, [filterDate.dateFinal])

  return (
    <>
      <ToastContainer />
      {itemDelete && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={() => setItemDelete(null)}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}
      <Loader loading={loading} />
      <TitlePage title="Cotações" icon="fal fa-box" />
      <SearchFor
        pegaResultados={pegaResultados}
        tiposDeBusca={[
          { key: "name", label: "Nome" },
          { key: "code", label: "Número" },
          { key: "cnpj", label: "CNPJ" },
        ]}
        searchFor={searchFor}
        setSearchFor={setSearchFor}
        keyword={keyword}
        setKeyword={setKeyword}
      />

      <Row className="align-items-start mb-3 filtros-pesquisa">
        <Col md="12" lg="12" xl="12">
          <Label>Período</Label>
          <InputGroup size="sm" className="m-b-10 flex-nowrap">
            <Input
              type="date"
              name="date"
              placeholder="Início"
              style={{ width: 220 }}
              value={filterDate.dateInitial}
              onChange={(e) => {
                setFilterDate({
                  ...filterDate,
                  dateFinal: "",
                  dateInitial: e.target.value,
                });
              }}
            />
            <Input
              type="date"
              name="date"
              placeholder="Fim"
              style={{ width: 220 }}
              value={filterDate.dateFinal}
              disabled={!filterDate.dateInitial}
              onChange={(e) =>
                setFilterDate({ ...filterDate, dateFinal: e.target.value })
              }
              min={filterDate.dateInitial}
            />
            <InputGroupButtonDropdown
              addonType="append"
              isOpen={splitButtonOpen}
              toggle={toggleSplit}
            >
              {filterDate.dateFinal && (
                <Button
                  color="danger"
                  onClick={() => {
                    setFilterDate({ dateInitial: "", dateFinal: "" });
                  }}
                >
                  <i className="fa fa-times fa-fw"></i>
                </Button>
              )}
            </InputGroupButtonDropdown>
          </InputGroup>
        </Col>
      </Row>
      <div>
        <div className="row">
          {quotaions?.data?.length > 0 ? (
            quotaions.data.map((cota) => {
              return (
                <ItemCotacao
                  key={cota.id}
                  cotacao={{
                    number: cota.quotation_code,
                    cnpj: cnpjMask(cota.company2.cnpj) || "00.0000/0000-00",
                    link: `/cotacoes/${cota.id}`,
                    de: cota.company.company_name,
                    para: cota.company2.company_name || "Empresa Fornecedora",
                    status: cota.status,
                    preco: cota.preco || null,
                    data: moment(new Date(cota.created_at)).format("DD/MM/YY"),
                    valido: cota.valid || null,
                    ...cota,
                  }}
                  handleDelete={handleDelete}
                />
              );
            })
          ) : (
            <h2>Sem cotações</h2>
          )}
        </div>

        <div className="clearfix m-t-20">
          <Pagination
            firstPageUrl={quotaions.first_page_url}
            lastPageUrl={quotaions.last_page_url}
            nextPageUrl={quotaions.next_page_url}
            previousPageUrl={quotaions.prev_page_url}
            perPage={quotaions.per_page}
            lastPage={quotaions.last_page}
            currentPage={quotaions.current_page}
            currentUrl={quotaions.currentUrlProducts}
            path={quotaions.path}
            params={params}
            {...quotaions}
            getItems={handleQuotations}
          />
        </div>
      </div>
    </>
  );
}
