import React, { useEffect, useState } from "react";
import { Collapse, CardHeader, CardBody, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getCities, getUfs } from "../../services/regions";
import { createServiceRegion } from "../../services/regions/regions-service";

const Home = ({ pais, setDeletar, openCountry, index, setLoading, avisar, getAreaDeAtendimento }) => {
    // const countSelectedCities = React.useMemo(() => pais.ufs.filter((item) => item.selected).length, [pais.states])

    const [ufs, setUfs] = useState([])
    const [showText, setShowText] = useState("")
    const [countSelectedEstados, setCountSelectedEstados] = useState(0)
    const [modalCities, setModalCities] = useState({show: false, cities: [], currentUf: {}})
    const [selectedState, setSelectedState] = useState(0)

    // const ufsActives = (ufs) => {
    //     setCountSelectedEstados(ufs.filter(uf => uf.active).length)
    //     setShowText(ufs.filter(item => item.active).length === ufs.length ? "Deselecionar todos" : "Selecionar todos")
    // }

    const selectedEstado = async (idEstado) => {
        if (idEstado === 'todos') {
            const newUfs = ufs.map(uf => ({ ...uf, active: !uf.active }))
            setUfs(newUfs)
            setCountSelectedEstados(newUfs.filter(uf => uf.active).length)
            setShowText(newUfs.filter(item => item.active).length === newUfs.length ? "deselecionar todos" : "Selecionar todos")
            return
        }
        const newUfs = ufs.map(uf => ({ ...uf, active: idEstado === uf.id ? !uf.active : uf.active}))
        setUfs(newUfs)
        setCountSelectedEstados(newUfs.filter(uf => uf.active).length)
        setShowText(newUfs.filter(item => item.active).length === newUfs.length ? "deselecionar todos" : "Selecionar todos")

        await salvar(newUfs)
    }

    const trocarCidades = async (city) => {
        setLoading(true)

        const dataToSave = { countries: [ {
            id: parseInt(pais.id),
            ufs: [
                {
                    id: modalCities.currentUf.id,
                    cities: []
                }
            ]
        }]}

        const newCities = modalCities.currentUf.cities.map(crrCity => {
            if (city.city_id === crrCity.city_id) {
                crrCity.active = crrCity.active ? 0 : 1 
            }
            dataToSave.countries[0].ufs[0].cities.push({id: crrCity.city_id, active: crrCity.active})
            return crrCity
        })

        await createServiceRegion(dataToSave)
            .then(response => {
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
                setModalCities({show: true, cities: newCities, currentUf: modalCities.currentUf})
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })

    }
    const toggleModal = () => {
        setModalCities(!modalCities)
    }

    const salvar = async (ufs) => {

        setLoading(true)
        
        const newsUfs = ufs.map(uf => {
            const isActive = uf.cities.some(city => city.active === 1)
            
            const newUF = {}
            newUF.id = uf.id
            if (uf.active) {
                newUF.active = 1
                return newUF
            }
            if (!isActive) {
                newUF.active = 0
                return newUF
            }
            if (uf.active === false) {
                newUF.active = 0
                return newUF
            }
            if (uf.active === 0) {
                newUF.active = 0
                return newUF
            }
            newUF.active = 1
            return newUF
        })
        const dataToSave = { countries: [ {
            id: parseInt(pais.id),
            ufs: newsUfs
        }]}

        await createServiceRegion(dataToSave)
            .then(async response => {
                await getAreaDeAtendimento().then(() => {
                    avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
                })
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // const cities = async ufId => {
    //     return await getCities(ufId).then(response => response.data.resposta.conteudo.city.data)
    // }
    const modifyuFS = async () => {
        await getUfs(pais.id)
            .then(uf => {
                const ufs = uf.data.resposta.conteudo.ufs
                const newsUFs = ufs.map(countryUf => {
                    const regionUf = pais.ufs.find(uf => uf.id === countryUf.id)

                    if (regionUf) {
                        const noActive = regionUf.cities.every(city => city.active === 0)
                        return { ...regionUf, active: !noActive }
                    }
                    const noActive = countryUf.cities.every(city => city.active === 0)
                    return { ...countryUf, active: !noActive }
                })

                setUfs(newsUFs)
                return newsUFs
            }).catch(err => [])

        // const newsUfs = await ufsApi.map(async uf => {
        //     if (!uf.cities) {
        //         uf.cities = await getCities(uf.id).then(response => response.data.resposta.conteudo.city.data)
        //     }
        // })
        //ufsActives(newsUfs)
    }
    
    useEffect(() => {
        modifyuFS()
    }, [])

    return (
        <>
            <Modal isOpen={modalCities.show} toggle={toggleModal} size="lg" centered>
                <ModalHeader toggle={toggleModal}>Cidades:</ModalHeader>
                <ModalBody className="height-400  overflow-auto">
                    {
                        modalCities?.cities?.length > 0 && modalCities.cities.map((item, i) => {
                            return <div className="checkbox checkbox-css p-10 border-bottom" key={item.id}>
                                <input type="checkbox" id={i + "element"} checked={item.active} onChange={() => trocarCidades(item)} />
                                <label htmlFor="cssCheckbox3" className="w-50 text-truncate" onClick={() => trocarCidades(item)}>{item.cities?.name}</label>
                            </div>
                        })
                    }
                </ModalBody>
            </Modal>
            <Card >
                <CardHeader className={'card-header bg-dark-light pointer-cursor ' + (!pais.open ? 'collapsed ' : '')} onClick={() => openCountry(index)}>
                    <img src={`/assets/img/flag/${pais.name.toLowerCase()}.jpg`} className="img-rounded height-30" alt="" /> {pais.name} ({ufs.filter(uf => uf.active).length} estados)
                    <button className="btn btn-danger btn-icon btn-sm ml-2 float-right" onClick={(e) => { e.stopPropagation(); setDeletar(pais.id) }}><i className="fa fa-times" ></i></button>
                </CardHeader>
                <Collapse isOpen={pais.open}>
                    <CardBody>
                        <div className="checkbox checkbox-css p-10 border-bottom">
                            <input type="checkbox" id={"element"} />
                            <label htmlFor="cssCheckbox3" className="w-50 text-truncate" onClick={() => selectedEstado("todos")}>
                                {showText}
                            </label>
                        </div>
                        {
                            ufs.length > 0 && ufs.map((item, i) => {
                                return (
                                    <div className="checkbox checkbox-css p-10 border-bottom" key={i}>
                                        <input type="checkbox" id={i + "element"} checked={item?.active} onClick={() => selectedEstado(item.id)} />
                                        {/* <label htmlFor="cssCheckbox3" className="w-50 text-truncate" onClick={() => trocarUmEstado(i, index)}>{item.name}</label> */}
                                        <label htmlFor="cssCheckbox3" className="w-50 text-truncate" onClick={() => selectedEstado(item.id)}>{item.name}</label>
                                        {
                                            item.active && (
                                                <div className="float-right">
                                                    <small>({item.cities.filter(cty => cty.active).length}) cidades</small>
                                                    <button
                                                        className="btn btn-success btn-icon btn-sm ml-2"
                                                        onClick={() => {
                                                            setSelectedState(i)
                                                            setModalCities({ show: true, cities: item.cities, currentUf: item })
                                                        }}
                                                    >
                                                        <i className="fa fa-edit" ></i>
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </CardBody>
                </Collapse>
            </Card>
        </>

    );
};

export default Home;
