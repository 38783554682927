import React, { useState, useEffect, useContext } from "react";
import {} from "react-router-dom";
import { useDispatch } from "react-redux";
import { PageSettings } from "../../../config/page-settings";
import { getCompanie } from "../../../store/auth/action";

import { Row } from "reactstrap";
import Loader from "../../../components/loader";

import { useSelector } from "react-redux";

import Topo from "./topo";
import { usersRequests } from "../../../services/admin/users";
import { companiesRequests } from "../../../services/admin/companies";
import { productsRequests } from "../../../services/admin/products";
import { servicesRequests } from "../../../services/admin/services";
import { quotaionsRequests } from "../../../services/admin/quotaions";
import { URL_TO_IMAGES } from "../../../config/api.config";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [info, setInfo] = useState({
    users: 0,
    logo: "../../../assets/img/company/img-1.png",
    companies: 0,
    products: 0,
    name: "",
    services: 0,
    cotations: "0",
  });

  const getAllInfo = async () => {
    setLoading(true);
    await Promise.all([
      usersRequests.list(),
      companiesRequests.qtd(),
      productsRequests.list(),
      servicesRequests.list(),
      quotaionsRequests.qtd(),
    ])
      .then(
        ([
          responseUsers,
          responseCompanies,
          responseProducts,
          responseServices,
          responseQuotations,
        ]) => {
          const users =
            responseUsers?.data?.resposta?.conteudo?.users?.total || 0;
          const [supplies, customers] =
            responseCompanies?.data?.resposta?.conteudo.map(comp => comp.total) || [0, 0];
          const products =
            responseProducts?.data?.resposta?.conteudo?.products?.total || 0;
          const services =
            responseServices?.data?.resposta?.conteudo?.services?.total || 0;
          const cotations =
            responseQuotations?.data?.resposta?.conteudo?.["qtd-quotations"] || 0;

          const logo =
            user.companies[0]?.company_logo ? URL_TO_IMAGES+'/'+user.companies[0]?.company_logo.replace('public', '') : "../../../assets/img/company/img-1.png";
          setInfo({
            ...info,
            name: user.companies[0].company_name,
            logo,
            users,
            supplies,
            customers,
            products,
            services,
            cotations,
          });
        }
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    getAllInfo();
  }, []);

  useEffect(() => {
    if (user) dispatch(getCompanie(user.companies[0].id));
  }, [user]);

  return (
    <>
      <Loader loading={loading} />

      <h1 className="page-header">
        <i className="fal fa-building mr-2 opacity-5 text-aqua"></i>
        Dashboard <br />
        <small>As principais informações do sistema estão aqui.</small>
      </h1>

      <Row>
        <Topo info={info} />
      </Row>
    </>
  );
};

export default Dashboard;
