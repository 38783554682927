import React, { useState, useEffect, useContext, memo, useRef } from "react";
import Loader from "../../components/loader";
import ModalFiltro from "./parts/modalFiltro";
import {
  InputGroup,
  Input,
  Row,
  Col,
  InputGroupButtonDropdown,
  Button,
} from "reactstrap";
import { PageSettings } from "../../config/page-settings";
import TitlePage from "../../components/pages/title";
import SweetAlert from "react-bootstrap-sweetalert";
import Result from "./parts/result";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataRequested,
  setTypeRequested,
  setPageRequested,
} from "../../store/pesquisa/action";

const Home = (props) => {
  const [loading, setLoading] = useState(false);
  const [modalFiltro, setModalFiltro] = useState(false);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [deletar, setDeletar] = useState(false);
  const contextType = useContext(PageSettings);
  const dados = useSelector((state) => state.search);
  const [resultados, setResultados] = useState([]);
  const [params, setParams] = useState({ pesquisar: "produtos" });
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };
  const confirmar = () => {
    setDeletar(false);
  };
  const deletarItem = () => {
    setDeletar(false);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const setarPosition = () => {
    var doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    localStorage.setItem("top-serach", top);
  };
  const toggleModalFiltro = () => {
    setModalFiltro(!modalFiltro);
  };

  const pegaResultados = async (params = {}) => {
    const { page } = params;
    dispatch(setPageRequested(page));
  };

  useEffect(() => {
    dispatch(
      getDataRequested({
        ...params,
        pesquisar: dados.type,
        keyword,
        page: dados.currentPage,
      })
    );
  }, [dados.type, dados.currentPage]);

  useEffect(() => {
    //window.scrollTo(0, 0)
    if (dados.result)
      return window.scrollTo(0, localStorage.getItem("top-serach"));
    contextType.closeMobileSiderBar();

    if (!dados.result) {
      dispatch(getDataRequested({ ...params, keyword }));
    }
    return () => {
      setarPosition();
    };
  }, []);

  const setType = (type) => {
    dispatch(setTypeRequested(type));
    dispatch(setPageRequested(1));
  };

  return (
    <>
      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={deletarItem}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}
      <Loader loading={loading} />

      <TitlePage
        title="Pesquisa avançada"
        desc="Encontre Fornecedores, Produtos, Serviços e Clientes"
        icon="fab fa-simplybuilt"
      />
      <div className="row">
        <ModalFiltro
          modal={modalFiltro}
          toggle={toggleModalFiltro}
          getResults={pegaResultados}
          params={params}
          setParams={setParams}
          setLoading={setLoading}
        />
        <div className="col-md-12">
          <Row className="align-items-start mt-3 filtros-pesquisa">
            <Col md="8" lg="8" xl="10">
              <InputGroup size="lg" className="m-b-20">
                <Input
                  placeholder=""
                  type="text"
                  className="input-white"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={splitButtonOpen}
                  toggle={toggleSplit}
                >
                  <Button
                    color="success"
                    onClick={() => pegaResultados({ ...params, keyword })}
                  >
                    <i className="fa fa-search fa-fw"></i>
                  </Button>
                  {keyword && (
                    <Button
                      color="danger"
                      onClick={() => {
                        pegaResultados({ ...params, keyword: "" });
                        setKeyword("");
                      }}
                    >
                      <i className="fa fa-times fa-fw"></i>
                    </Button>
                  )}
                </InputGroupButtonDropdown>
              </InputGroup>
            </Col>
            <Col md="4" lg="4" xl="2" className="container-botao-pesquisa">
              <button
                className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
                onClick={toggleModalFiltro}
              >
                Filtros
              </button>
            </Col>

            <Col className="form-group d-flex pesquisa-por">
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="fornecedor"
                  onChange={() => {
                    //setTipoPesquisa('fornecedor')
                    setType("fornecedor");
                  }}
                  checked={dados.type === "fornecedor"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="fornecedor">
                  Fornecedores
                </label>
              </div>
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="clientes"
                  onChange={() => {
                    setType("clientes");
                  }}
                  checked={dados.type === "clientes"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="clientes">
                  Clientes
                </label>
              </div>
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="produtos"
                  onChange={() => {
                    //setTipoPesquisa('produtos')
                    setType("produtos");
                  }}
                  checked={dados.type === "produtos"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="produtos">
                  Produtos
                </label>
              </div>
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="servicos"
                  onChange={() => {
                    //setTipoPesquisa('servicos')
                    setType("servicos");
                  }}
                  checked={dados.type === "servicos"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="servicos">
                  Serviços
                </label>
              </div>
            </Col>
          </Row>
          {/* */}
          <Result
            dados={dados}
            setarPosition={setarPosition}
            pegaResultados={pegaResultados}
            params={params}
            resultados={resultados}
          />
        </div>
      </div>
    </>
  );
};

export default memo(withRouter(Home));
