import React, {useState} from "react"
import {Row} from "reactstrap"
import { Editor } from 'react-draft-wysiwyg'

const BasicInfo = ( props ) => {
    return(
      <Row>
      <div className="col-md-12">
        <div className="form-group">
          <label className="opacity-5 mb-1">Especificações Técnicas</label>
          <Editor
              editorState={props.especificacao}
              editorClassName="form-control min-height-editor"
              toolbar={{ options: ["inline", "blockType"] }}
              onEditorStateChange={(e) => props.setEspecificacao(e)}
            />
        </div>
      </div>
      <button type="button" className="mt-3 btn btn-secondary" onClick={props.func_acao}>Proximo</button>
    </Row>

    )
}
export default BasicInfo