import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/loader";
import Campos from "./cadastrar/novoProduto"

import { PageSettings } from "../../config/page-settings"

import TitlePage from "../../components/pages/title"
import { getProduct } from "../../services/product";

const EditarProduto = () => {
    const [loading, setLoading] = useState(true);
    const contextType = useContext(PageSettings);
    const [modalProduto, toggleModal] = useState(false)
    const [produto, setProduto] = useState(null)

    const [name, setName] = useState("")

    const getProduto = async () => {

        const produtoId = window.location.pathname.split('/').pop()
        await getProduct(produtoId)
            .then(response => {
                setProduto(response.data.resposta.conteudo.product)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        const URLParams = new URLSearchParams(window.location.search)
        setName(URLParams.get('name'))

        getProduto()
        
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const toggleModalProduto = () => {
        toggleModal(!modalProduto)
    }
    return (
        <>
            {
                produto ? (
                    <>
                        <Loader loading={loading} />
                        <TitlePage title={`Editar Produto: ${name}`} icon="fal fa-box" />
                        <Campos modalProduto={modalProduto}
                            toggleModal={toggleModalProduto}
                            produto={produto}

                            setLoading={setLoading}
                        />
                    </>
                ) : (
                    <Loader loading={true} />
                )
            }
        </>
    );
};

export default EditarProduto;
