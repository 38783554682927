import React, { useState, useEffect, useContext } from "react";
import { store } from "react-notifications-component";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSelector } from "react-redux";

import Loader from "../../components/loader";
import { PageSettings } from "../../config/page-settings"
import Breadcrumb from "../../components/breakcumbs"
import TitlePage from "../../components/pages/title"
import { recusaPortfolio } from "../../services/portfolios";

import Cliente from './cliente'
import Fornecedor from './fornecedor'
import Solicitacoes from './solicitacoes'

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const MinhaCarteiraHome = () => {
    const contextType = useContext(PageSettings);
    const user = useSelector(state => state.auth.user)
    const [activeTab, setActiveTab] = useState("1");
    const [typeUser, setTypeUser] = useState(2);
    const [hasChange, setHasChange] = useState(false)
    
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const naoSeguir = async (id, getItems) => {
        await recusaPortfolio(id)
            .then(async response => {
                avisar("Sucesso!", response.data?.resposta?.conteudo?.message, "success")
                await getItems()
            })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()
        
        setTypeUser(parseInt(user.companies[0].profile))
        setActiveTab(parseInt(user.companies[0].profile) === 1 ? "1" : "2")
    }, []);

    return (
        <>
            <TitlePage title="Minha carteira" icon="fa fa-wallet" />
            <div className="row">
                <div className="col-12 pt-3 pb-3">
                    <Nav tabs className="nav-pills">
                        {
                            typeUser === 1 && (
                                <NavItem className="m-3">
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        Carteira de clientes
                                    </NavLink>
                                </NavItem>
                            )
                        }
                        <NavItem className="m-3">
                            <NavLink
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                    toggle("2");
                                }}
                            >
                                Carteira de fornecedor
                            </NavLink>
                        </NavItem>
                        <NavItem className="m-3">
                            <NavLink
                                className={classnames({ active: activeTab === "3" })}
                                onClick={() => {
                                    toggle("3");
                                }}
                            >
                                Solicitações pendentes
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
            
            <TabContent activeTab={activeTab} style={{ marginBottom: 30 }}>
                {
                    typeUser === 1 && (
                        <TabPane tabId="1">
                            <Cliente
                                hasChange={hasChange}
                                setHasChange={setHasChange}
                                naoSeguir={naoSeguir}
                            />
                        </TabPane>
                    )
                }
                <TabPane tabId="2">
                    <Fornecedor
                        hasChange={hasChange}
                        setHasChange={setHasChange}
                        naoSeguir={naoSeguir}
                    />
                </TabPane>
                <TabPane tabId="3">
                    <Solicitacoes
                        setHasChange={setHasChange}
                    />
                </TabPane>
            </TabContent>
        </>
    );
};

export default MinhaCarteiraHome;
