import api from "../api";
import { BASE_URL_ADMIN } from "../../config/api.config";

const baseUrl = `${BASE_URL_ADMIN}/product-quotations`;

export const quotaionsRequests = {
    list: (params = {}) => api.get(`${baseUrl}`, { params }),
    show: (id) => api.get(`${baseUrl}/${id}`),
    delete: (id) => api.delete(`${baseUrl}/${id}`),
    qtd: (params = {}) => api.get(`${baseUrl}/qtd-quotations`, { params })
}
