import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import CollapseItem from "../../../components/itemAcoddion"
import DropFiles from "../../../components/dropFiles"
import VideoEmbed from "../../../components/videoEmbed"
import BasicInfo from "./basicInfo"
import { convertToRaw, EditorState } from 'draft-js'
import { useHistory } from "react-router-dom"

import { store } from 'react-notifications-component'
import { createCategory, getCategories } from '../../../services/service/cotegories'
import { createImages, updateImages } from '../../../services/service'
import { servicesRequests } from '../../../services/admin/services'
import draftToHtml from 'draftjs-to-html'
import { stringHtmlDataToDraftState } from '../../../uteis/draft-jsCustom'
import { URL_TO_IMAGES } from '../../../config/api.config'
import { orderBy } from '../../../uteis'

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}
// separar responsabilidades
// fotos preview
// deletar foto
// proximo item
const NovoServico = (props) => {
    let history = useHistory();

    //1
    const [infoAbrir, setInfoAbrir] = useState(true)
    const [filesOpen, setFilesOpen] = useState(false)
    const [abrirVideo, setAbrirVideo] = useState(false)
    const [btnSalvar, setBtnSalvar] = useState(true)

    // form
    // 1
    const [nomeService, setNomeService] = useState("")
    const [categoria, setCategoria] = useState("")
    const [categorias, setCategorias] = useState([])
    const [descricao, setDescricao] = useState(EditorState.createEmpty())
    const [codigo, setCodigo] = useState("")
    // 2
    const [files, setFiles] = useState([])
    // 3
    const [videoURL, setVideoURL] = useState("")

    const isCopy = window.location.pathname.includes("/cadastrar/servico/")
    const serviceId = props.servico && !isCopy ? props.servico.id : null
    const salvarServico = () => {

        props.setLoading(true)

        const formdata = new FormData()
        formdata.append("active", 1)
        formdata.append("name", nomeService)
        formdata.append("description", draftToHtml(convertToRaw(descricao.getCurrentContent())))

        formdata.append("video_link", videoURL)

        formdata.append("code", codigo)

        const filesOrder = orderBy(files, 'order')
        
        const currentImages = {
            _method: "put",
            current_images: []
        }
        filesOrder.forEach(async (file, index) => {
            if (file?.id) {
                // formdata.append("images[]", file.id)
                currentImages.current_images.push(`id: ${file.id}, order: ${index}`)
                return
            }
            file.order = index
            if (serviceId) {
                const newImages = new FormData()
                newImages.append("service_id", serviceId)
                newImages.append("images[]", file)
                await createImages(newImages)
                    .then(response => {
                        currentImages.current_images.push(`id: ${response.data.resposta.conteudo.images[0].id}, order: ${file.order}`)
                    })
            }
            formdata.append("images[]", file)
        })

        if (props.servico && !isCopy) {
            formdata.append("_method", "put")
        }

        getCategorias().then(async response => {
            await createCategory({ name: categoria }).then(async response => {
                const objectCategoria = response.data.resposta.conteudo?.category || response.data.resposta.conteudo.categoryService
                formdata.append("category_services_id", objectCategoria.id)
                
                if (serviceId && !isCopy) {
                    await updateImages(currentImages, serviceId)
                        .then(response => {
                            createServico(formdata, serviceId)
                        })
                    return
                }

                createServico(formdata, serviceId)
            })
        })
    }

    const createServico = async (formdata, serviceId) => {

        for (let pair of formdata.entries()) {
            if (pair[1] === 'null' || pair[1] === null) {
                formdata.delete(pair[0])
            }
        }        

        let object = {};
        formdata.forEach((value, key) => (object[key] = value));
        const dataTosave = JSON.stringify(object);

        return await servicesRequests.update(serviceId, dataTosave)
            .then(response => {
                avisar("Serviço salvo com sucesso!", "Seu serviço foi salvo e já esta disponivel na plataforma", "success")
                history.push("/servicos")
            })
            .catch(error => {
                if (error && error.response?.data?.resposta) {
                    const messages = error.response.data?.resposta?.conteudo?.messages

                    if (error.response.data.resposta?.conteudo?.error && !messages) {
                        avisar("Ops!!", error.response.data.resposta?.conteudo?.error, "danger")
                    }

                    if (messages) {
                        Object.keys(messages).forEach(key => {
                            messages[key].forEach(message => {
                                avisar("Ops!!", message, "danger")
                            })
                        })
                    }
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                props.setLoading(false)
            })
    }

    const fecharTodos = () => {
        setInfoAbrir(false)
        setFilesOpen(false)
        setAbrirVideo(false)
        setInfoAbrir(false)
    }

    const liberarBotao = () => {
        setBtnSalvar(false)
    }
    const validar = () => {
        let valid = true;
        if (!nomeService) {
            avisar("Ops!!", "Campos 'nome' é obrigátorio", "danger")
            valid = false
        }
        if (!descricao.getCurrentContent().hasText()) {
            avisar("Ops!!", "Campos 'Descrição' é obrigátorio", "danger")
            valid = false
        }
        
        if (!categoria) {
            avisar("Ops!!", "Campos 'Categoria' é obrigátorio", "danger")
            valid = false
        }
        
        setBtnSalvar(!valid)
        return valid
    }
    const irFotos = () => {

        if (validar()) {
            fecharTodos()
            liberarBotao()
            setFilesOpen(true)
        }

    }

    const irVideo = () => {
        fecharTodos()
        setAbrirVideo(true)
    }

    const getCategorias = async (searchItem = '') => {
        await getCategories(searchItem).then(response => {
            setCategorias(response.data.resposta.conteudo.categoryService.data)
        })
            .catch(error => {
                if (error && error.response?.data?.message) {
                    avisar("Ops!!", error.response.data.message, "danger")
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
    }

    const isEditar = async () => {
        const { servico } = props
        if (!servico) {
            return
        }

        setNomeService(servico?.name || '')
        setCategoria(servico?.category?.name || '')
        setDescricao(stringHtmlDataToDraftState(servico.description))
        setCodigo(servico?.code || '')
        // 3
        setVideoURL(servico?.video_link || '')

        if (servico.images) {
            const files = orderBy(servico.images, 'order').map(file => {
                file.preview = `${URL_TO_IMAGES}${file.path.replace('public', '')}`
                
                delete file['order']
    
                return file
            })

            setFiles(files)
        }
    }

    useEffect(() => {
        isEditar()
    }, [])

    useEffect(() => {
        if (filesOpen || abrirVideo) {
            if (!validar()) {
                fecharTodos()
                setInfoAbrir(true)
            }
        }
    }, [filesOpen,abrirVideo])

    return (
        <>
            <div>
                <div id="accordion" className="accordion">
                    <CollapseItem posicao={1} nome="Informações básicas" open={infoAbrir} func={setInfoAbrir}>
                        <BasicInfo
                            nome={nomeService}
                            setNome={setNomeService}

                            categoria={categoria}
                            setCategoria={setCategoria}

                            codigo={codigo}
                            setCodigo={setCodigo}

                            descricao={descricao}
                            setDescricao={setDescricao}

                            categorias={categorias}
                            setCategorias={setCategorias}

                            searchCategoria={getCategorias}

                            func_acao={irFotos} />
                    </CollapseItem>
                    <CollapseItem nome="Cadastar as fotos" posicao={2} open={filesOpen} func={setFilesOpen}>
                        {
                            files && (
                                <DropFiles
                                    func_acao={irVideo}
                                    setFiles={setFiles}
                                    files={files}
                                    setLoading={props.setLoading}
                                    isUpdate={!isCopy && serviceId}
                                    create={createImages}
                                    type="servico"
                                />
                            )
                        }
                    </CollapseItem>
                    <CollapseItem nome="Video do serviço" open={abrirVideo} posicao={3} func={setAbrirVideo}>
                        <VideoEmbed
                            hideButton={true}
                            url={videoURL}
                            setURL={setVideoURL}
                        />
                    </CollapseItem>
                </div>
                <Button className="mt-3" color="secondary" onClick={salvarServico} disabled={btnSalvar}>Salvar</Button>
            </div>
        </>
    );
}

export default NovoServico;