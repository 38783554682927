import React from 'react';
import moment from 'moment';

import { usersRequests } from '../../../services/admin/users';
import PageDefault from '../pageDefault';

export default function Users() {

    return (
      <PageDefault
        title={"Usuário"}
        titlePlural={"Usuários"}
        type="users"
        dataRequest={usersRequests}
        baseUrl={"usuarios"}
        columnsTable={[
          { key: "name", name: "Nome" },
          { key: "email", name: "E-mail", hide: "sm",  },
          { key: "active", name: "Ativo", hide: "md", render: cell => cell === 1  ? "Sim" : "Não"},
          { key: "last_access", name: "Último acesso", hide: "md", render: cell => cell && moment(new Date(cell)).format("DD/MM/YY HH:MM") },
        ]}
        tiposDeBusca={[{ key: "name", label: "Nome" }]}      
      />
    )
}