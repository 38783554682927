import {put, takeLatest, all, call, fork} from "redux-saga/effects"
import {singUpFailed, singUpSuccess} from "./action"
import * as types from "./types"
import {API, apiConfig} from "../../config/api.config"

function callAPISignUp(values){
    return new Promise((resolve, reject)=> {
        const url = `${API}/auth/register?cnpj=${values.cnpj}&company_name=${values.companyName}&email=${values.email}&profile=${values.tipoCadastro === "Fornecedor" ? 1 : 2}&c_password=${values.password}&password=${values.password}`
        fetch(url, apiConfig)
            .then(response =>{
            return response.json()
            })
            .then((data)=>{
                return resolve(data)
            })
            .catch(err=>{
            return reject(err.message)
            })
    })

}

function* _signUp(action){
    try {
        const result = yield call(callAPISignUp, action.payload)
        yield put(singUpSuccess(result))
    } catch (error) {
        yield put(singUpFailed(error))
    }
}

function* signUpWatcher(){
    yield takeLatest(types.SING_UP, _signUp)
}

function* sagaSignUp() {
    yield all([
        fork(signUpWatcher),
    ]);
}

export default sagaSignUp



