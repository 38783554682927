import React from "react";
import { Row, Col, CardBody, Card } from "reactstrap";
const Topo = ({ info }) => {
  return (
    <Col md="12">
      <Card>
        <CardBody>
          <Row>
            <Col
              md="12"
              lg="12"
              className="align-items-center justify-content-center justify-content-md-start jus d-flex mb-4 mb-lg-0 "
            >
              <div className="mr-2">
                <img
                  src={info.logo}
                  alt="logo empresa"
                  className="rounded-corner avatar-sm "
                />
              </div>
              <div>
                <p className="mb-0 text-muted">Seja bem-vindo</p>
                <h4 className="mb-0">{info.name}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12" className="mb-2 mb-lg-0">
              <Row className="justify-content-around">
                <Col md="2" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3">Usuários</p>
                  <h3>{info.users}</h3>
                </Col>
                <Col md="2" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3 text-truncate">Fornecedores</p>
                  <h3>{info.supplies}</h3>
                </Col>
                <Col md="2" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3 text-truncate">Compradores</p>
                  <h3>{info.customers}</h3>
                </Col>
                <Col md="2" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3 text-truncate">Produtos</p>
                  <h3>{info.products}</h3>
                </Col>
                <Col md="2" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3 text-truncate">Servicos</p>
                  <h3>{info.services}</h3>
                </Col>
                <Col md="2" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3 text-truncate">Cotações</p>
                  <h3>{info.cotations}</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Topo;
