import React, { useState, useEffect, useContext, useRef } from "react";
import Loader   from "../../components/loader";

import {PageSettings} from "../../config/page-settings"
import lottie from "lottie-web"
import animation from "../../assets/json/gift2"


import {
  Panel, 
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import Breadcrumb     from "../../components/breakcumbs"
import TitlePage      from "../../components/pages/title"
import {Row, Col} from "reactstrap"
import { useHistory } from "react-router-dom";

const Assinatura = ( ) => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const container = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: animation
    })
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return ()=>{
      contextType.handleSetPageContentFullHeight(false);
      contextType.handleSetPageContentFullWidth(false);
    }
  }, []);


  

  return (
    <>
    <Loader loading={loading}/>
    <TitlePage icon="far fa-comments" title="Assinatura" desc="" />
    <Row>
      <Col sm={12}>
        <Panel theme="default">
          <PanelHeader>Sua assinatura</PanelHeader>
          <PanelBody>

                  <h2>Temos um presente para todos os novos membros</h2>
                  <p className="content-desc">
                  Aproveite todas beneficios do nossa assinatura PREMIUM válido até 01 de Janeiro de 2022.</p>
                  {/* 
                  <ul className="pricing-table pricing-col-4">
                    <li data-animation="true" >
                      <div className="pricing-container">
                        <h3>Starter</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">Grátis</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-inverse btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                    <li data-animation="true" >
                      <div className="pricing-container">
                        <h3>Basic (atual)</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">R$9.99</span>
                            <span className="price-tenure">(plano atual gratuido até 22/03/2021)</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>todos os beneficios do plano grátis</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-inverse btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                    <li className="highlight" >
                      <div className="pricing-container">
                        <h3>Premium (Recomendamos)</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">$19.99</span>
                            <span className="price-tenure text-inverse text-white">per month</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>todos os beneficios do plano basic</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-orange btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                    <li data-animation="true">
                      <div className="pricing-container">
                        <h3>Super Premium</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">R$120.00</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>todos os beneficios do plano premium</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-inverse btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  */}
                  <div ref={container}></div>

          </PanelBody>
        </Panel>
      </Col>
    </Row>
  </>

  );
};

export default Assinatura;
