import React, { useEffect, useState } from "react";
import ReactTags from "react-tag-autocomplete";
import { Row, Input, Label } from "reactstrap";
import { Editor } from 'react-draft-wysiwyg';
import Select from 'react-select'

import { cepMask, cnpjMask } from "../../../uteis/mask";
import { orderBy } from "../../../uteis";
import { stringHtmlDataToDraftState } from "../../../uteis/draft-jsCustom";
import { getCities, getUfs } from "../../../services/regions";

import "react-datetime/css/react-datetime.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getEconomicActivity, getCompanyType } from "../../../services/companies";

function CadastroInfo({ empresa, setEmpresa, setLoading }) {
    const { company_name, fantasy_name, cnpj, postal_code, description, address, complement, uf_id, city_id} = empresa

    const [estados, setEstados] = useState([])
    const [municipios, setMunicipios] = useState([])
    const [atividades, setAtividades] = useState([])
    const [tipos, setTipos] = useState([])

    const pegaUFs = async idPais => {
        setLoading(true)
        await getUfs(idPais)
            .then(response => {
                setEstados(response.data.resposta.conteudo.ufs)
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }
    const pegaCidades = async idUf => {
        setLoading(true)
        await getCities(idUf,'all')
            .then(response => {
                setMunicipios(response.data.resposta.conteudo.city)
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }
    const pegaAtividades = async () => {
        setLoading(true)
        await getEconomicActivity()
            .then(response => {
                setAtividades(response.data.resposta.conteudo.companiesType)
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }
    const pegaTipos = async () => {
        setLoading(true)
        await getCompanyType()
            .then(response => {
                setTipos(response.data.resposta.conteudo.companiesType)
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        pegaUFs(33)
        pegaAtividades()
        pegaTipos()

        
        if (uf_id) {
            pegaCidades(uf_id)
        }
    }, [empresa.uf_id])
   
    return (
        <form>
            <Row>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Razao social</label>
                        <input
                            name="company_name"
                            placeholder="Razão social"
                            type="text"
                            className="form-control"
                            value={company_name || ""}
                            onChange={(event) => setEmpresa({...empresa, company_name: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Nome Fantasia</label>
                        <input
                            name="fantasy_name"
                            placeholder="Nome da empresa"
                            type="text"
                            className="form-control"
                            value={fantasy_name || ""}
                            onChange={(event) => setEmpresa({...empresa, fantasy_name: event.target.value})}
                        />
                    </div>
                </div> 
                <div className="col-md-5">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">CNPJ</label>
                        <input
                            name="cnpj"
                            placeholder="CNPJ"
                            type="text"
                            className="form-control"
                            value={cnpj || ""}
                            maxLength={18}
                            onChange={(event) => setEmpresa({...empresa, cnpj: cnpjMask(event.target.value)})}
                            disabled
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">CEP</label>
                        <input
                            name="postal_code"
                            placeholder="CEP"
                            type="text"
                            className="form-control"
                            value={postal_code || ""}
                            maxLength={10}
                            onChange={(event) => setEmpresa({...empresa, postal_code: cepMask(event.target.value)})}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Endereço</label>
                        <input
                            name="address"
                            placeholder="Rua"
                            type="text"
                            className="form-control"
                            value={address || ""}
                            onChange={(event) => setEmpresa({...empresa, address: event.target.value})}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Complemento</label>
                        <input
                            name="complement"
                            placeholder="Complemento"
                            type="text"
                            className="form-control"
                            value={complement || ""}
                            onChange={(event) => setEmpresa({...empresa, complement: event.target.value})}
                        />
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Estado</label>
                        <Input
                            type="select"
                            name="select"
                            className="form-round"
                            value={uf_id || ""}
                            onChange={event => {
                                pegaCidades(parseInt(event.target.value))
                                setEmpresa({...empresa, uf_id: event.target.value, country_id: 33})
                            }}
                        >
                            <option>Escolha um Estado...</option>
                            {
                                estados && estados.map(estado => (
                                    <option key={estado.id} value={estado.id}>{estado.name}</option>
                                ))
                            }
                        </Input>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Cidade</label>                        
                        <Input
                            type="select"
                            name="select"
                            className="form-round"
                            value={city_id || ""}
                            onChange={event => setEmpresa({...empresa, city_id: event.target.value})}
                        >   
                            <option>Escolha um Municipio...</option>
                            {
                                municipios && municipios.map(municipio => (
                                    <option key={municipio.id} value={municipio.id}>{municipio.name}</option>
                                ))
                            }
                        </Input>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Tipo da empresa</label>
                        <br />
                        {/* <Input type="select" name="select" className="form-round" id="exampleSelect">
                            <option>Fabricante</option>
                            <option>Atacadista</option>
                            <option>Distribuidor</option>
                            <option>importador</option>
                            <option>Exportador</option>
                            <option>Prestador de serviço</option>
                            <option>Fornecedor de Matėria Prima</option>
                        </Input> */}
                        {/* <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            paddingLeft: '20px'
                        }}>
                            {
                                tipos && tipos.map(tipo => (
                                    <Label key={tipo.id} id={tipo.id}>
                                        <Input
                                            value={tipo.value}
                                            onChange={event => {
                                                console.log({...empresa, companies_types: [...empresa.companies_types, parseInt(event.target.value)] });
                                                setEmpresa({...empresa, companies_types: [...empresa.companies_types, parseInt(event.target.value)] })
                                            }}
                                            type="checkbox" 
                                            name={tipo.label} 
                                            // disabled={disabled} 
                                            // checked={frete?.freight?.includes('FOB') || frete?.freight?.includes('CIF/FOB')}
                                        />
                                        { tipo.label }
                                    </Label>
                                ))
                            }
                        </div> */}
                        { 
                            empresa.companies_types && (
                                <Select
                                    value={empresa.companies_types.length > 0 ? empresa.companies_types : []}
                                    options={orderBy(tipos, 'label')}
                                    isMulti
                                    name="colors"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={event => {
                                        setEmpresa({...empresa, companies_types: event ? event : [] })
                                    }}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => `${option.description}`}
                                />
                            )
                        }
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Atividade Econômica</label>

                        {/* <ReactTags  
                            placeholder="Add Atividade"
                            tags={tags}
                            autofocus={false}
                            handleFocus={() => console.log("teste")}
                            suggestions={suggestions}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                        /> */}
                        {
                            empresa.economic_activities && (
                                <Select
                                    value={empresa.economic_activities.length > 0 ? empresa.economic_activities : []}
                                    options={orderBy(atividades, 'label')}
                                    isMulti
                                    name="colors"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={event => {
                                        setEmpresa({...empresa, economic_activities: event ? event : [] })
                                    }}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => `${option.description}`}
                                />
                            )
                        }

                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="opacity-5 mb-1">Descrição</label>
                        <Editor
                            editorState={typeof description === 'string' ? stringHtmlDataToDraftState(description || "") : description }
                            editorClassName="form-control min-height-editor"
                            toolbar={{ options: ["inline", "blockType"] }}
                            onEditorStateChange={(event) => setEmpresa({...empresa, description: event})}
                        />
                    </div>
                </div>
            </Row>
        </form>
    );
}

export default CadastroInfo;
