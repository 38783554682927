import React from 'react';
import { Button } from 'reactstrap';

function pages(props) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }}>
      <h1 className="page-header">
          <i className={`fal ${props.icon || "fa-building"} mr-2 opacity-5 text-aqua`}></i>
          {props.title} <br />
          <small>
          {" "}
          <div className="mt-2" dangerouslySetInnerHTML={{__html: props.desc}}></div>
          </small>
      </h1>      
      <Button
        className="btn-sm bg-default"
        type="button"
        onClick={() => window.history.back()}
      >
        Voltar
      </Button>
    </div>
  );
}

export default pages;