import React from 'react';
import {Link} from "react-router-dom"

function breakcumbs(props) {
  return (
    <ol className="breadcrumb float-xl-right opacity-5">
        {props.list.map((item)=>{
          return (
            <li className="breadcrumb-item" key={Math.random()}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          )
        })}
    </ol>
  );
}

export default breakcumbs;