import React from "react"
import { Link } from "react-router-dom"
import { Col, Row, Badge, } from "reactstrap"
import { cepMask, cnpjMask, phoneMask } from "../../uteis/mask"
import { URL_TO_IMAGES } from '../../config/api.config'

const Topo = ({ empresa, cliente, cnpj, respondida, dataSolicitacao, hidePdf, quotation, gerarPdf, loadPDF }) => (
    <>
        <div className="invoice-company text-inverse f-w-600 p-2">
            {!hidePdf ?
                <>
                    <span className="pull-right hidden-print">
                        {
                            !quotation?.quotation_code && (
                                <button disabled={loadPDF} onClick={gerarPdf}  className="btn btn-sm btn-white m-b-10 p-l-5 m-r-5">
                                    {loadPDF ? <>
                                        <i className="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Carregando
                                    </> :
                                    <>
                                        <i className="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Exportar para PDF
                                    </>
                                    }
                                </button>
                            )
                        }
                    </span>
                    Cotação #{quotation?.quotation_code || quotation?.quotationDetails?.quotation_code || ''} <br />
                    {!quotation?.quotation_code ? <Badge className="badge-soft-success">Respondida</Badge>
                        : <Badge className="badge-soft-warning">Aguardando resposta</Badge>}
                </>
                : null
            }
        </div>
        <div className="invoice-header">
            <div className="invoice-from">
                <Link to={`/fornecedor/${empresa.id}`}>
                    <small>{cliente ? "Cliente" : "Fornecedor"}</small>
                    <address className="m-t-5 m-b-5">
                        <Row>
                            <Col className="col-auto">
                                <div className="mr-2">
                                    {/* <img src={require("../../assets/img/company/img-1.png")} alt="logo empresa" className="rounded-corner avatar-sm " /> */}
                                    <img
                                        src={empresa.company_logo ? URL_TO_IMAGES+'/'+empresa.company_logo.replace('public', '') : "/assets/img/user/user-13.jpg"}
                                        alt="logo empresa"
                                        className="rounded-corner avatar-sm "
                                    />
                                </div>
                            </Col>
                            <Col>
                                <strong className="text-inverse">{empresa.company_name}</strong><br />
                                CNPJ: {cnpjMask(empresa.cnpj)}<br />
                            </Col>
                        </Row>


                    </address>
                </Link>
            </div>
            <div className="invoice-to d-flex align-items-center">
                <Link to="#">
                    <small>Endereço</small>
                    <address className="m-t-5 m-b-5">
                        {empresa.address}, {empresa.complement}<br />
                    Zip Code: {cepMask(empresa.postal_code)}<br />
                    Telefone: {phoneMask(empresa.phone)}<br />
                    </address>

                </Link>
            </div>
            {!hidePdf ?
                <div className="invoice-date">
                    <small>Data da solicitação:</small>
                    <div className="date text-inverse m-t-5">{dataSolicitacao || "2 de Novembro de 2020"}</div>
                </div> : null
            }

        </div>
    </>
)

export default Topo