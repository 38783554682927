import React, { useState, useEffect } from "react";
import "react-datetime/css/react-datetime.css";

import { Row, Input } from "reactstrap";
import 'react-datetime/css/react-datetime.css';
import Modal from "./modal/horario"

function Horario({businessHour, setBusinessHour}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [indice, setIndice] = useState(0)
  
  const [item, setItem] = useState(businessHour[0])
  
  const trocar = (i, indice) => {


    if (i.target.value === "Definir Horario") {
      setItem(businessHour[indice])
      setIndice(indice)
      setModal(true)
    }else{
      let newArray = [...businessHour];
      newArray[indice].tipo = i.target.value;
      // setDados(newArray)
      setBusinessHour(newArray)
    }
  }
  const salvarItem = (i, de, ate) => {
    let newArray = [...businessHour];
    newArray[i].de = de;
    newArray[i].ate = ate;
    newArray[i].tipo = "Definir Horario";
    setBusinessHour(newArray)
    setModal(false)
  }

  return (
    <>
      <Modal modal={modal} toggle={toggle} item={item} salvarItem={salvarItem} indice={indice} />
      <Row>
        {businessHour.map((item, i) => {
                    return (
            <div key={i} className="col-md-6">
              <div className="form-group">
                <label className="opacity-5 mb-1">{item.nome} {(item.tipo === "Definir Horario") ? `de:${item.de} até:${item.ate}` : null}</label>
                <Input type="select" name="select" value={item.tipo} defaultValue={item.tipo} className="form-round" id="exampleSelect" onChange={(e) => trocar(e, i)}>

                  <option value={"Aberto 24h"}>Aberto 24h</option>
                  <option value={"Fechado"}>Fechado</option>
                  <option value={"Definir Horario"}>Definir Horario</option>
                </Input>
              </div>
            </div>
          )
        })}
      </Row>
    </>

  );
}

export default Horario;
