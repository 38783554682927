import React, { isValidElement, useContext, useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    FormFeedback
} from "reactstrap";

// core components
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { PageSettings } from "../../config/page-settings";
import { resetPassword } from "../../services/auth/forgot";
import { store } from "react-notifications-component";

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true
        }
    });
}

function RedefinirSenha() {
    const history = useHistory()
    const contextType = useContext(PageSettings)
    const [token, setToken] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const validation = () => {
        const configToast = { position: toast.POSITION.TOP_CENTER }

        if (!email) toast.error("O campo email é obrigatório", configToast)
        if (!password) toast.error("O campo senha é obrigatório", configToast)
        if (password !== confirmPassword) toast.error("As senhas não são iquais", configToast)

        return email && password && password === confirmPassword
    }

    const redefinirSenha = () => {

        if (!validation()) {
            return
        }

        setLoading(true)

        const data = {
            email,
            token,
            password,
            c_password: confirmPassword
        }
        resetPassword(data)
            .then(response => {
                avisar("Recuperar senha", response.data.resposta.conteudo.message, "success")
                history.push(`/login?email=${email}`)
            })
            .catch(error => {
                if (error.response.data.resposta?.conteudo?.message) {
                    avisar("Ops!!", error.response.data.resposta?.conteudo?.message, "danger")
                }
                if (error && error.response?.data?.resposta) {
                    const messages = error.response.data?.resposta?.conteudo?.messages

                    if (error.response.data.resposta?.conteudo?.error && !messages) {
                        avisar("Ops!!", error.response.data.resposta?.conteudo?.error, "danger")
                    }

                    if (messages) {
                        Object.keys(messages).forEach(key => {
                            messages[key].forEach(message => {
                                avisar("Ops!!", message, "danger")
                            })
                        })
                    }
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        const paramsToUrl = new URLSearchParams(window.location.search)
        setEmail(paramsToUrl.get('email'))
        setToken(paramsToUrl.get('token'))

        contextType.handleSetPageSidebar(false);
        contextType.handleSetPageHeader(false);
        contextType.handleSetPageContentFullWidth(true);
        return () => {
            contextType.handleSetPageSidebar(true);
            contextType.handleSetPageHeader(true);
            contextType.handleSetPageContentFullWidth(false);
        }
    }, [])

    return (
        <>
            <ToastContainer />
            <div
                className="section section-signup"
                style={{
                    backgroundImage: "url(" + require("../../assets/img/logar-on-top.svg") + ")," + "url(" + require("../../assets/img/logar.svg") + ")",

                }}
            >
                <Container fluid>
                    <Row>
                        <Col md={11} className="d-flex justify-content-center justify-content-md-end">

                            <Card className="card-signup shadow-none" >
                                <Form className="form" >
                                    <CardHeader className="text-center">
                                        <Link to="/"><img src={require("../../assets/img/logo@2x.png")} style={{ maxWidth: 100 }} /></Link>
                                        <CardTitle className="title-up" tag="p">
                                            Seja Bem-vindo!
                                        </CardTitle>

                                    </CardHeader>
                                    <CardBody>
                                        <InputGroup
                                            className={
                                                "no-border",
                                                "mt-3"
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fal fa-envelope"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Email..."
                                                type="text"
                                                className="input-lg"
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                            />
                                        </InputGroup>

                                        <InputGroup
                                            className={
                                                "no-border",
                                                "mt-3"
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fal fa-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Senha"
                                                type="password"
                                                onChange={(event) => setPassword(event.target.value)}
                                            ></Input>
                                        </InputGroup>

                                        <InputGroup
                                            className={
                                                "no-border",
                                                "mt-3"
                                            }
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fal fa-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Confirmar Senha"
                                                type="password"
                                                onChange={(event) => setConfirmPassword(event.target.value)}
                                            ></Input>
                                        </InputGroup>

                                        <Button
                                            className="btn btn-lg btn-primary btn-block mt-5"
                                            color="primary"
                                            onClick={redefinirSenha}                                            
                                            disabled={loading}
                                            size="lg"
                                        >
                                            {loading ? "Carregando" : "Redefinir Senha" }
                                        </Button>

                                    </CardBody>
                                    <CardFooter className="text-center">

                                    </CardFooter>
                                </Form>
                                <p className="text-center">
                                    Ainda não tem conta? <Link to="/cadastro"><strong>Cadastre-se</strong></Link>
                                </p>
                            </Card>

                        </Col>

                    </Row>
                    <div className="col text-center">

                    </div>
                </Container>
            </div>
        </>
    );
}

export default RedefinirSenha;
