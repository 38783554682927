import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { PageSettings } from "../../config/page-settings"
import { getUser } from "../../store/user/action"
import {
  Panel,
  PanelHeader,
  PanelBody

} from "../../components/panel/panel.jsx";
import { Alert } from "reactstrap"
import { store } from 'react-notifications-component'

import Loader from "../../components/loader"
import { forgot } from '../../services/auth/forgot'
import 'react-notifications-component/dist/theme.css' 

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false)
  const contextType = useContext(PageSettings);
  const dispatch = useDispatch()
  const [enviado, setEnviado] = useState(false)
  const user = useSelector(state => state.auth.user)
  const avisar = (title, msg, type) => {
    store.addNotification({
      title: title || "Usuário ou senha incorretos",
      message: msg || "Um código de verificação foi enviado para o seu email!",
      type: type || "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 7000,
        onScreen: true
      }
    });
  }
  const trocarSenha = async () => {
    setLoadingBtn(true)

    await forgot(user.email)
      .then(response => {
        if (response.status !== 200) {
          avisar("Recuperar senha", "Você recebera um e-mail com as orientações para alterar sua senha!", "success")
        } else {
          avisar("Recuperar senha", "Você recebera um e-mail com as orientações para alterar sua senha!", "success")
        }
      })
      .catch(error => {
        if (error.response.data.resposta?.conteudo?.message) {
          avisar("Ops!!", error.response.data.resposta?.conteudo?.message, "danger")
        }
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            avisar("Ops!!", error.response.data.resposta?.conteudo?.error, "danger")
          }

          if (messages) {
            Object.keys(messages).forEach(key => {
              messages[key].forEach(message => {
                avisar("Ops!!", message, "danger")
              })
            })
          }
          return
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoadingBtn(false)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    dispatch(getUser())
  }, [])
  return (
    <>
      <Loader loading={loading} />
      <h1 className="page-header">
        <i className="fad fa-shield-alt mr-2 opacity-5 text-aqua"></i>
        Trocar senha <br />
      </h1>

      <Panel theme="default">
        <PanelHeader>Trocar senha</PanelHeader>
        <PanelBody>
          {enviado ?
            <Alert color="success">
              Mandamos um e-mail para você com as instruções para alterar a senha.
            </Alert> : null
          }

          <p>Clique no botão abaixo para alterar a senha.</p>
          <button disabled={loadingBtn} className="btn btn-md btn-orange btn-circle ml-auto" onClick={trocarSenha}> {loadingBtn ? "Carregando" : "Trocar senha"}</button>
        </PanelBody>
      </Panel>
    </>
  );
};

export default Home;
