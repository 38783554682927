import React, { useState, memo } from "react"
import {
  InputGroup,
  Input,
  InputGroupButtonDropdown,
  Button,
} from "reactstrap";

import ItemProduto from "../../../components/produto"
import SkeletonItem from "../../../components/skeleton/skeleton-item";
import Pagination from "../../../components/paginate/pagination";
import { BASE_URL_SERVICES } from "../../../config/api.config";

const Products = ({ listaProdutos = [], getItems, company, loading }) => {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false)
  const [name, setName] = useState('')
  const [searchCliked, setSearchCliked] = useState(false)

  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen)
  }
  const toggleSearchCliked = () => setSearchCliked(!searchCliked)

  return (
    <div className="col-md-12 mt-4">
      <div className="result-container">
        <InputGroup size="lg" className="m-b-20">
          <Input
            placeholder="Pesquise o seu produto..."
            type="text"
            className="input-white"
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <InputGroupButtonDropdown
            addonType="append"
            isOpen={splitButtonOpen}
            toggle={toggleSplit}
          >
            {
              searchCliked && name ? (
                <Button
                  color="danger"
                  onClick={() => {
                    getItems()
                    toggleSearchCliked()
                    setName('')
                  }}
                >
                  <i className="fa fa-times fa-fw"></i>
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => {
                    getItems({ name })
                    toggleSearchCliked()
                  }}
                >
                  <i className="fa fa-search fa-fw"></i>
                </Button>
              )
            }
          </InputGroupButtonDropdown>
        </InputGroup>
        <ul className="result-list">
          {
            listaProdutos?.data ? (
              listaProdutos.data.map((produto => {
                return <ItemProduto key={Math.random()} produto={produto} />
              }))
            ) : (
              <>
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
              </>
            )
          }
        </ul>

        {
          loading && (
            <p className="text-center mb-5 mt-2"><i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando </p>
          )
        }
     

      </div>
    </div>
  )
}

export default memo(Products)