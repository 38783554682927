import axios from "axios"
import { toast } from "react-toastify";
import { API } from "../config/api.config"

const api = axios.create({
    baseURL: API,
});

api.interceptors.response.use(response => response, error => {
    console.log('api.interceptors.response', error.response);
    
    if (error && error.response?.data?.resposta?.conteudo?.message) {
        const { message } = error.response.data.resposta.conteudo;
        toast.error(`${message} [${error?.response?.config?.url}]` || "Algo deu errado!!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
        });
    }

    return Promise.reject(error);
})

api.interceptors.request.use(config => {
    const token = `Bearer ${localStorage.getItem('access_token')}`
    config.headers.authorization = token;

    return config;
})


// api.defaults.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`

export default api;