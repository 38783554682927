import React, { useState } from 'react';
import {
  InputGroup,
  Input,
  Row,
  Col,
  InputGroupButtonDropdown,
  Button,
} from 'reactstrap';

SearchFor.propTypes = {
  pegaResultados: () => {},
  tiposDeBusca: [],
  searchFor: '',
  setSearchFor: () => {},
  keyword: '',
  setKeyword: () => {},
};

export default function SearchFor({
  pegaResultados,
  tiposDeBusca = [],
  searchFor,
  setSearchFor,
  keyword,
  setKeyword,
}) {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);

  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };

  return (
    <>
      <Row className="align-items-start mt-3 filtros-pesquisa">
        <Col md="12" lg="12" xl="12">
          <InputGroup size="lg" className="m-b-10">
            <Input
              placeholder=""
              type="text"
              className="input-white"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <InputGroupButtonDropdown
              addonType="append"
              isOpen={splitButtonOpen}
              toggle={toggleSplit}
            >
              <Button color="success" onClick={() => pegaResultados()}>
                <i className="fa fa-search fa-fw"></i>
              </Button>
              {keyword && (
                <Button
                  color="danger"
                  onClick={() => {
                    setKeyword('');
                    pegaResultados(true);
                  }}
                >
                  <i className="fa fa-times fa-fw"></i>
                </Button>
              )}
            </InputGroupButtonDropdown>
          </InputGroup>
        </Col>

        <Col className="form-group d-flex pesquisa-por">
          {tiposDeBusca &&
            tiposDeBusca?.map((tipo) => (
              <div key={tipo.key} className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id={tipo.key}
                  onChange={() => setSearchFor(tipo.key)}
                  checked={searchFor === tipo.key}
                />
                <label style={{ paddingLeft: '20px' }} htmlFor={tipo.key}>
                  {tipo.label}
                </label>
              </div>
            ))}
        </Col>
      </Row>
    </>
  );
}
