import api from "../api";
import { BASE_URL_ADMIN } from "../../config/api.config";

const baseUrl = `${BASE_URL_ADMIN}/services`;

export const servicesRequests = {
    list: (params = {}) => api.get(`${baseUrl}`, { params }),
    show: (id) => api.get(`${baseUrl}/${id}`),
    create: (values) => api.post(`${baseUrl}/add`, values),
    update: (id, values) => api.put(`${baseUrl}/${id}`, values),
    delete: (id) => api.delete(`${baseUrl}/${id}`),
}
