import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Button } from "reactstrap"
import { formatDistance } from 'date-fns'

import { ptBR } from 'date-fns/locale'
import FormComentario from "./formComentario"
import { URL_TO_IMAGES } from "../../config/api.config"
import SweetAlert from "react-bootstrap-sweetalert"
import { deleteEvaluations } from "../../services/evaluations"

const Comentario = ({ 
    item,
    minhas = false,
    createComent,
    updateComent,
    edit,
    getItems,
    avisar }) => {

    const { service, product, created_at, company, costomer_company } = item

    const [stars, setStars] = useState(item.stars || '')
    const [title, setTitle] = useState(item.title || '')
    const [comment, setComment] = useState(item.description || '')
    const [type, setType] = useState(service ? 'service_id' : product ? 'product_id' : 'company_id')
    const [showEdit, setShowEdit] = useState(false)
    const [photo, setPhoto] = useState('')
    const [deletar, setDeletar] = useState(null)
    const [loading, setLoading] = useState(false)

    const confirmar = async () => {        
        setLoading(true)
        await deleteEvaluations(deletar.id)
            .then(response => {
                getItems()
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
            })
            .catch(error => {
                if (error && error.response?.data) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
                setDeletar(null)
            })
    }
    const deletarItem = () => {
        setDeletar(null)
    }
    
    useEffect(() => {
        setType(service ? 'service_id' : product ? 'product_id' : 'company_id')
        
        const imageURL = url => url ? `${URL_TO_IMAGES}${url.replace('public', '')}` : ''
        const hasImage = arrImg => {
            if (!arrImg) {
                return ''
            }
            const defaultImg = arrImg.find(photo => photo.featured_image === 1)
            return defaultImg?.path || ''
        }
        switch (type) {
            case 'service_id':
                setPhoto(imageURL(item?.service?.images ? hasImage(item?.service?.images) : ''))
                break;
            case 'product_id':
                setPhoto(imageURL(item?.product?.images ? hasImage(item?.product?.images) : ''))
                break;
            default:
                setPhoto(imageURL(item?.company?.company_logo || ''))
                break;
        }
        if (showEdit) {
            setShowEdit(false)
        }
    }, [item])

    return (
        <li
            className={`media media-sm ${showEdit ? 'bg-light' : 'bg-white'} comentario`}
            style={{
                width: '100%',
                position: 'relative',
                opacity: loading ? 0.3 : 1
            }}
        >
            
            {(deletar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim, deletar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Você tem certeza?"
                    onConfirm={confirmar}
                    onCancel={deletarItem}
                >
                    Após essa ação Você não conseguira recuperar os dados.
                </SweetAlert>
            )}

            <div className="media-body">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start'
                    }}
                >
                    <div
                        className="d-flex mr-3"
                        style={{
                            width: '64px',
                            height: '64px'
                        }}
                    >
                        <img
                            src={photo || '/assets/img/user/user-13.jpg'}
                            alt="Foto desse item"
                            className="media-object rounded-corner mr-3"
                        />
                    </div>
                    <div>
                        <h4 className="media-heading">{title || 'Aenean mollis arcu sed turpis accumsan dignissim.'}</h4>
                        
                        {(service || product) && (
                            <Link to={`/${service ? 'servico/'+service.id : product ? 'produto/'+product.id : ''}`}>
                                <h5 className="media-heading opacity-5">{service ? service.name : product ? product.name : ''}</h5>
                            </Link>
                        )}

                        <p className="m-b-5">
                            {
                                comment
                            }
                        </p>
                        <span className="badge badge-lime">{service ? 'Serviço' : product ? 'Produto' : 'Empresa'}</span>

                        <Row className="justify-content-between">
                            <Col>
                                <div className="rating mt-2 mb-1">
                                    <span className="mr-2 text-orange">Nota {stars}</span>

                                    {
                                        [1,2,3,4,5].map(item => (
                                            <span key={item} className={`star ${item <= stars && 'active'}`}></span>
                                        ))
                                    }
                                </div>
                                <i className="label label-danger">
                                    {
                                        formatDistance(
                                            new Date(created_at),
                                            new Date(),
                                            { 
                                                addSuffix: true,
                                                locale: ptBR
                                            },
                                        )
                                    }
                                </i>
                            </Col>
                        </Row>

                        <p className="mt-3">
                            {
                                company ? (
                                    minhas ? (
                                        <i className="text-muted mt-2">Empresa: <Link to={`/fornecedor/${company.id}`}> {company.company_name} </Link></i>
                                    ) : costomer_company ? (
                                        <i className="text-muted mt-2">Avaliado por: <Link to={`/fornecedor/${costomer_company.id}`}> {costomer_company.company_name} </Link></i>
                                    ) : (
                                        <i className="text-muted mt-2">Avaliado por: <Link to={`/fornecedor/${company.id}`}> {company.company_name} </Link></i>
                                    )
                                ) : (
                                    costomer_company && (
                                        <i className="text-muted mt-2">Avaliado por: <Link to={`/fornecedor/${costomer_company.id}`}> {costomer_company.company_name} </Link></i>
                                    )
                                )
                            }
                        </p>

                    </div>
                </div>
                {
                    edit && (
                        <div
                            className="edit"
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <a
                                className="btn btn-success btn-icon btn-circle btn-lg m-3"
                                onClick={() => setShowEdit(oldValue => !oldValue)}
                            >
                                <i className="fa fa-edit"></i>
                            </a>
                            <Button
                                onClick={(e) => {
                                    setDeletar(item)
                                }}
                                className="btn btn-danger btn-icon btn-circle btn-lg m-3"
                            >
                                <i className="fa fa-times"></i>
                            </Button>
                        </div>
                    )
                }
                
                <div style={{width: '100%'}}>
                {
                    edit && showEdit && (
                        <FormComentario
                            stars={stars}
                            setStars={setStars}
                            title={title}
                            setTitle={setTitle}
                            comment={comment}
                            setComment={setComment}
                            type={type}
                            id={item.id}
                            createComent={createComent}
                            updateComent={updateComent}
                            titleSendButton='Editar'
                        />
                    )
                }
                </div>
            </div>
        </li>
    )
}

export default Comentario