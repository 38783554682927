import React from 'react';

import { companiesRequests } from '../../../services/admin/companies';
import PageDefault from '../pageDefault';
import { cnpjMask } from '../../../uteis/mask'

export default function Suppliers() {
    return (
      <PageDefault
        title={"Comprador"}
        titlePlural={"Compradores"}
        type="companies"
        params={{type: 2}}
        dataRequest={companiesRequests}
        baseUrl={"compradores"}
        columnsTable={[
          { key: "company_name", name: "Nome" },
          { key: "fantasy_name", name: "Nome Fantasia", hide: 'md' },
          { key: "cnpj", name: "CNPJ", hide: 'sm', render: cell => cell && cnpjMask(cell) },
        ]}
        withoutNew
        tiposDeBusca={[
          { key: "name", label: "Nome" },
          { key: "email", label: "E-mail" },
          { key: "cnpj", label: "CNPJ" },
        ]}
        hasDelete={false}
      />
    )
}