import React, { useState } from "react";
import Dropzone from "react-dropzone";
import cliente from "../../../../assets/img/iconeCliente.svg";
import "react-datetime/css/react-datetime.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datetime/css/react-datetime.css";
import { uploadLogo } from "../../../../services/companies";
import { URL_TO_IMAGES } from "../../../../config/api.config";
import moment from "moment";
import ModalCrop from "./modal/cropImage";

moment.locales("pt-BR");

function Topo({ empresa, getEmpresa, setLoading }) {
  const [foto, setFoto] = useState(null);
  const [modalCrop, setModalCrop] = useState(false);
  const toggleModalCrop = () => setModalCrop(!modalCrop);

  const salvarFoto = async (file) => {
    setLoading(true);
    const data = new FormData();
    data.append("image", file);
    await uploadLogo(data)
      .then(async (response) => {
        await getEmpresa(empresa.id);
        setFoto(file);
      })
      .catch((error) => console.log("error"))
      .finally(() => setLoading(false));
  };

  const carregarFotoThumb = (e) => {
    return new Promise((resolve, reject) => {
      if (!e) return reject("Arquivo necessario");
      const file = new FileReader();
      const arquivo = e[0];
      file.readAsDataURL(arquivo);
      file.onloadend = (event) => {
        resolve(event.target.result);
      };
    });
  };

  const sendFile = async (file) => {    
    var file1 = new File([file], "filename.jpg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    salvarFoto(file1);
    toggleModalCrop();
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-auto mb-3">
          <Dropzone
            accept="image/jpeg, image/png"
            onDrop={async (acceptedFiles) => {
              const blob = await carregarFotoThumb(acceptedFiles);
              setFoto(blob);
              toggleModalCrop();
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div
                    className="dropZoneAchei"
                    style={{
                      backgroundImage: `url(${
                        empresa?.company_logo
                          ? URL_TO_IMAGES +
                            "/" +
                            empresa?.company_logo.replace("public", "")
                          : cliente
                      })`,
                      width: 150,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: 150,
                      margin: "auto",
                      overflow: "hidden",
                      WebkitFilter: "none",
                      filter: "none",
                    }}
                  ></div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <ModalCrop
          modal={modalCrop}
          toggle={toggleModalCrop}
          sendFile={sendFile}
          foto={foto}
          setFoto={setFoto}
        />

        <div className="col d-flex flex-column flex-sm-row justify-content-between mb-3">
          <div className="text-sm-left mb-2 mb-sm-0">
            <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">
              {empresa.fantasy_name ||
                empresa.company_name ||
                "Nome da Empresa"}
            </h4>
            <p className="mb-0">Razão Social: {empresa.company_name}</p>
            <div className="text-muted">
              {empresa.users && (
                <small>
                  ultimo acesso em{" "}
                  {moment(empresa?.users[0]?.last_access).format("DD/MM/YYYY")}
                </small>
              )}
            </div>
            <div className="mt-2">
              {" "}
              <button
                className="btn btn-primary"
                type="file"
                onClick={() => document.getElementById("selectedFile").click()}
              >
                {" "}
                <i className="fa fa-fw fa-camera" /> <span>Trocar foto</span>{" "}
              </button>
              <input
                type="file"
                id="selectedFile"
                style={{ display: "none" }}
                onChange={async (e) => {
                  const blob = await carregarFotoThumb(e.target.files);
                  setFoto(blob);
                  toggleModalCrop();
                }}
              />
            </div>
          </div>
          <div className="text-left text-md-right">
            {" "}
            <span className="badge badge-secondary">Fornecedor</span>
            <div className="text-muted text-left">
              {empresa.users && (
                <small>
                  desde{" "}
                  {moment(empresa?.users[0]?.created_at).format(
                    "MMMM [de] YYYY"
                  )}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div></div>
    </>
  );
}

export default Topo;
